import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { Store } from '@ngxs/store';

import { Document } from '../../models/document.model';
import { SetCurrentDocumentId } from '../../ngxs/document-signing.actions';

@Component({
  selector: 'rs-agreements-list',
  templateUrl: './agreements-list.component.html',
  styleUrls: ['./agreements-list.component.scss'],
  changeDetection:  ChangeDetectionStrategy.OnPush
})
export class AgreementsListComponent {

  @Input() documents: Document[] = [];
  @Input() activeDocumentId: string;

  @Output() goToAgreement = new EventEmitter<string>();

  constructor(
    private store: Store
  ) {}

  public onGoToAgreementClick(documentId: string): void {
    this.store.dispatch(new SetCurrentDocumentId(documentId));
  }
}
