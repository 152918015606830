import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AuthModule } from './modules/auth/auth.module';
import { ReviewModule } from './modules/enrollment/flow/review/review.module';
import { LayoutModule } from './modules/enrollment/layout/layout.module';
import { SuccessModule } from './modules/success/success.module';
import { OnboardingRoutingModule } from './onboarding-routing.module';
import { OnboardingComponent } from './onboarding.component';
import { SpinnerModule } from './shared/components/spinner/spinner.module';
import { AccessTokenInterceptor } from './shared/interceptors/access-token.interceptor';
import { ErrorHandlerInterceptor } from './shared/interceptors/error-handler.interceptor';
import { PluginsModule } from './shared/modules/plugins.module';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    PluginsModule,
    OnboardingRoutingModule,
    LayoutModule,
    ReviewModule,
    AuthModule,
    SuccessModule,
    SpinnerModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AccessTokenInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true}
  ],
  declarations: [
    OnboardingComponent
  ],
  bootstrap: [
    OnboardingComponent
  ]
})
export class OnboardingModule {}
