export const ENROLLMENT_SECTIONS = {
  ADDRESS: 'address',
  BANK_ACCOUNT: 'bankAccount',
  BUSINESS_TYPE: 'businessType',
  COMPANY_INFORMATION: 'companyInformation',
  OWNERS_INFORMATION: 'ownersInformation',
  OWNERSHIP_STRUCTURE: 'ownershipStructure',
  RESTAURANT_STATUS: 'restaurantStatus',
  ROCKSPOON_PLAN: 'rockspoonPlan',
  SERVICE_TYPE: 'serviceType',
  TERMS_AND_AGREEMENT: 'termsAndAgreement',
  WEBSITE: 'website',
  WELCOME: 'welcome',
  SALES_DISTRIBUTION: 'salesDistribution',
  REVIEW_INFORMATION: 'reviewInformation'
};
