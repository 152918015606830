<rs-popup>
  <div class="rs-delete-owner-popup">
    <div class="rs-delete-owner-popup__title">{{text}}</div>
    <div class="rs-delete-owner-popup__actions">
      <button
        tabindex="-1"
        mat-raised-button
        color="primary"
        class="rs-blue-button"
        [mat-dialog-close]="true">{{ 'buttons.delete' | translate | uppercase }}
      </button>
      <button
        tabindex="-1"
        mat-raised-button
        color="primary"
        class="rs-blue-button"
        mat-dialog-close>{{ 'buttons.cancel' | translate | uppercase }}</button>
    </div>
  </div>
</rs-popup>
