import { IndividualOwner } from '../models/individual-owner.model';

export const EMPTY_CONTROL_PERSON: IndividualOwner = {
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  email: '',
  nationality: '',
  phoneNumber: '',
  residingCountry: '',
  secondaryNationality: '',
  share: null,
  socialSecurityNumber: '',
  socialSecurityType: '',
  documentDetails: {
    documentIssuer: '',
    documentType: '',
    expireDate: '',
    idNumber: '',
    issueDate: '',
    issuingState: ''
  },
  legalAddress: {
    address1: '',
    address2: '',
    city: '',
    country: 'USA',
    state: '',
    zipcode: ''
  }
}
