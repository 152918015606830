<div class="rs-layout" *ngIf="contentVisibility$ | async">
  <div class="rs-layout__side-nav">
    <rs-side-nav></rs-side-nav>
  </div>
  <div class="rs-layout__content">
    <rs-welcome></rs-welcome>
    <rs-restaurant-name></rs-restaurant-name>
    <rs-restaurant-status *ngIf="isStepAvailable('restaurantStatus')"></rs-restaurant-status>
    <rs-website *ngIf="isStepAvailable('website')"></rs-website>
    <rs-address *ngIf="isStepAvailable('address')"></rs-address>
    <rs-business-type *ngIf="isStepAvailable('businessType')"></rs-business-type>
    <rs-service-type *ngIf="isStepAvailable('serviceType')"></rs-service-type>
    <rs-sales-distribution *ngIf="isStepAvailable('salesDistribution')"></rs-sales-distribution>
    <rs-rockspoon-plan *ngIf="isStepAvailable('rockspoonPlan')"></rs-rockspoon-plan>
    <rs-ownership-structure *ngIf="isStepAvailable('ownershipStructure')"></rs-ownership-structure>
    <rs-company-information *ngIf="isStepAvailable('companyInformation')"></rs-company-information>
    <rs-owners-information *ngIf="isStepAvailable('ownersInformation')"></rs-owners-information>
    <rs-bank-account *ngIf="isStepAvailable('bankAccount')"></rs-bank-account>
    <rs-review *ngIf="isStepAvailable('reviewInformation')"></rs-review>
    <rs-terms-and-agreement *ngIf="isStepAvailable('termsAndAgreement')"></rs-terms-and-agreement>
    <rs-document-signing *ngIf="isStepAvailable('contracts')"></rs-document-signing>
  </div>
</div>
