import { Injectable } from '@angular/core';

import { Action, State, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { Spinner } from 'src/onboarding/shared/classes/spinner.class';
import { EnrollmentSteps } from 'src/onboarding/shared/enums/enrollment-steps.enum';
import { GoToStep, RefreshApplication, SetStepAsCompleted } from '../../../layout/ngxs/layout.actions';
import { LayoutState } from '../../../layout/ngxs/layout.state';
import { CompanyType, CompanyTypes } from '../models/company-type.model';
import { OwnershipStructureService } from '../ownership-structrure.service';
import { InitOwnershipStructure, SetOwnershipStructure } from './ownership-structure.actions';

interface OwnerShipStructureStateModel {
  ownershipStructure: CompanyTypes;
}

@State<OwnerShipStructureStateModel>({
  name: 'ownershipStructure',
  defaults: {
    ownershipStructure: null,
  }
})
@Injectable()
export class OwnershipStructureState extends Spinner {

  constructor(
    protected store: Store,
    private ownershipStructureService: OwnershipStructureService
  ) {
    super(store);
  }

  @Action(InitOwnershipStructure)
  initOwnershipStructure({patchState}: StateContext<OwnerShipStructureStateModel>, {businessEntity}: InitOwnershipStructure) {
    patchState({
      ownershipStructure: businessEntity.companyType
    });
  }

  @Action(SetOwnershipStructure)
  setRestaurantName({patchState}: StateContext<OwnerShipStructureStateModel>, {ownershipStructure}: SetOwnershipStructure){
    this.showSpinner();

    return this.ownershipStructureService.saveCompanyType(
      new CompanyType(ownershipStructure),
      this.store.selectSnapshot(LayoutState.getBusinessEntityId)
    )
      .pipe(
        tap({
          next: () => {
            patchState({
              ownershipStructure
            });
          },
          complete: () => {
            this.store.dispatch(new SetStepAsCompleted(EnrollmentSteps.ownershipStructure));
            this.store.dispatch(new GoToStep(EnrollmentSteps.companyInformation));
            this.hideSpinner();
            this.store.dispatch(new RefreshApplication());
          }
        })
      );
  }

}
