import { Component } from '@angular/core';

import { ROCKSPOON_WEBSITE_URL } from 'src/onboarding/shared/constants/recources';

@Component({
  selector: 'rs-leave-onboarding',
  templateUrl: 'leave-onboarding.component.html',
  styleUrls: ['leave-onboarding.component.scss']
})
export class LeaveOnboardingComponent {

  public goToRockSpoonSite(): void {
    window.location.replace(ROCKSPOON_WEBSITE_URL);
  }

}
