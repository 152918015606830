<div class="rs-review-website">
  <div class="rs-review-website__title rs-review-step">{{'review.restaurant-website' | translate}}</div>
  <div class="rs-form-control-outline" *ngIf="websiteUrl; else noUrl">
    <mat-form-field appearance="outline">
      <mat-label>{{'review.website-url' | translate}}</mat-label>
      <input [disabled]="true" [value]="websiteUrl" type="text" matInput>
    </mat-form-field>
  </div>
  <ng-template #noUrl>
    <rs-icon-button [disabled]="true" icon="sentiment_dissatisfied">{{'website.no-website' | translate}}</rs-icon-button>
  </ng-template>
</div>
