import { Component, EventEmitter, Input, Output } from '@angular/core';

import { OwnerTypes } from 'src/onboarding/shared/enums/owners.enum';

@Component({
  selector: 'rs-owner',
  templateUrl: 'owner.component.html',
  styleUrls: ['owner.component.scss', 'owner.component.responsive.scss']
})
export class OwnerComponent {

  @Input() owner: any;
  @Input() ownerType: string;
  @Output() ownerDeletion = new EventEmitter<void>();
  @Output() ownerEditing = new EventEmitter<void>();

  public isIndividual(): boolean {
    return this.ownerType === OwnerTypes.individual;
  }

  public deleteOwner(): void {
    this.ownerDeletion.emit();
  }

  public editOwner(): void {
    this.ownerEditing.emit();
  }

}
