import { NgModule } from '@angular/core';

import { IconButtonModule } from 'src/onboarding/shared/components/icon-button/icon-button.module';
import { IndividualFormModule } from 'src/onboarding/shared/components/individual-form/individual-form.module';
import { SectionFooterModule } from 'src/onboarding/shared/components/section-footer/section-footer.module';
import { MaterialModule } from 'src/onboarding/shared/modules/material.module';
import { SharedModule } from 'src/onboarding/shared/modules/shared.module';
import { PipesModule } from 'src/onboarding/shared/pipes/pipes.module';
import { AreYouSignerComponent } from './components/are-you-signer/are-you-signer.component';
import { ControlPersonComponent } from './components/control-person/control-person.component';
import { InvitedListComponent } from './components/invited-list/invited-list.component';
import { RequestSignerComponent } from './components/request-signer/request-signer.component';
import { TermsAndAgreementComponent } from './components/terms-and-agreement/terms-and-agreement.component';

@NgModule({
  imports: [
    SharedModule,
    MaterialModule,
    PipesModule,
    IconButtonModule,
    SectionFooterModule,
    IndividualFormModule
  ],
  declarations: [
    TermsAndAgreementComponent,
    AreYouSignerComponent,
    RequestSignerComponent,
    InvitedListComponent,
    ControlPersonComponent
  ],
  exports: [
    TermsAndAgreementComponent
  ]
})
export class TermsAndAgreementModule {}
