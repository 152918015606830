<div class="rs-success">
  <div class="rs-success__wrapper">
    <div class="rs-success__title">Congratulations</div>
    <div class="rs-success__content">
      <div class="rs-success__content-item">We will take it from here!</div>
      <div class="rs-success__content-item">Approval takes only minutes. Please check your email.</div>
      <div class="rs-success__content-item">Questions? Status? Reach out to us at <a href="tel:+1(415)781-9987">+1 (415) 781-9987</a> or <a href="mailto:support@rockspoon.com.">support@rockspoon.com.</a></div>
    </div>
  </div>
</div>
