<div class="rs-side-nav">
  <div class="rs-side-nav__items">
    <div class="rs-side-nav__item" *ngFor="let step of steps;let i=index">
      <rs-side-nav-item [step]="step" [isReview]="isReview" [steps]="steps"></rs-side-nav-item>
    </div>
  </div>
  <div class="rs-side-nav__footer">
    <div class="rs-side-nav__back">
      <button mat-button class="rs-link-button" rsOpenLeaveOnboarding>
        <mat-icon>chevron_left</mat-icon>{{'layout.back-to-rockspoon' | translate}}
      </button>
    </div>
    <div class="rs-side-nav__privacy-policy rs-link" (click)="goToPrivacyPolicy()">{{'layout.privacy-policy' | translate}}</div>
  </div>
</div>
