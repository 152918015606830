import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'rs-section-footer',
  templateUrl: 'section-footer.component.html',
  styleUrls: ['section-footer.component.scss', 'section-footer.component.responsive.scss']
})
export class SectionFooterComponent {

  @Output() accepted = new EventEmitter();
  @Output() canceled = new EventEmitter();

  @Input() disabled: boolean;
  @Input() buttonText: string;
  @Input() cancelButtonText: string;
  @Input() bigContent: boolean;

  public reactOnButtonClick(): void {
    this.accepted.emit();
  }

  public reactOnCancelButtonClick(): void {
    this.canceled.emit();
  }

}
