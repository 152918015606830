import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ENDPOINTS } from 'src/onboarding/shared/constants/endpoints';
import { IndividualOwner } from 'src/onboarding/shared/models/individual-owner.model';
import { ApiService } from 'src/onboarding/shared/services/api.service';
import { BusinessLeader } from './model/business-leader.model';

@Injectable({
  providedIn: 'root'
})
export class TermsAndAgreementService {

  constructor(
    private apiService: ApiService
  ) {}

  public createControlPerson(businessEntityId: string, controlPerson: IndividualOwner): Observable<void> {
    // TODO: remove hardcoded share attribute
    return this.apiService.put(ENDPOINTS.CONTROL_PERSON(businessEntityId), {...controlPerson, share: 10});
  }

  public getControlPerson(businessEntityId: string): Observable<IndividualOwner> {
    return this.apiService.get(ENDPOINTS.CONTROL_PERSON(businessEntityId));
  }

  public createBusinessLeader(businessEntityId: string, businessLeader: BusinessLeader): Observable<void> {
    return this.apiService.post(ENDPOINTS.CREATE_BUSINESS_LEADER(businessEntityId), businessLeader);
  }

  public getInvites(businessEntityId: string): Observable<any> {
    return this.apiService.get(ENDPOINTS.INVITES(businessEntityId));
  }

  public clearControlPerson(businessEntityId: string): Observable<void> {
    return this.apiService.delete(ENDPOINTS.CONTROL_PERSON(businessEntityId));
  }

}
