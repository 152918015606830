<div class="rs-popup">
  <mat-dialog-content>
    <div class="rs-popup__header">
      <div class="rs-popup__header-title">{{caption | translate}}</div>
      <div class="rs-popup__header-close">
        <button mat-icon-button color="primary" tabindex="-1" mat-dialog-close>  
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
    <ng-content></ng-content>
  </mat-dialog-content>
</div>
