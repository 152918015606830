import { Component, OnInit } from '@angular/core';

import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { Unsubscribe } from 'src/onboarding/shared/classes/unsubscribe.class';
import { EnrollmentSteps } from 'src/onboarding/shared/enums/enrollment-steps.enum';
import { checkIfStepAvailable, getCookie, goToAuthenticationApp } from 'src/onboarding/shared/helpers';
import { Step } from 'src/onboarding/shared/models/step.model';
import { SharedDataService } from 'src/onboarding/shared/services/shared-data.service';
import { InitApplication, RefreshSession } from '../../ngxs/layout.actions';
import { LayoutState } from '../../ngxs/layout.state';

@Component({
  selector: 'rs-layout',
  templateUrl: 'layout.component.html',
  styleUrls: ['layout.component.scss', 'layout.component.responsive.scss']
})
export class LayoutComponent extends Unsubscribe implements OnInit {

  @Select(state => state.layout.contentVisibility) contentVisibility$: Observable<boolean>;
  @Select(state => state.layout.businessEntityId) businessEntityId$: Observable<string>;
  @Select(state => state.layout.steps) steps$: Observable<Step[]>;
  @Select(state => state.layout.isNecessaryStepsCompleted) isNecessaryStepsCompleted$: Observable<boolean>;

  private businessEntityId: string;
  private steps: Step[];

  constructor(
    private store: Store,
    private sharedDataService: SharedDataService
  ) {
    super();
  }

  public isStepAvailable(stepId: EnrollmentSteps): boolean {
    return checkIfStepAvailable(
      this.steps,
      stepId,
      this.store.selectSnapshot(LayoutState.getIsUserLeader)
    );
  }

  public ngOnInit(): void {
    this.getAuthenticationData();
    this.initSubscriptions();
  }

  public visibilityOfItemsWhichRequireBusinessEntity(): boolean {
    return !!this.businessEntityId;
  }

  private getAuthenticationData(): void {
    const refreshToken = getCookie(environment.env ? `${environment.env}-refreshToken` : 'refreshToken');

    if (refreshToken) {
      this.sharedDataService.refreshToken = refreshToken;

      if (!this.sharedDataService.accessToken) {
        this.getAccessTokenAndInitApplication();
      } else {
        this.store.dispatch(new InitApplication());
      }
    } else {
      goToAuthenticationApp();
    }
  }

  private getAccessTokenAndInitApplication(): void {
    this.subscribeTo = this.store.dispatch(new RefreshSession())
      .pipe(
        switchMap(() => this.store.dispatch(new InitApplication()))
      )
      .subscribe(() => {});
  }

  private initSubscriptions(): void {
    this.subscribeTo = this.businessEntityId$
      .subscribe(businessEntityId => this.businessEntityId = businessEntityId);
    this.subscribeTo = this.steps$
      .subscribe(steps => this.steps = steps);
  }

}
