<div class="rs-review rs-enrollment-section" id="reviewInformation">
  <div class="rs-enrollment-section__content">
    <div class="rs-review__content">
      <div class="rs-review__content-wrapper">
        <div class="rs-review__title rs-step-title">{{'review.title' | translate}}</div>
        <rs-review-restaurant-name [businessEntity]="businessEntity"></rs-review-restaurant-name>
        <rs-review-restaurant-status [businessEntity]="businessEntity"></rs-review-restaurant-status>
        <rs-review-website [businessEntity]="businessEntity"></rs-review-website>
        <rs-review-address [businessEntity]="businessEntity"></rs-review-address>
        <rs-review-service-type [businessEntity]="businessEntity"></rs-review-service-type>
        <rs-review-sales-distribution [businessEntity]="businessEntity"></rs-review-sales-distribution>
        <rs-review-rockspoon-plan [businessEntity]="businessEntity"></rs-review-rockspoon-plan>
        <rs-review-ownership-structure [businessEntity]="businessEntity"></rs-review-ownership-structure>
        <rs-review-company-information [businessEntity]="businessEntity"></rs-review-company-information>
        <rs-review-owners-information [businessEntity]="businessEntity"></rs-review-owners-information>
        <rs-review-banking-information [businessEntity]="businessEntity"></rs-review-banking-information>
        <rs-section-footer buttonText="confirm" (accepted)="goToNext()"></rs-section-footer>
      </div>
    </div>
  </div>
</div>
