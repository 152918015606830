import { Address } from 'src/onboarding/modules/enrollment/flow/address/models/address.model';
import { CompanyTypes } from 'src/onboarding/modules/enrollment/flow/ownership-structure/models/company-type.model';
import { BusinessEntityStatuses } from '../enums/business-entity-statuses.enum';
import { EnrollmentSteps } from '../enums/enrollment-steps.enum';
import { RestaurantStatuses } from '../enums/restaurant-statuses.enum';
import { CompanyOwner } from './company-owner.model';
import { IndividualOwner } from './individual-owner.model';

export interface BusinessEntityReduced {
  id: string;
  mcc: string;
  merchantId: string;
  name: string;
  servicePackage: string;
  status: string;
}

export interface BusinessEntityExtended {
  bankingInformation: BankingInformation;
  businessInformation: BusinessInformation;
  companyType: CompanyTypes;
  companyTypeOther: string;
  controlPerson: IndividualOwner;
  createdAt: string;
  deviceOrderId: string;
  hubspot: HubSpot;
  jobDescription: string;
  leaderProfileIds: any[]; // TODO: add correct type definition
  merchantId: string;
  name: string;
  onboardingSteps: OnboardingSteps; // TODO: add correct type definition
  paymentInAdvance: boolean;
  phoneNumber: string;
  planId: string;
  principals: Principals;
  restaurantStatus: RestaurantStatuses;
  salesInformation: SalesInformation;
  serviceModel: string;
  servicePackage: ServicePackage;
  status: BusinessEntityStatuses;
  url: string;
  posReplacement: string;
}

export interface OnboardingSteps {
  [BackEndStepsNaming.businessEntityCreated]: boolean;
  [BackEndStepsNaming.restaurantStatus]: boolean;
  [BackEndStepsNaming.website]: boolean;
  [BackEndStepsNaming.address]: boolean;
  [BackEndStepsNaming.businessType]: boolean;
  [BackEndStepsNaming.serviceModel]: boolean;
  [BackEndStepsNaming.plan]: boolean;
  [BackEndStepsNaming.companyInformation]: boolean;
  [BackEndStepsNaming.owners]: boolean;
  [BackEndStepsNaming.salesInformation]: boolean;
  [BackEndStepsNaming.companyType]: boolean;
  [BackEndStepsNaming.contracts]: boolean;
}

export interface BankingInformation {
  accountName: string;
  accountNumber: string;
  accountType: string;
  bankName: string;
  routingNumber: string;
}

export interface BusinessInformation {
  ein: string;
  legalAddress: Address;
  businessAddress: Address;
  legalEntityName: string;
  type: string;
}

export interface HubSpot {
  dealFields: DealFields;
  dealStage: string;
}

export interface DealFields {
  owners_information_complete: boolean;
}

export interface Principals {
  companies: CompanyOwner[];
  individual: IndividualOwner[];
}

export interface SalesInformation {
  averageCardTransactionsYear: number;
  averageCheck: number;
  averageMontlyCardSales: number;
  cardPresentTransactions: number;
  floorServersAmount: number;
  highestCardTransactionYear: number;
  internetTransactions: number;
  kitchenStationsCount: number;
  mailPhoneTransactions: number;
  monthlyTotalCardRevenue: number;
  monthlyTotalRevenue: number;
  tablesCount: number;
}

export interface ServicePackage {
  baseFees: any; // TODO: add correct type
  cost: string;
  hasMenuRSGo: boolean;
  includesAnalytics: boolean;
  includesDeliveryAndTakeout: boolean;
  includesHumanResources: boolean;
  includesInventoryManagement: boolean;
  includesPOSSolution: boolean;
  includesPayments: boolean;
  includesRSGoOrders: boolean;
  includesReservationsWaitlist: boolean;
  includesSalesReport: boolean;
  isVisibleRSGo: boolean;
}

export enum BackEndStepsNaming {
  businessEntityCreated = 'businessEntityCreated',
  restaurantStatus = 'restaurantStatus',
  website = 'website',
  address = 'address',
  businessType = 'businessType',
  serviceModel = 'serviceModel',
  plan = 'plan',
  companyInformation = 'companyInformation',
  bankingInformation = 'bankingInformation',
  owners = 'owners',
  salesInformation = 'salesInformation',
  companyType = 'companyType',
  controlPerson = 'controlPerson',
  contracts = 'termsSigned'
}

export const onboardingStepsMap = new Map<BackEndStepsNaming, EnrollmentSteps>([
  [BackEndStepsNaming.businessEntityCreated, EnrollmentSteps.restaurantName],
  [BackEndStepsNaming.restaurantStatus, EnrollmentSteps.restaurantStatus],
  [BackEndStepsNaming.website, EnrollmentSteps.website],
  [BackEndStepsNaming.address, EnrollmentSteps.address],
  [BackEndStepsNaming.businessType, EnrollmentSteps.businessType],
  [BackEndStepsNaming.serviceModel, EnrollmentSteps.serviceType],
  [BackEndStepsNaming.plan, EnrollmentSteps.rockspoonPlan],
  [BackEndStepsNaming.companyInformation, EnrollmentSteps.companyInformation],
  [BackEndStepsNaming.bankingInformation, EnrollmentSteps.bankAccount],
  [BackEndStepsNaming.owners, EnrollmentSteps.ownersInformation],
  [BackEndStepsNaming.salesInformation, EnrollmentSteps.salesDistribution],
  [BackEndStepsNaming.companyType, EnrollmentSteps.ownershipStructure],
  [BackEndStepsNaming.controlPerson, EnrollmentSteps.termsAndAgreement],
  [BackEndStepsNaming.contracts, EnrollmentSteps.contracts]
]);
