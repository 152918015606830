import { NgModule } from '@angular/core';

import { NgxsModule } from '@ngxs/store';
import { PercentControlModule } from 'src/onboarding/shared/components/percent-control/percent-control.module';

import { SectionFooterModule } from 'src/onboarding/shared/components/section-footer/section-footer.module';
import { DirectivesModule } from 'src/onboarding/shared/directives/directives.module';
import { MaterialModule } from 'src/onboarding/shared/modules/material.module';
import { SharedModule } from 'src/onboarding/shared/modules/shared.module';
import { SalesDistributionComponent } from './component/sales-distribution.component';
import { SalesDistributionState } from './ngxs/sales-distribution.state';

@NgModule({
  imports: [
    SharedModule,
    SectionFooterModule,
    MaterialModule,
    DirectivesModule,
    PercentControlModule,
    NgxsModule.forFeature([SalesDistributionState])
  ],
  declarations: [
    SalesDistributionComponent
  ],
  exports: [
    SalesDistributionComponent
  ]
})
export class SalesDistributionModule {}
