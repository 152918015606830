import { Component, Input } from '@angular/core';

@Component({
  selector: 'rs-type-item',
  templateUrl: 'type-item.component.html',
  styleUrls: ['type-item.component.scss']
})
export class TypeItemComponent {

  @Input() selected: boolean;
  @Input() caption: string;

}
