import { BusinessEntityExtended } from 'src/onboarding/shared/models/business-entity.model';
import { SalesDistribution } from '../models/sales-distribution.model';

export class SaveSalesDistribution {
  static readonly type = '[Sales Distribution] Save';

  constructor(
    public salesDistribution: SalesDistribution
  ) {}
}

export class InitSalesDistribution {
  static readonly type = '[Sales Distribution] Init';

  constructor(
    public businessEntity: BusinessEntityExtended
  ) {}
}
