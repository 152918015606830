import { NgModule } from '@angular/core';

import { MessageModule } from 'src/onboarding/shared/components/message/message.module';
import { SectionFooterModule } from 'src/onboarding/shared/components/section-footer/section-footer.module';
import { TypeItemModule } from 'src/onboarding/shared/components/type-item/type-item.module';
import { MaterialModule } from 'src/onboarding/shared/modules/material.module';
import { SharedModule } from 'src/onboarding/shared/modules/shared.module';
import { ServiceTypeComponent } from './component/service-type.component';

@NgModule({
  imports: [
    SharedModule,
    MaterialModule,
    TypeItemModule,
    MessageModule,
    SectionFooterModule
  ],
  declarations: [
    ServiceTypeComponent
  ],
  exports: [
    ServiceTypeComponent
  ]
})
export class ServiceTypeModule {}
