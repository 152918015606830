import { Component, Input } from '@angular/core';

import { BusinessEntityExtended } from 'src/onboarding/shared/models/business-entity.model';

@Component({
  selector: 'rs-review-website',
  templateUrl: 'review-website.component.html',
  styleUrls: ['review-website.component.scss']
})
export class ReviewWebsiteComponent {

  @Input() set businessEntity({url}: BusinessEntityExtended) {
    this.websiteUrl = url;
  }

  public websiteUrl: string = null;

}
