import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { EnrollmentPage } from 'src/onboarding/shared/classes/enrollment-page.class';
import { ACCOUNT_TYPES } from 'src/onboarding/shared/constants/common';
import { ENROLLMENT_SECTIONS } from 'src/onboarding/shared/constants/enrollment-sections.constants';
import { ID_NUMBER_MASK } from 'src/onboarding/shared/constants/inputs-masks';
import { EnrollmentSteps } from 'src/onboarding/shared/enums/enrollment-steps.enum';
import { copy } from 'src/onboarding/shared/helpers';
import { BankingInformation } from 'src/onboarding/shared/models/business-entity.model';
import { GoToStep } from '../../../layout/ngxs/layout.actions';
import { SetBankingInformation } from '../ngxs/bank-account.actions';

@Component({
  selector: 'rs-bank-account',
  templateUrl: 'bank-account.component.html',
  styleUrls: ['bank-account.component.scss']
})
export class BankAccountComponent extends EnrollmentPage implements OnInit {

  @Select(state => state.bankingInfo.bankingInfo) bankingInformation$: Observable<BankingInformation>;

  public accountTypes: string[] = copy(ACCOUNT_TYPES);
  public bankingInformationForm: FormGroup;
  public idNumberMask = ID_NUMBER_MASK;

  private bankingInformation: BankingInformation;

  constructor(
    protected store: Store,
    private formBuilder: FormBuilder
  ) {
    super(ENROLLMENT_SECTIONS.BANK_ACCOUNT, store);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.initForm();
    this.initSubscriptions();
  }

  public initForm(): void {
    this.bankingInformationForm = this.formBuilder.group({
      accountNumber: [this.bankingInformation ? this.bankingInformation.accountNumber : null, Validators.required],
      routingNumber: [this.bankingInformation ? this.bankingInformation.routingNumber : null, Validators.required],
    });
  }

  public saveBankingInfo(): void {
    this.store.dispatch(new SetBankingInformation(this.bankingInformationForm.value));
  }

  private initSubscriptions(): void {
    this.subscribeTo = this.bankingInformation$
      .subscribe((bankingInformation: BankingInformation) => {
        this.bankingInformation = bankingInformation;
        this.initForm();
      });
  }

}
