<div class="rs-company-information rs-enrollment-section rs-form-control-outline" id="companyInformation">
  <div class="rs-enrollment-section__content" [formGroup]="companyInformationForm">
    <div class="rs-company-information__title rs-step-title">{{'company-information.title' | translate}}</div>
    <div
      class="rs-company-information__controls rs-row"
      [formGroup]="companyInformationForm"
      [ngClass]="{ 'individual' : isIndividual }"
    >
      <div class="rs-row">
        <mat-form-field appearance="outline">
          <mat-label>{{'placeholders.legal-entity-name' | translate}}*</mat-label>
          <input formControlName="legalEntityName" type="text" matInput placeholder="{{'placeholders.legal-entity-name' | translate}}*">
          <mat-error *ngIf="companyInformationForm.get('legalEntityName').hasError('required')">{{ "placeholders.required" | translate }}</mat-error>
        </mat-form-field>
        <rs-control-with-mask
          *ngIf="!isIndividual; else individualForm"
          [rsFormControl]="companyInformationForm.controls.ein"
          [placeholder]="'placeholders.ein'"
          mask="00-0000000">
        </rs-control-with-mask>
      </div>
      <ng-template #individualForm>
        <div class="rs-company-information__controls__individual">
          <mat-radio-group (change)="reactOnRadioBtnChange($event)">
            <div class="individual-item">
              <mat-radio-button [value]="'individual_ein'"></mat-radio-button>
              <rs-control-with-mask
                [rsFormControl]="companyInformationForm.controls.individual_ein"
                [placeholder]="'placeholders.ein'"
                mask="00-0000000">
              </rs-control-with-mask>
            </div>
            <div class="individual-item">
              <mat-radio-button [value]="'individual_ssn'"></mat-radio-button>
              <rs-control-with-mask
                [rsFormControl]="companyInformationForm.controls.individual_ssn"
                [placeholder]="'placeholders.ssn-itin'"
                mask="000-00-0000">
              </rs-control-with-mask>
            </div>
          </mat-radio-group>
        </div>
      </ng-template>
    </div>
    <rs-section-footer
      buttonText="{{'buttons.save' | translate}}"
      (accepted)="goToNext()"
      [disabled]="!isFormValid">
    </rs-section-footer>
  </div>
</div>
