<div class="rs-address rs-enrollment-section" id="address">
  <div class="rs-enrollment-section__content">
    <div class="rs-step-title">{{'enrollment-step-titles.address' | translate}}</div>
    <div class="rs-address__title">{{'address.business' | translate}}</div>
    <rs-address-form *ngIf="isFormsInit" [inputForm]="businessAddressForm" [state]="businessState"></rs-address-form>
    <mat-checkbox [(ngModel)]="different">{{'address.different' | translate}}</mat-checkbox>
    <div class="rs-address__title" *ngIf="different">{{'address.legal' | translate}}</div>
    <rs-address-form [inputForm]="legalAddressForm" *ngIf="different" [state]="legalState"></rs-address-form>
    <div class="rs-address__footer">
      <rs-section-footer [disabled]="isSaveDisabled()" (accepted)="save()" buttonText="{{'buttons.save' | translate}}"></rs-section-footer>
    </div>
  </div>
</div>
