import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { DEFAULT_COUNTRY, MAX_INPUT_LENGTH } from '../constants/common';
import { REGEX } from '../constants/regular-expressions';
import { getUSstateValue } from '../helpers';
import { CompanyOwner } from '../models/company-owner.model';
import { IndividualOwner } from '../models/individual-owner.model';

export class OwnersForm {

  static formBuilder: FormBuilder = new FormBuilder();

  public static getCompanyOwnerForm(): FormGroup {
    return this.formBuilder.group({
      address1: [null, [Validators.required, Validators.maxLength(MAX_INPUT_LENGTH)]],
      address2: [null, [Validators.maxLength(MAX_INPUT_LENGTH)]],
      city: [null, [Validators.required, Validators.maxLength(MAX_INPUT_LENGTH)]],
      country: [DEFAULT_COUNTRY, Validators.required],
      state: [null, Validators.required],
      zipcode: [null, Validators.required],
      businessName: [null, Validators.required],
      email: [null, [Validators.required, Validators.pattern(REGEX.EMAIL)]],
      phone: [null, Validators.required],
      share: [null, Validators.required],
      tin: [null, [Validators.required]],
      isCompany: true,
      ownerType: 'Company'
    });
  }

  public static getPatchedCompanyOwnerForm(owner: CompanyOwner): FormGroup {
    const form = OwnersForm.getCompanyOwnerForm();

    form.setValue({
      businessName: owner.businessName,
      email: owner.email,
      share: owner.share,
      tin: owner.tin,
      phone: owner.phone,
      address1: owner.address.address1,
      address2: owner.address.address2,
      city: owner.address.city,
      country: owner.address.country,
      state: owner.address.state,
      zipcode: owner.address.zipcode,
      isCompany: true,
      ownerType: 'Company'
    });

    return form;
  }

  public static getIndividualOwnerForm(isControlPerson: boolean = false): FormGroup {
    const formObject = {
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      phone: [null, Validators.required],
      email: [null, [Validators.required, Validators.pattern(REGEX.EMAIL)]],
      nationality: [null, Validators.required],
      secondaryNationality: [null],
      residingCountry: [null, Validators.required],
      birthDate: [null, Validators.required],
      socialSecurityNumber: [null, Validators.required],
      ownership: [null, Validators.required],
      country: [DEFAULT_COUNTRY, Validators.required],
      address1: [null, [Validators.required, Validators.maxLength(MAX_INPUT_LENGTH)]],
      address2: [null, [Validators.maxLength(MAX_INPUT_LENGTH)]],
      city: [null, [Validators.required, Validators.maxLength(MAX_INPUT_LENGTH)]],
      state: [null, Validators.required],
      zipcode: [null, Validators.required],
      documentIssuer: [null, Validators.required],
      issuingState: [null],
      issueDate: [null, Validators.required],
      expireDate: [null, Validators.required],
      documentType: [null, Validators.required],
      idNumber: [null, Validators.required],
      isIndividual: true,
      ownerType: 'Individual'
    };

    if (isControlPerson) {
      delete formObject.ownership;
    }

    return this.formBuilder.group(formObject);
  }

  public static getPatchedIndividualForm(owner: IndividualOwner, isControlPerson: boolean = false): FormGroup {
    const form = OwnersForm.getIndividualOwnerForm(isControlPerson);
    const formValue = {
      firstName: owner.firstName,
      lastName: owner.lastName,
      phone: owner.phoneNumber,
      email: owner.email,
      nationality: owner.nationality,
      secondaryNationality: owner.secondaryNationality || null,
      residingCountry: owner.residingCountry,
      birthDate: owner.dateOfBirth,
      socialSecurityNumber: owner.socialSecurityNumber,
      ownership: owner.share,
      country: owner.legalAddress.country,
      address1: owner.legalAddress.address1,
      address2: owner.legalAddress.address2,
      city: owner.legalAddress.city,
      state: getUSstateValue(owner.legalAddress.state),
      zipcode: owner.legalAddress.zipcode,
      documentIssuer: owner.documentDetails.documentIssuer,
      issuingState: getUSstateValue(owner.documentDetails.issuingState),
      issueDate: owner.documentDetails.issueDate,
      expireDate: owner.documentDetails.expireDate,
      documentType: owner.documentDetails.documentType,
      idNumber: owner.documentDetails.idNumber,
      isIndividual: true,
      ownerType: 'Individual'
    };

    if (isControlPerson) {
      delete formValue.ownership;
    }

    form.setValue(formValue);

    return form;
  }

}
