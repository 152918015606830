<div class="rs-review-restaurant-name" *ngIf="restaurantName">
  <div class="rs-review-restaurant-name__title rs-review-step">{{'review.restaurant-name' | translate}}</div>
  <div class="rs-form-control-outline" *ngIf="restaurantName">
    <div class="rs-row">
      <mat-form-field appearance="outline">
        <mat-label>{{'placeholders.restaurant-name' | translate}}</mat-label>
        <input [disabled]="true" [value]="restaurantName" type="text" matInput>
      </mat-form-field>
    </div>
  </div>
</div>
