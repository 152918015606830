<div class="rs-owner">
  <div class="rs-owner__label" [class.rs-owner__label_individual]="isIndividual()">{{ownerType}}</div>
  <div class="rs-owner__title">
    {{ isIndividual() ? (owner | rsFullName) : owner.businessName }}
  </div>
  <div class="rs-owner__menu">
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="editOwner()">
        <span>{{ "buttons.view-edit" | translate }}</span>
      </button>
      <button
        mat-menu-item (accepted)="deleteOwner()"
        rsConfirmation
        text="{{ 'popups.are-you-sure' | translate }}"
      >
        <span>{{ "buttons.delete" | translate }}</span>
      </button>
    </mat-menu>
  </div>
  <div class="rs-owner__content">
    <div class="rs-owner__item">
      <div class="rs-owner__key">{{'owners.ownership' | translate}} %</div>
      <div class="rs-owner__value">{{owner.share}}%</div>
    </div>
    <div class="rs-owner__item">
      <div class="rs-owner__key">{{'placeholders.email' | translate}}</div>
      <div class="rs-owner__value">{{owner.email}}</div>
    </div>
  </div>
</div>
