import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ResetPasswordComponent } from './modules/auth/components/reset-password.component';
import { LayoutComponent } from './modules/enrollment/layout/components/layout/layout.component';
import { SuccessComponent } from './modules/success/success.component';

@NgModule({
  imports: [
    RouterModule.forRoot([
      { path: '', redirectTo: '/enrollment', pathMatch: 'full' },
      { path: 'enrollment', component: LayoutComponent },
      { path: 'success', component: SuccessComponent },
      { path: 'resetPassword', component: ResetPasswordComponent },
      { path: '**', redirectTo: '/enrollment' }
    ])
  ],
  exports: [
    RouterModule
  ]
})
export class OnboardingRoutingModule {}
