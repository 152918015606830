<div class="rs-individual" *ngIf="form">
  <mat-checkbox (change)="reactOnChangeIAmOwner($event)">{{'owners.i-am' | translate}}</mat-checkbox>
  <rs-individual-form [individualForm]="form" [addressForm]="form"></rs-individual-form>
  <div
    *ngIf="!isEdit"
    class="rs-single-cancel-btn"
  >
    <button
      rsConfirmation
      (accepted)="closeForm()"
      mat-raised-button
      text="{{ 'popups.are-you-sure' | translate }}"
    >
      {{ 'buttons.delete' | translate | uppercase }}
    </button>
  </div>
  <rs-section-footer
    *ngIf="isEdit"
    cancelButtonText="{{ 'buttons.cancel' | translate }}"
    buttonText="{{ 'buttons.save' | translate }}"
    (accepted)="saveEditForm()"
    (canceled)="closeEditForm()"
    [disabled]="form.invalid"
  ></rs-section-footer>
</div>
