import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { InitSteps } from './modules/enrollment/layout/ngxs/layout.actions';

@Component({
  selector: 'rs-onboarding',
  templateUrl: 'onboarding.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OnboardingComponent implements OnInit {

  @Select(state => state.layout.spinnerVisibility) spinnerVisibility$: Observable<boolean>;

  constructor(
    private store: Store
  ) {}

  ngOnInit() {
    this.store.dispatch(new InitSteps());
  }

}
