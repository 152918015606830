import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { EnrollmentPage } from 'src/onboarding/shared/classes/enrollment-page.class';
import { ENROLLMENT_SECTIONS } from 'src/onboarding/shared/constants/enrollment-sections.constants';
import { differentAddresses, getUSstateValue } from 'src/onboarding/shared/helpers';
import { Address } from '../models/address.model';
import { SaveRestaurantAddress } from '../ngxs/address.actions';
import { AddressStateModel } from '../ngxs/address.state';

@Component({
  selector: 'rs-address',
  templateUrl: 'address.component.html',
  styleUrls: ['address.component.scss']
})
export class AddressComponent extends EnrollmentPage implements OnInit {

  @Select(state => state.address) restaurantAddress$: Observable<AddressStateModel>;

  private legalAddress: Address;
  private businessAddress: Address;
  public isFormsInit: boolean = false;
  public different: boolean;
  public legalAddressForm: FormGroup;
  public businessAddressForm: FormGroup;

  public businessState: string = null;
  public legalState: string = null;

  constructor(
    protected store: Store,
    private formBuilder: FormBuilder
  ) {
    super(ENROLLMENT_SECTIONS.ADDRESS, store);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.initSubscriptions();
  }

  public isSaveDisabled(): boolean {
    return this.different ? (this.legalAddressForm.invalid || this.businessAddressForm.invalid) : this.businessAddressForm.invalid;
  }

  public save(): void {
    this.store.dispatch(new SaveRestaurantAddress(
      this.businessAddressForm.getRawValue(),
      this.different ? this.legalAddressForm.getRawValue() : this.businessAddressForm.getRawValue())
    );
  }

  private initForm(): void {
    this.legalAddressForm = this.formBuilder.group({
      address1: [this.legalAddress ? this.legalAddress.address1 : '', [Validators.required, Validators.maxLength(31)]],
      address2: [this.legalAddress ? this.legalAddress.address2 : '', Validators.maxLength(31)],
      city: [this.legalAddress ? this.legalAddress.city : '', Validators.required],
      state: [this.legalAddress ? getUSstateValue(this.legalAddress.state) : '', Validators.required],
      zipcode: [this.legalAddress ? this.legalAddress.zipcode : '', Validators.required]
    });
    this.businessAddressForm = this.formBuilder.group({
      address1: [this.businessAddress ? this.businessAddress.address1 : '', [Validators.required, Validators.maxLength(31)]],
      address2: [this.businessAddress ? this.businessAddress.address2 : '', Validators.maxLength(31)],
      city: [this.businessAddress ? this.businessAddress.city : '', Validators.required],
      state: [this.businessAddress ? getUSstateValue(this.businessAddress.state) : '', Validators.required],
      zipcode: [this.businessAddress ? this.businessAddress.zipcode : '', Validators.required]
    });
  }

  private getDifferentValue(): void {
    this.different = differentAddresses(this.businessAddress, this.legalAddress);
  }

  private initSubscriptions(): void {
    this.subscribeTo = this.restaurantAddress$
      .subscribe((addressStoreLayer: AddressStateModel) => {
        this.legalAddress = addressStoreLayer.legalAddress;
        this.businessAddress = addressStoreLayer.businessAddress;
        this.initForm();
        this.businessAddress ? this.businessState = getUSstateValue(this.businessAddress.state) : null;
        this.legalAddress ? this.legalState = getUSstateValue(this.legalAddress.state) : null;
        this.getDifferentValue();

        if (this.legalAddress) {
          this.isFormsInit = true;
        }
      });
  }

}
