import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ENDPOINTS } from 'src/onboarding/shared/constants/endpoints';
import { ApiService } from 'src/onboarding/shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class ReviewService {

  constructor(
    private apiService: ApiService
  ) {}

  public submit(businessEntityId: string): Observable<void> {
    return this.apiService.get(ENDPOINTS.VALIDATE(businessEntityId));
  }

}
