import { Injectable } from '@angular/core';

import { CACHE } from '../constants/cache';
import { IndividualOwner } from '../models/individual-owner.model';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {

  public businessEntityId: string;
  public myEmail: string;

  constructor(
    private localStorageService: LocalStorageService
  ) {}

  get companies(): any[] {
    return this.localStorageService.getCachedData(CACHE.COMPANIES) || [];
  }

  set companies(companies: any[]) {
    this.localStorageService.cacheData(CACHE.COMPANIES, companies);
  }

  get individuals(): IndividualOwner[] {
    return this.localStorageService.getCachedData(CACHE.INDIVIDUALS) || [];
  }

  set individuals(individuals: IndividualOwner[]) {
    this.localStorageService.cacheData(CACHE.INDIVIDUALS, individuals);
  }

  get accessToken(): string {
    return this.localStorageService.getCachedData(CACHE.ACCESS_TOKEN);
  }

  set accessToken(accessToken: string) {
    this.localStorageService.cacheData(CACHE.ACCESS_TOKEN, accessToken);
  }

  get refreshToken(): string {
    return this.localStorageService.getCachedData(CACHE.REFRESH_TOKEN);
  }

  set refreshToken(refreshToken: string) {
    this.localStorageService.cacheData(CACHE.REFRESH_TOKEN, refreshToken);
  }

}
