import { Component, OnInit } from '@angular/core';

import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { GoToStep, SetStepAsCompleted } from 'src/onboarding/modules/enrollment/layout/ngxs/layout.actions';
import { EnrollmentPage } from 'src/onboarding/shared/classes/enrollment-page.class';
import { ENROLLMENT_SECTIONS } from 'src/onboarding/shared/constants/enrollment-sections.constants';
import { EnrollmentSteps } from 'src/onboarding/shared/enums/enrollment-steps.enum';
import { BusinessEntityExtended } from 'src/onboarding/shared/models/business-entity.model';

@Component({
  selector: 'rs-review',
  templateUrl: 'review.component.html',
  styleUrls: ['review.component.scss']
})
export class ReviewComponent extends EnrollmentPage implements OnInit {

  @Select(state => state.layout.businessEntity) businessEntity$: Observable<BusinessEntityExtended>;

  public businessEntity: BusinessEntityExtended;

  constructor(
    protected store: Store
  ) {
    super(ENROLLMENT_SECTIONS.REVIEW_INFORMATION, store);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.initSubscriptions();
  }

  public goToNext(): void {
    this.store.dispatch(new SetStepAsCompleted(EnrollmentSteps.reviewInformation));
    this.store.dispatch(new GoToStep(EnrollmentSteps.termsAndAgreement));
  }

  private initSubscriptions(): void {
    this.subscribeTo = this.businessEntity$
      .subscribe((businessEntity: BusinessEntityExtended) => this.businessEntity = businessEntity);
  }

}
