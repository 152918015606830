export enum EnrollmentSteps {
  restaurantName = 'restaurantName',
  restaurantStatus = 'restaurantStatus',
  website = 'website',
  address = 'address',
  businessType = 'businessType',
  serviceType = 'serviceType',
  salesDistribution = 'salesDistribution',
  rockspoonPlan = 'rockspoonPlan',
  ownershipStructure = 'ownershipStructure',
  companyInformation = 'companyInformation',
  ownersInformation = 'ownersInformation',
  bankAccount = 'bankAccount',
  reviewInformation = 'reviewInformation',
  termsAndAgreement = 'termsAndAgreement',
  contracts = 'contracts'
}
