import { Pipe, PipeTransform } from '@angular/core';

import { getFullName } from '../helpers';

interface NamedEntity {
  firstName: string;
  lastName: string;
}

@Pipe({
  name: 'rsFullName'
})
export class FullNamePipe implements PipeTransform {

  transform(namedEntity: NamedEntity) {
    return getFullName(namedEntity);
  }

}
