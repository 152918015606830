import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { COMMON_CONSTANTS } from '../constants/common';
import { CustomHttpParamEncoder } from '../http-params.encoder';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient
  ) {}

  public get(endpoint: string, queryParams?: any): Observable<any> {
    const httpParams = new HttpParams({
      encoder: new CustomHttpParamEncoder(),
      fromObject: queryParams
    });

    return this.http.get<any>(`${COMMON_CONSTANTS.API_URL}/${endpoint}`, {
      params: httpParams
    });
  }

  public post(endpoint: string, body: any): Observable<any> {
    return this.http.post(`${COMMON_CONSTANTS.API_URL}/${endpoint}`, body);
  }

  public put(endpoint: string, body: any): Observable<any> {
    return this.http.put(`${COMMON_CONSTANTS.API_URL}/${endpoint}`, body);
  }

  public patch(endpoint: string, body: any): Observable<any> {
    return this.http.patch(`${COMMON_CONSTANTS.API_URL}/${endpoint}`, body);
  }

  public delete(endpoint: string, queryParams?: any): Observable<any> {
    const httpParams = new HttpParams({
      encoder: new CustomHttpParamEncoder(),
      fromObject: queryParams
    });

    return this.http.delete<any>(`${COMMON_CONSTANTS.API_URL}/${endpoint}`, {
      params: httpParams
    });
  }

}
