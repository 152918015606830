import { Component, Inject, OnDestroy, OnInit } from '@angular/core';

import { Store } from '@ngxs/store';
import { SetStepAsActive } from 'src/onboarding/modules/enrollment/layout/ngxs/layout.actions';

import { Unsubscribe } from './unsubscribe.class';

@Component({
  template: ''
})
// tslint:disable-next-line:component-class-suffix
export class EnrollmentPage extends Unsubscribe implements OnDestroy, OnInit {

  private enrollmentSection: HTMLElement;
  private minOffsetToSetNavigationItemAsActive = 200;

  constructor(
    @Inject(String) private enrollmentSectionId: string,
    protected store: Store
  ) {
    super();
  }

  public ngOnInit(): void {
    this.enrollmentSection = document.getElementById(this.enrollmentSectionId);
    document.addEventListener('scroll', () => this.reactOnDocumentScroll());
    this.reactOnDocumentScroll();
  }

  private reactOnDocumentScroll() {
    if (this.enrollmentSection) {
      const { top: currentTop, bottom: currentBottom } = this.enrollmentSection.getBoundingClientRect();

      if (currentTop < this.minOffsetToSetNavigationItemAsActive && currentBottom > this.minOffsetToSetNavigationItemAsActive * 2) {
        this.store.dispatch(new SetStepAsActive(this.enrollmentSectionId));
      }
    }
  }

  public ngOnDestroy(): void {
    document.removeEventListener('scroll', this.reactOnDocumentScroll);
  }

}
