import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ENDPOINTS } from 'src/onboarding/shared/constants/endpoints';
import { ApiService } from 'src/onboarding/shared/services/api.service';
import { SalesDistribution } from './models/sales-distribution.model';

@Injectable({
  providedIn: 'root' 
})
export class SalesDistributionService {

  constructor(
    private apiService: ApiService
  ) {}

  public saveSalesDistribution(salesDistribution: SalesDistribution, businessEntityId: string): Observable<void> {
    return this.apiService.put(ENDPOINTS.SALES_DISTRIBUTION(businessEntityId), salesDistribution)
  }

}
