import { BusinessEntityExtended } from 'src/onboarding/shared/models/business-entity.model';
import { CompanyTypes } from '../models/company-type.model';

export class SetOwnershipStructure {
  static readonly type = '[Ownership Structure] Set';

  constructor(
    public ownershipStructure: CompanyTypes
  ) {}
}

export class InitOwnershipStructure {
  static readonly type = '[Ownership Structure] Init';

  constructor(
    public businessEntity: BusinessEntityExtended
  ) {}
}
