import { Component, Input } from '@angular/core';

import { RestaurantStatuses } from 'src/onboarding/shared/enums/restaurant-statuses.enum';
import { BusinessEntityExtended } from 'src/onboarding/shared/models/business-entity.model';

@Component({
  selector: 'rs-review-restaurant-status',
  templateUrl: 'review-restaurant-status.component.html',
  styleUrls: ['review-restaurant-status.component.scss']
})
export class ReviewRestaurantStatusComponent {

  @Input() set businessEntity({restaurantStatus}: BusinessEntityExtended) {
    this.restaurantStatus = restaurantStatus;
  };

  public restaurantStatus: RestaurantStatuses = null;

  public isOperational(): boolean {
    return this.restaurantStatus === RestaurantStatuses.operational;
  }

  public isPreOperational(): boolean {
    return this.restaurantStatus === RestaurantStatuses.preOperational;
  }

  public isTemporaryClosed(): boolean {
    return this.restaurantStatus === RestaurantStatuses.temporarilyClosed;
  }

}
