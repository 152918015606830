<div class="rs-owners-information rs-enrollment-section" id="ownersInformation">
  <div class="rs-enrollment-section__content">
    <div class="rs-owner-type-selection__title rs-step-title">
      {{ 'owners.list-owners' | translate: {name: restaurantName$ | async} }}
    </div>
    <div class="rs-owner-type-selection__subtitle rs-owners-subtitle">
      {{ 'titles.to-company-with-the-patriot' | translate }}
      <mat-icon>info</mat-icon>
    </div>
    <rs-owner-type-selection
      *ngIf="!owners.length"
      (selectedOwnerType)="addOwner($event)"
    ></rs-owner-type-selection>
    <rs-add-owner
      *ngFor="let owner of owners; let i = index"
      [index]="i"
      [form]="owner"
      title="owners.list-another"
      (selectOwnerType)="changeOwnerType($event, i)"
      (close)="closeOwner(i)"
    ></rs-add-owner>

    <div *ngIf="isShowBottomSelection && owners.length">
      <div class="rs-step-title">
        {{ 'owners.list-another' | translate }}
      </div>
      <div class="rs-owners-subtitle">
        {{ 'titles.to-company-with-the-patriot' | translate }}
        <mat-icon>info</mat-icon>
      </div>
      <rs-owner-type-selection
        (selectedOwnerType)="addOwner($event)"
      ></rs-owner-type-selection>
    </div>
    <rs-section-footer
      *ngIf="!owners.length"
      [buttonText]="'save'"
      (accepted)="skip()"
      [disabled]="!((companies$ | async).length || (individuals$ | async).length )"
    ></rs-section-footer>
    <rs-section-footer
      *ngIf="owners.length"
      buttonText="{{ 'buttons.save' | translate }}"
      (accepted)="saveOwners()"
      [disabled]="!isFormsValid"
    ></rs-section-footer>
    <div class="rs-owners-information__list">
      <rs-owner
        *ngFor="let owner of (companies$ | async); let i = index"
        [owner]="owner"
        ownerType="Company"
        (ownerEditing)="editCompany(owner, i)"
        (ownerDeletion)="deleteCompany(i)"
      ></rs-owner>
      <rs-owner
        *ngFor="let owner of (individuals$ | async); let i = index"
        [owner]="owner"
        ownerType="Individual"
        (ownerEditing)="editIndividual(owner, i)"
        (ownerDeletion)="deleteIndividual(i)"
      ></rs-owner>
    </div>
    <rs-individual
      *ngIf="isIndividualEdit"
      [form]="ownerFormForEdit"
      [isEdit]="isIndividualEdit"
      (closeEdit)="closeEditForm()"
      (saveEdit)="saveEditForm()"
    ></rs-individual>
    <rs-company
      *ngIf="isCompanyEdit"
      [form]="ownerFormForEdit"
      [isEdit]="isCompanyEdit"
      (closeEdit)="closeEditForm()"
      (saveEdit)="saveEditForm()"
    ></rs-company>
  </div>
</div>
