import { Component, EventEmitter, Input, Output } from '@angular/core';

import { OWNER_TYPES } from 'src/onboarding/shared/constants/common';

@Component({
  selector: 'rs-owner-type-selection',
  templateUrl: 'owner-type-selection.component.html',
  styleUrls: ['owner-type-selection.component.scss', 'owner-type-selection.component.responsive.scss']
})
export class OwnerTypeSelection {

  @Input() dropdownValue: string = undefined;
  @Output() selectedOwnerType = new EventEmitter<string>();

  public ownerTypes: string[] = OWNER_TYPES;

  public selectOwnerType(ownerType: string): void {
    this.selectedOwnerType.emit(ownerType);
  }

}
