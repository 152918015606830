import { Component, Input } from '@angular/core';

@Component({
  selector: 'rs-restaurant-status-item',
  templateUrl: 'restaurant-status-item.component.html',
  styleUrls: ['restaurant-status-item.component.scss', 'restaurant-status-item.component.responsive.scss']
})
export class RestaurantStatusItemComponent {

  @Input() icon: string;
  @Input() title: string;
  @Input() selected: boolean;

}
