import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'rs-add-owner',
  templateUrl: 'add-owner.component.html',
  styleUrls: ['add-owner.component.scss'],
})
export class AddOwnerComponent {

  @Input() index: string | number;
  @Input() form: FormGroup = null;
  @Output() selectOwnerType = new EventEmitter<string>();
  @Output() close = new EventEmitter<void>();

  public changeOwnerType(ownerType: string): void {
    this.selectOwnerType.emit(ownerType);
  }

  public closeForm(): void {
    this.close.emit();
  }

}
