import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Store } from '@ngxs/store';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { SetSpinnerVisibility } from 'src/onboarding/modules/enrollment/layout/ngxs/layout.actions';
import { Unsubscribe } from '../../classes/unsubscribe.class';
import { ENDPOINTS } from '../../constants/endpoints';
import { getRandomString } from '../../helpers';
import { ApiService } from '../../services/api.service';
import { AddressAutocomplete } from './models/address-autocomplete.model';

@Component({
  selector: 'rs-address-control',
  templateUrl: 'address-control.component.html',
  styleUrls: ['address-control.component.scss']
})
export class AddressControlComponent extends Unsubscribe {

  @Output() selectedAutocompletedValue = new EventEmitter<{selectedValue: string, suggestionId: string}>();

  @Input() addressControl: FormControl;

  public addresses: string[];
  public autocompleteOff: string;
  public apiResponse: AddressAutocomplete[];

  constructor(
    private apiService: ApiService,
    private store: Store
  ) {
    super();
    this.autocompleteOff = getRandomString(10);
  }

  public reactOnSelectFromAutocomplete(event: MatAutocompleteSelectedEvent): void {
    const selectedValue = event.option.value;
    const suggestionId = this.apiResponse.find( (value:AddressAutocomplete) => value.address === selectedValue).suggestionId;

    this.selectedAutocompletedValue.emit({selectedValue, suggestionId});
  }

  public reactOnInputText(event: KeyboardEvent) {
    const {value: text} = event.target as HTMLInputElement;

    if (text) {
      this.store.dispatch(new SetSpinnerVisibility(true));

      this.subscribeTo = this.apiService.get(ENDPOINTS.ADDRESS_AUTOCOMPLETE, {text})
        .pipe(
          debounceTime(5000),
          distinctUntilChanged()
        )
        .subscribe((response: AddressAutocomplete[]) => {
          this.apiResponse = response;
          this.addresses = response.map(address => address.address);
          this.store.dispatch(new SetSpinnerVisibility(false));
        });
    }

  }

}
