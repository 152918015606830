import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';

import { Observable, of } from 'rxjs';

import { ENDPOINTS } from 'src/onboarding/shared/constants/endpoints';
import { EnrollmentSteps } from 'src/onboarding/shared/enums/enrollment-steps.enum';
import { checkIsAllStepsCompleted } from 'src/onboarding/shared/helpers';
import { AuthenticationData } from 'src/onboarding/shared/models/authentication.model';
import { BusinessEntityReduced } from 'src/onboarding/shared/models/business-entity.model';
import { Step } from 'src/onboarding/shared/models/step.model';
import { UserProfile } from 'src/onboarding/shared/models/user-profile.model';
import { ApiService } from 'src/onboarding/shared/services/api.service';
import { GoToStep, InitApplication } from './ngxs/layout.actions';
import { LayoutState } from './ngxs/layout.state';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  constructor(
    private apiService: ApiService,
    private store: Store
  ) {}

  public refreshToken(refreshToken: string): Observable<AuthenticationData> {
    return this.apiService.post(ENDPOINTS.REFRESH_TOKEN, {refreshToken});
  }

  public getUserProfile(): Observable<UserProfile> {
    return this.apiService.get(ENDPOINTS.USER_PROFILE_DATA);
  }

  public getBusinessEntities(): Observable<BusinessEntityReduced[]> {
    return this.apiService.get(ENDPOINTS.BUSINESS_ENTITY);
    // return of([]);
  }

  public getBusinessEntityById(businessEntityId: string): Observable<any> {
    return this.apiService.get(`${ENDPOINTS.BUSINESS_ENTITY}/${businessEntityId}`);
  }

}
