<div class="rs-address-control">
  <mat-form-field appearance="outline">
    <mat-label>{{'placeholders.address-1' | translate}}*</mat-label>
    <input
      (input)="reactOnInputText($event)"
      placeholder="{{'placeholders.address-1' | translate}}*"
      type="text"
      matInput
      maxlength="32"
      [matAutocomplete]="auto"
      autocomplete="{{autocompleteOff}}"
      [formControl]="addressControl"
    >
    <mat-error *ngIf="addressControl.hasError('required') && addressControl.touched">{{ "placeholders.required" | translate }}</mat-error>
    <mat-error *ngIf="addressControl.hasError('maxlength')">{{ "placeholders.max-input-length" | translate }}</mat-error>
  </mat-form-field>
  <mat-autocomplete (optionSelected)="reactOnSelectFromAutocomplete($event)" #auto="matAutocomplete">
    <mat-option *ngFor="let option of addresses" [value]="option">
      {{option}}
    </mat-option>
  </mat-autocomplete>
</div>
