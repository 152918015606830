import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ENDPOINTS } from 'src/onboarding/shared/constants/endpoints';
import { ApiService } from 'src/onboarding/shared/services/api.service';
import { Address } from './models/address.model';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  constructor(
    private apiService: ApiService
  ) {}

  public saveRestaurantAddress(legalAddress: Address, address: Address, businessEntityId: string): Observable<void> {
    return this.apiService.put(ENDPOINTS.ADDRESS(businessEntityId), {legalAddress, address});
  }

}
