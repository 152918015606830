import { Injectable } from '@angular/core';

import { Action, State, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { Spinner } from 'src/onboarding/shared/classes/spinner.class';
import { LayoutState } from '../../../layout/ngxs/layout.state';

import { ReviewService } from '../review.service';
import { Submit } from './review.actions';

@State<any>({
  name: 'review'
})
@Injectable()
export class ReviewState extends Spinner {

  constructor(
    protected store: Store,
    private reviewService: ReviewService
  ) {
    super(store);
  }

  @Action(Submit)
  submit() {
    this.showSpinner();

    return this.reviewService.submit(this.store.selectSnapshot(LayoutState.getBusinessEntityId))
      .pipe(
        tap({
          next: () => {
            // this.routerService.navigateToPage(PAGES.SUCCESS);
            // this.sharedDataService.validationCompleted = true;
          },
          complete: () => this.hideSpinner()
        })
      );
  }

}
