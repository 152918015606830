import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Store } from '@ngxs/store';

import { OwnersForm } from 'src/onboarding/shared/classes/owners';
import { IndividualOwner } from 'src/onboarding/shared/models/individual-owner.model';
import { CreateControlPerson } from '../../ngxs/terms-and-agreement.actions';

@Component({
  selector: 'rs-control-person',
  templateUrl: 'control-person.component.html',
  styleUrls: ['control-person.component.scss']
})
export class ControlPersonComponent implements OnInit {

  @Input() controlPerson: IndividualOwner;

  public controlPersonForm: FormGroup = null;

  constructor(
    private store: Store
  ) {}

  public ngOnInit(): void {
    this.controlPersonForm = OwnersForm.getPatchedIndividualForm(this.controlPerson, true);
  }

  public saveButtonDisabled(): boolean {
    return this.controlPersonForm.invalid;
  }

  public createControlPerson(): void {
    const formValue = this.controlPersonForm.value;

    this.store.dispatch(
      new CreateControlPerson(
        new IndividualOwner(formValue, formValue)
      )
    );
  }

}
