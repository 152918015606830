import { NgModule } from '@angular/core';
import { RadioListModule } from 'src/onboarding/shared/components/radio-list/radio-list.module';

import { SharedModule } from 'src/onboarding/shared/modules/shared.module';
import { OwnershipStructureComponent } from './component/ownership-structure.component';

@NgModule({
  imports: [
    SharedModule,
    RadioListModule
  ],
  declarations: [
    OwnershipStructureComponent
  ],
  exports: [
    OwnershipStructureComponent
  ]
})
export class OwnershipStructureModule {}
