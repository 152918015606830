import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { NotifierService } from 'angular-notifier';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { SetSpinnerVisibility } from 'src/onboarding/modules/enrollment/layout/ngxs/layout.actions';
import { NOTIFICATION_TYPES } from '../constants/common';
import { ERROR_CODES } from '../constants/errors';
import { getErrorContentByCode, goToAuthenticationApp } from '../helpers';
import { SharedDataService } from '../services/shared-data.service';

const errorsThatWeShouldIgnore = [4751006];​
// 4751006 - Viewing documents is only allowed after they have been signed

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerInterceptor implements HttpInterceptor {
​
  private store: Store;
  private notifierService: NotifierService;
  private sharedDataService: SharedDataService;
​
  constructor(
    private injector: Injector
  ) {}
​
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.store = this.injector.get(Store);
    this.notifierService = this.injector.get(NotifierService);
    this.sharedDataService = this.injector.get(SharedDataService);
​
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse, caught: Observable<HttpEvent<any>>) => {
          this.store.dispatch(new SetSpinnerVisibility(false));

          if (error?.error?.errors && error.error.errors.codes.includes(ERROR_CODES.SESSION_EXPIRED)) {
            this.sharedDataService.accessToken = null;
            goToAuthenticationApp();
          } else
          if (!errorsThatWeShouldIgnore.includes(error?.error?.code)) {
            this.notifierService.notify(NOTIFICATION_TYPES.ERROR, this.injector.get(TranslateService).instant(getErrorContentByCode(error?.error) || 'Something happened with the server!'));​
          }
​
          throw error;
        })
      );
  }
​
}
