export class SalesDistribution {
  public cardPresentTransactions: number;
  public internetTransactions: number;
  public mailPhoneTransactions: number;

  constructor(salesDistribution: SalesDistribution) {
    this.cardPresentTransactions = Number(salesDistribution.cardPresentTransactions);
    this.internetTransactions = Number(salesDistribution.internetTransactions);
    this.mailPhoneTransactions = Number(salesDistribution.mailPhoneTransactions);
  }
}
