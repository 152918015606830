import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

import { ApiService } from 'src/onboarding/shared/services/api.service';
import { StartSignature } from './models/start-signature.model';
import { Document } from './models/document.model';
import { ENDPOINTS } from 'src/onboarding/shared/constants/endpoints';
import { DocuSignModes } from 'src/onboarding/shared/enums/docu-sign-modes.enum';

@Injectable({
  providedIn: 'root'
})
export class DocumentSigningService {

  constructor(private readonly apiService: ApiService) {}

  public startSignatureDocuments(businessEntityId: string): Observable<StartSignature> {
    return this.apiService.post(ENDPOINTS.START_SIGNING(businessEntityId), {});
  }

  public getDocuments(envelopeId: string): Observable<Document[]> {
    return this.apiService.get(ENDPOINTS.GET_DOCUMENTS(envelopeId));
  }

  public viewDocument(envelopeId: string, documentId: string): Observable<unknown> {
    return this.apiService.post(ENDPOINTS.VIEW_DOCUMENT(envelopeId), {documentId});
  }

  public signDocuments(businessEntityId: string): Observable<unknown> {
    return this.apiService.post(ENDPOINTS.SIGN_DOCUMENTS(businessEntityId), {mode: DocuSignModes.SIGN});
  }
}
