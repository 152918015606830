<div class="rs-rockspoon-plan rs-enrollment-section" id="rockspoonPlan">
  <div class="rs-enrollment-section__content">
    <div class="rs-step-title-bold">
      {{ "titles.choose-your-payment-plan" | translate }}
    </div>
    <div class="rs-rockspoon-plan__sections">
      <div
        class="rs-rockspoon-plan__sections-section"
        *ngFor="let plan of rockspoonPlans"
        [class.rs-selected-item]="isPlanChecked(plan.id)"
        (click)="setPayment(plan.id)"
      >
        <rs-plan-item [plan]="plan"></rs-plan-item>
      </div>
    </div>
  </div>
</div>
