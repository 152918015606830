import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { NotifierService } from 'angular-notifier';
import { forkJoin, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { Spinner } from 'src/onboarding/shared/classes/spinner.class';
import { NOTIFICATION_TYPES } from 'src/onboarding/shared/constants/common';
import { EMPTY_CONTROL_PERSON } from 'src/onboarding/shared/constants/empty-data';
import { EnrollmentSteps } from 'src/onboarding/shared/enums/enrollment-steps.enum';
import { cutBirthDate } from 'src/onboarding/shared/helpers';
import { IndividualOwner } from 'src/onboarding/shared/models/individual-owner.model';
import { UserProfile } from 'src/onboarding/shared/models/user-profile.model';
import { TranslationService } from 'src/onboarding/shared/services/translation.service';
import { GoToStep, RefreshSession, SetStepAsCompleted, SetStepAsUncompleted } from '../../../layout/ngxs/layout.actions';
import { LayoutState } from '../../../layout/ngxs/layout.state';
import { OwnersInformationState } from '../../owners-information/ngxs/owners-information.state';
import { BusinessLeader } from '../model/business-leader.model';
import { Invitation } from '../model/invitation.model';
import { TermsAndAgreementService } from '../terms-and-agreement.service';
import { 
  CreateBusinessLeader,
  CreateControlPerson,
  CreateControlPersonFromIndividualOwner,
  InitTermsAndAgreement,
  SetRequestSignerVisibility,
  YesIAm
} from './terms-and-agreement.actions';

export interface TermsAndAgreementStateModel {
  signUrl: string;
  isDocumentSigningStarted: boolean;
  signed: boolean;
  requestSignerVisibility: boolean;
  controlPerson: IndividualOwner;
  activeInvitation: Invitation;
}

@State<TermsAndAgreementStateModel>({
  name: 'termsAndAgreement',
  defaults: {
    signUrl: null,
    isDocumentSigningStarted: false,
    signed: false,
    requestSignerVisibility: false,
    controlPerson: null,
    activeInvitation: null
  }
})
@Injectable()
export class TermsAndAgreementState extends Spinner {

  constructor(
    protected readonly store: Store,
    private readonly termsAndAgreementService: TermsAndAgreementService,
    private readonly notifierService: NotifierService,
    private translationService: TranslationService
  ) {
    super(store);
  }

  @Selector()
  static getControlPersonFullName(state: TermsAndAgreementStateModel): string {
    return `${state.controlPerson.firstName} ${state.controlPerson.lastName}`;
  }

  @Action(YesIAm)
  yesIAm() {
    const userProfile: UserProfile = this.store.selectSnapshot(LayoutState.getUserProfile);
    const individuals = this.store.selectSnapshot(OwnersInformationState.individuals);
    const amIIndividualOwner = individuals.findIndex(owner => owner.email === userProfile.email);
    const businessLeader: BusinessLeader = {
      email: userProfile.email,
      firstName: userProfile.firstName,
      lastName: userProfile.lastName
    };
    const action = ~amIIndividualOwner ?
      this.store.dispatch(new CreateControlPersonFromIndividualOwner(individuals[amIIndividualOwner])) :
      this.store.dispatch(new CreateBusinessLeader(businessLeader));

    return action
      .pipe(
        switchMap(() => this.store.dispatch(new RefreshSession()))
      );
  }

  @Action(InitTermsAndAgreement)
  initTermsAndAgreement({patchState}: StateContext<TermsAndAgreementStateModel>) {
    const businessEntityId = this.store.selectSnapshot(LayoutState.getBusinessEntityId);

    return forkJoin([
      this.termsAndAgreementService.getInvites(businessEntityId),
      this.termsAndAgreementService.getControlPerson(businessEntityId)
    ])
      .pipe(
        tap({
          next: ([invitations, controlPerson]: [Invitation[], IndividualOwner]) => {
            patchState({
              activeInvitation: invitations.find(invitation => invitation.active),
              controlPerson: controlPerson.email ? controlPerson : EMPTY_CONTROL_PERSON
            });
          }
        })
      );
  }

  @Action(SetRequestSignerVisibility)
  setRequestSignerVisibility({patchState}: StateContext<TermsAndAgreementStateModel>, {visibility}: SetRequestSignerVisibility) {
    patchState({
      requestSignerVisibility: visibility
    });
  }

  @Action(CreateBusinessLeader)
  makeMeAsBusinessLeader({patchState, getState}: StateContext<TermsAndAgreementStateModel>, {businessLeader}: CreateBusinessLeader) {
    const businessEntityId = this.store.selectSnapshot(LayoutState.getBusinessEntityId);
    this.showSpinner();

    return this.termsAndAgreementService.createBusinessLeader(
      businessEntityId,
      businessLeader
    )
      .pipe(
        switchMap(() => {
          if (getState().activeInvitation?.email !== businessLeader.email) {
            return this.termsAndAgreementService.clearControlPerson(businessEntityId);
          } else {
            return of({});
          }
        }),
        switchMap(() => this.store.dispatch(new InitTermsAndAgreement())),
        tap({
          complete: () => {
            patchState({
              requestSignerVisibility: false
            });
            this.store.dispatch(new SetStepAsUncompleted(EnrollmentSteps.termsAndAgreement));
            this.hideSpinner();
          },
        })
      );
  }

  @Action(CreateControlPerson)
  createControlPerson({patchState}: StateContext<TermsAndAgreementStateModel>, {controlPerson}: CreateControlPerson) {
    const businessEntityId = this.store.selectSnapshot(LayoutState.getBusinessEntityId);
    this.showSpinner();

    return this.termsAndAgreementService.createControlPerson(businessEntityId, controlPerson)
      .pipe(
        switchMap(() => this.store.dispatch(new InitTermsAndAgreement())),
        tap({
          complete: () => {
            patchState({
              requestSignerVisibility: false
            });
            this.store.dispatch(new SetStepAsCompleted(EnrollmentSteps.termsAndAgreement));
            this.store.dispatch(new GoToStep(EnrollmentSteps.contracts));
            this.notifierService.notify(NOTIFICATION_TYPES.SUCCESS, this.translationService.getTranslation('success.control-person'));
            this.hideSpinner();
          }
        })
      );
  }

  @Action(CreateControlPersonFromIndividualOwner)
  createControlPersonFromIndividualOwner({patchState}: StateContext<TermsAndAgreementStateModel>, {individualOwner}: CreateControlPersonFromIndividualOwner) {
    const businessEntityId = this.store.selectSnapshot(LayoutState.getBusinessEntityId);
    this.showSpinner();

    return this.termsAndAgreementService.createBusinessLeader(
      businessEntityId,
      {
        email: individualOwner.email,
        firstName: individualOwner.firstName,
        lastName: individualOwner.lastName
      }
    )
      .pipe(
        switchMap(() => this.termsAndAgreementService.createControlPerson(
          businessEntityId,
          {
            ...individualOwner,
            dateOfBirth: cutBirthDate(individualOwner.dateOfBirth)
          }
        )),
        switchMap(() => this.store.dispatch(new InitTermsAndAgreement())),
        tap({
          complete: () => {
            patchState({
              requestSignerVisibility: false
            });
            this.hideSpinner();
          }
        })
      )
  }

}
