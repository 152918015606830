<div class="rs-radio-list">
  <div class="rs-radio-list__title rs-step-title">{{caption}}</div>
  <div class="rs-radio-list__items">
    <mat-radio-group [formControl]="radioControl" (change)="reactOnChange()">
      <mat-radio-button *ngFor="let item of items" [value]="item.value">{{item.title}}</mat-radio-button>
      <mat-radio-button *ngIf="isShowOtherChoice" [value]="'other'" class="rs-radio-list__items-other">
        <mat-form-field appearance="outline">
          <mat-label>{{'placeholders.other' | translate}}</mat-label>
          <input
            #otherValueInput
            [disabled]="isOtherValueDisabled()"
            [(ngModel)]="otherValue"
            placeholder="{{'placeholders.other' | translate}}"
            type="text"
            matInput
          >
        </mat-form-field>
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="rs-radio-list__footer">
    <rs-section-footer
      (canceled)="reactOnCanceledClick()"
      (accepted)="reactOnAcceptedClick()"
      [disabled]="!isNextButtonValid()"
      [buttonText]="acceptButtonText"
      [cancelButtonText]="cancelButtonText"
    ></rs-section-footer>
  </div>
</div>
