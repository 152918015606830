import { BusinessEntityExtended } from 'src/onboarding/shared/models/business-entity.model';
import { CompanyOwner } from 'src/onboarding/shared/models/company-owner.model';
import { IndividualOwner } from 'src/onboarding/shared/models/individual-owner.model';

export class InitOwners {
  static readonly type = '[Owners] Init';

  constructor(
    public businessEntity: BusinessEntityExtended
  ) {}
}

export class SaveOwners {
  static readonly type = '[Owners] Save All';

  constructor(
    public individualOwners: IndividualOwner[],
    public companyOwners: CompanyOwner[]
  ) {}
}

export class DeleteIndividual {
  static readonly type = '[Individual] Delete';

  constructor(
    public index: string | number
  ) {}
}

export class DeleteCompany {
  static readonly type = '[Company] Delete';

  constructor(
    public index: string | number
  ) {}
}

export class UpdateIndividual {
  static readonly type = '[Individual] Update';

  constructor(
    public individualOwner: IndividualOwner,
    public index: string | number
  ) {}
}

export class UpdateCompany {
  static readonly type = '[Company] Update';

  constructor(
    public companyOwner: IndividualOwner,
    public index: string | number
  ) {}
}

export class ChangeOwnersFlowStatus {
  static readonly type = '[Owners] Change Flow Status';

  constructor(
    public flowStatus: boolean
  ) {}
}
