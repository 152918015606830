import moment from 'moment';
import { IndividualData } from './forms/individual.model';

export class DocumentDetails {
  documentIssuer: string;
  documentType: string;
  expireDate: string;
  idNumber: string;
  issueDate: string;
  issuingState: string;

  constructor(individualData: IndividualData) {
    this.documentIssuer = individualData.documentIssuer;
    this.documentType = individualData.documentType;
    this.expireDate = moment(individualData.expireDate).format();
    this.idNumber = individualData.idNumber;
    this.issueDate = moment(individualData.issueDate).format();
    this.issuingState = `US-${individualData.issuingState}`;
  }
}
