import { Address } from 'src/onboarding/modules/enrollment/flow/address/models/address.model';
import { getValidProneNumber } from '../helpers';
import { CompanyData } from './forms/company.model';

export class CompanyOwner {

  public businessName: string;
  public email: string;
  public firstName: string;
  public lastName: string;
  public hubSpotContactID: string;
  public phone: string;
  public share: number;
  public tin: string;
  public address: Address;

  constructor(companyData: CompanyData, legalAddress: Address) {
    this.businessName = companyData.businessName;
    this.email = companyData.email;
    this.firstName = companyData.firstName || 'test_name'; //These fields are required for API
    this.lastName = companyData.lastName || 'test_name';
    this.hubSpotContactID = '';
    this.phone = getValidProneNumber(companyData.phone.number);
    this.share = Number(companyData.share);
    this.tin = companyData.tin;
    this.address = new Address(legalAddress);
  }

}
