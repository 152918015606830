import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { Overlay } from '@angular/cdk/overlay';

import { DIALOG_SIZES } from 'src/onboarding/shared/constants/common';
import { ConfirmationPopupComponent } from './confirmation.component';

@Directive({
  selector: '[rsConfirmation]'
})
export class ConfirmationPopupDirective {

  @Output() accepted = new EventEmitter<void>();

  @Input() text: string;

  constructor(
    private dialog: MatDialog,
    private overlay: Overlay
  ) {}

  @HostListener('click', ['$event'])
  openConfirmation(event: MouseEvent): void {
    event.stopPropagation();
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: DIALOG_SIZES.MIDDLE,
      data: this.text,
      scrollStrategy: this.overlay.scrollStrategies.noop()
    });

    dialogRef.afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.accepted.emit();
        }
      });
  }

}
