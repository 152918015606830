import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ENDPOINTS } from 'src/onboarding/shared/constants/endpoints';
import { ApiService } from 'src/onboarding/shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class RestaurantStatusService {

  constructor(
    private apiService: ApiService
  ) {}

  public setRestaurantStatus(restaurantStatus: string, businessEntityId: string | number): Observable<void> {
    return this.apiService.put(ENDPOINTS.RESTAURANT_STATUS(businessEntityId), {restaurantStatus});
  }

  public setPosReplacement(posReplacement: string, businessEntityId: string | number): Observable<void> {
    return this.apiService.put(ENDPOINTS.POS_REPLACEMENT(businessEntityId), {posReplacement});
  }

}
