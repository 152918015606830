import { NgModule } from '@angular/core';

import { NgxsModule } from '@ngxs/store';

import { RadioListModule } from 'src/onboarding/shared/components/radio-list/radio-list.module';
import { SectionFooterModule } from 'src/onboarding/shared/components/section-footer/section-footer.module';
import { MaterialModule } from 'src/onboarding/shared/modules/material.module';
import { SharedModule } from 'src/onboarding/shared/modules/shared.module';
import { RestaurantStatusItemComponent } from './components/restaurant-status-item/restaurant-status-item.component';
import { RestaurantStatusComponent } from './components/restaurant-status/restaurant-status.component';
import { RestaurantStatusState } from './ngxs/restaurant-status.state';

@NgModule({
  imports: [
    SharedModule,
    MaterialModule,
    SectionFooterModule,
    RadioListModule,
    NgxsModule.forFeature([RestaurantStatusState])
  ],
  declarations: [
    RestaurantStatusComponent,
    RestaurantStatusItemComponent
  ],
  exports: [
    RestaurantStatusComponent
  ]
})
export class RestaurantStatusModule {}
