<div
  class="rs-restaurant-status-item"
  matRipple
  matRippleColor="#00a7ef10"
  [class.rs-restaurant-status-item_selected]="selected"
>
  <div class="rs-restaurant-status-item__icon">
    <img src="assets/img-new/{{icon}}.png">
  </div>
  <div class="rs-restaurant-status-item__title">{{title}}</div>
</div>
