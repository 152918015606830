<div *ngIf="bankingInfo" class="rs-review-banking-information">
  <div class="rs-review-step">{{ "review.banking" | translate }}</div>
  <div class="rs-review-banking-information__form rs-form-control-outline">
    <div class="rs-row">
      <mat-form-field appearance="outline">
        <mat-label>{{ "placeholders.routing-number" | translate }}</mat-label>
        <input matInput disabled value="{{ bankingInfo.routingNumber }}">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ "placeholders.account-number" | translate }}</mat-label>
        <input matInput disabled value="{{ bankingInfo.accountNumber }}">
      </mat-form-field>
    </div>
  </div>
</div>
