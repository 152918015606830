<div class="rs-section-footer">
  <div class="rs-line"></div>
  <div class="rs-section-footer__actions">
    <button
      [disabled]="disabled"
      (click)="reactOnButtonClick()"
      mat-raised-button color="primary"
      class="rs-blue-button"
      [ngClass]="{'initial-content': bigContent}"
    >
      {{ buttonText }}
    </button>
    <button
      *ngIf="cancelButtonText"
      (click)="reactOnCancelButtonClick()"
      mat-raised-button color="primary"
      class="rs-blue-button"
      [ngClass]="{'initial-content': bigContent}"
    >
      {{ cancelButtonText }}
    </button>
  </div>
</div>
