<div *ngIf="salesDistributionForm" class="rs-review-sales-distribution" [formGroup]="salesDistributionForm">
  <div class="rs-review-sales-distribution__title rs-review-step">Sales Distribution</div>
  <div class="rs-form-control-outline">
    <mat-form-field appearance="outline">
      <mat-label>Percentage of Card Present Sales Volume</mat-label>
      <input formControlName="cardPresentTransactions" type="text" matInput>
    </mat-form-field>
  </div>
  <div class="rs-form-control-outline">
    <mat-form-field appearance="outline">
      <mat-label>Percentage of Internet Sales Volume</mat-label>
      <input formControlName="internetTransactions" type="text" matInput>
    </mat-form-field>
  </div>
  <div class="rs-form-control-outline">
    <mat-form-field appearance="outline">
      <mat-label>Percentage of Phone Sales Volume</mat-label>
      <input formControlName="mailPhoneTransactions" type="text" matInput>
    </mat-form-field>
  </div>
</div>
