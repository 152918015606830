import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'rs-accept-input',
  templateUrl: 'accept-input.component.html',
  styleUrls: ['accept-input.component.scss']
})
export class AcceptInputComponent {

  @Output() accepted = new EventEmitter();

  @Input() rsFormControl: FormControl;
  @Input() placeholder: string;

  public isAcceptButtonDisabled(): boolean {
    return this.rsFormControl.invalid || !this.rsFormControl.value;
  }

  public reactOnAccept(): void {
    this.accepted.emit();
  }

}
