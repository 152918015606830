import { BusinessEntityExtended } from 'src/onboarding/shared/models/business-entity.model';
import { RestaurantName } from '../models/restaurant-name.model';

export class SetRestaurantName {
  static readonly type = '[Business Name] Set';

  constructor(
    public restaurantName: RestaurantName
  ) {}
}

export class UpdateRestaurantName {
  static readonly type = '[Business Name] Update';

  constructor(
    public restaurantName: RestaurantName,
    public businessEntityId: string
  ) {}
}

export class CreateRestaurantName {
  static readonly type = '[Business Name] Create';

  constructor(
    public restaurantName: RestaurantName
  ) {}
}

export class InitRestaurantName {
  static readonly type = '[Business Name] Init';

  constructor(
    public businessEntity: BusinessEntityExtended
  ) {}
}
