import { NgModule } from '@angular/core';

import { NgxsModule } from '@ngxs/store';
import { ControlWithMaskModule } from 'src/onboarding/shared/components/control-with-mask/control-with-mask.module';

import { SectionFooterModule } from 'src/onboarding/shared/components/section-footer/section-footer.module';
import { DirectivesModule } from 'src/onboarding/shared/directives/directives.module';
import { MaterialModule } from 'src/onboarding/shared/modules/material.module';
import { SharedModule } from 'src/onboarding/shared/modules/shared.module';
import { CompanyInformationComponent } from './component/company-information.component';
import { CompanyInformationState } from './ngxs/company-information.state';

@NgModule({
  imports: [
    SharedModule,
    MaterialModule,
    SectionFooterModule,
    DirectivesModule,
    ControlWithMaskModule,
    NgxsModule.forFeature([CompanyInformationState])
  ],
  declarations: [
    CompanyInformationComponent
  ],
  exports: [
    CompanyInformationComponent
  ]
})
export class CompanyInformationModule {}
