import { Injectable } from '@angular/core';

import { Action, State, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { Spinner } from 'src/onboarding/shared/classes/spinner.class';
import { SERVICE_TYPES } from 'src/onboarding/shared/constants/common';
import { EnrollmentSteps } from 'src/onboarding/shared/enums/enrollment-steps.enum';
import { getKeyFromEnum } from 'src/onboarding/shared/helpers';
import { GoToStep, RefreshApplication, SetStepAsCompleted } from '../../../layout/ngxs/layout.actions';
import { LayoutState } from '../../../layout/ngxs/layout.state';
import { ServiceTypeService } from '../service-type.service';
import { InitServiceType, SetServiceType } from './service-type.actions';

interface ServiceTypeStateModel {
  serviceType: string;
}

@State<ServiceTypeStateModel>({
  name: 'serviceType',
  defaults: {
    serviceType: null
  }
})
@Injectable()
export class ServiceTypeState extends Spinner {
  constructor(
    protected store: Store,
    private serviceTypeService: ServiceTypeService
  ) {
    super(store);
  }

  @Action(InitServiceType)
  initServiceType({patchState}: StateContext<ServiceTypeStateModel>, {businessEntity}: InitServiceType) {
    patchState({
      serviceType: SERVICE_TYPES[businessEntity.serviceModel]
    });
  }

  @Action(SetServiceType)
  setBusinessType(
    { patchState }: StateContext<ServiceTypeStateModel>,
    { serviceType }: SetServiceType
  ) {
    this.showSpinner();
    const id = this.store.selectSnapshot(LayoutState.getBusinessEntityId);
    const serviceTypeValue = getKeyFromEnum(SERVICE_TYPES, serviceType);

    return this.serviceTypeService.setBusinessType(serviceTypeValue, id)
      .pipe(
        tap({
          next: () => {
            const actions = [new SetStepAsCompleted(EnrollmentSteps.serviceType)];

            if (serviceType === SERVICE_TYPES.table) {
              actions.push(new GoToStep(EnrollmentSteps.salesDistribution));
            }

            patchState({ serviceType });
            this.store.dispatch(actions);
          },
          complete: () => {
            this.hideSpinner();
            this.store.dispatch(new RefreshApplication());
          }
        })
      );
  }

}
