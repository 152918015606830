import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { Unsubscribe } from 'src/onboarding/shared/classes/unsubscribe.class';
import { Step } from 'src/onboarding/shared/models/step.model';

@Component({
  selector: 'rs-side-nav',
  templateUrl: 'side-nav.component.html',
  styleUrls: ['side-nav.component.scss', 'side-nav.component.responsive.scss']
})
export class SideNavComponent extends Unsubscribe implements OnInit {

  @Select(state => state.layout.steps) public steps$: Observable<Step[]>;

  @Input() isReview: boolean;

  public steps: Step[];

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.initSubscriptions();
  }

  public goToPrivacyPolicy(): void {
    window.open('../../assets/files/Privacy_Policy.pdf');
  }

  private initSubscriptions(): void {
    this.subscribeTo = this.steps$
      .subscribe((steps: Step[]) => {
        this.steps = steps;
        this.changeDetectorRef.markForCheck();
      });
  }

}
