<div class="rs-terms-and-agreement rs-enrollment-section" id="termsAndAgreement">
  <div class="rs-enrollment-section__content">
    <rs-are-you-signer [amIInvitedPerson]="amIInvitedPerson" [wasAnotherPersonInvited]="wasAnotherPersonInvited()"></rs-are-you-signer>
    <div class="rs-terms-and-agreement__control-person" *ngIf="amIInvitedPerson && !requestSignerVisibility">
      <rs-control-person [controlPerson]="controlPerson"></rs-control-person>
    </div>
    <div class="rs-terms-and-agreement__request-signer">
      <rs-request-signer *ngIf="requestSignerVisibility"></rs-request-signer>
    </div>
    <rs-invited-list [activeInvitation]="activeInvitation" *ngIf="invitationListVisibility()"></rs-invited-list>
  </div>
</div>
