<div class="rs-service-type rs-enrollment-section" id="serviceType">
  <div class="rs-enrollment-section__content">
    <div class="rs-step-title">{{'enrollment-step-titles.service-type' | translate}}</div>
    <div class="rs-service-type__items">
      <div class="rs-service-type__item" *ngFor="let serviceType of serviceTypes">
        <rs-type-item
          (click)="selectServiceType(serviceType)"
          [selected]="isServiceTypeSelected(serviceType)"
          [caption]="serviceType"
        ></rs-type-item>
      </div>
    </div>
    <div class="rs-service-type__message" *ngIf="warningVisibility">
      <rs-message [headerIsNotVisible]="true">
        <div id="content">
          <mat-icon>error</mat-icon> {{'service-type.warning' | translate}}
        </div>
      </rs-message>
    </div>
    <div class="rs-service-type__footer" *ngIf="warningVisibility">
      <rs-section-footer (accepted)="goToNextStep()" buttonText="{{'buttons.next' | translate}}"></rs-section-footer>
    </div>
  </div>
</div>
