import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { EnrollmentPage } from 'src/onboarding/shared/classes/enrollment-page.class';
import { POS_SYSTEMS, RESTAURANT_STATUSES } from 'src/onboarding/shared/constants/common';
import { ENROLLMENT_SECTIONS } from 'src/onboarding/shared/constants/enrollment-sections.constants';
import { RestaurantStatuses } from 'src/onboarding/shared/enums/restaurant-statuses.enum';
import { ListItem } from 'src/onboarding/shared/models/list-item.model';
import { RestaurantStatus } from 'src/onboarding/shared/models/restaurant-status.model';
import { SetRestaurantStatus } from '../../ngxs/restaurant-status.actions';
import { RestaurantStatusStateModel } from '../../ngxs/restaurant-status.state';

@Component({
  selector: 'rs-restaurant-status',
  templateUrl: 'restaurant-status.component.html',
  styleUrls: ['restaurant-status.component.scss', 'restaurant-status.component.responsive.scss']
})
export class RestaurantStatusComponent extends EnrollmentPage implements OnInit {

  @Select(state => state.restaurantStatus) restaurantStatusState$: Observable<RestaurantStatusStateModel>;

  public restaurantStatuses: RestaurantStatus[] = RESTAURANT_STATUSES;
  public posSystems: ListItem[] = POS_SYSTEMS;
  public posSystemVisibility: boolean;
  public posSystemControl = new FormControl('', Validators.required);
  public posSystemValue: string;
  public restaurantStatusValue: RestaurantStatuses = null;

  private restaurantStatus: RestaurantStatuses;

  constructor(
    protected store: Store
  ) {
    super(ENROLLMENT_SECTIONS.RESTAURANT_STATUS, store);
  }

  ngOnInit() {
    super.ngOnInit();
    this.initSubscriptions();
  }

  public reactOnPosSystemCanceled(): void {
    this.posSystemVisibility = false;
  }

  public reactOnPosSystemAccepted(posSystem: string): void {
    this.posSystemValue = posSystem;
    this.subscribeTo = this.store.dispatch(new SetRestaurantStatus(this.restaurantStatusValue, posSystem))
      .subscribe(() => this.posSystemVisibility = false);
  }

  public isStatusSelected(restaurantStatusValue: RestaurantStatuses): boolean {
    return restaurantStatusValue === this.restaurantStatus;
  }

  public reactOnSelectRestaurantStatus(restaurantStatusValue: RestaurantStatuses): void {
    this.restaurantStatusValue = restaurantStatusValue;

    if (
      restaurantStatusValue === RestaurantStatuses.operational ||
      restaurantStatusValue === RestaurantStatuses.temporarilyClosed
    ) {
      this.posSystemVisibility = true;
    } else {
      this.subscribeTo = this.store.dispatch(new SetRestaurantStatus(restaurantStatusValue, null))
        .subscribe(() => this.posSystemVisibility = false);
    }
  }

  private initSubscriptions(): void {
    this.subscribeTo = this.restaurantStatusState$
      .subscribe(restaurantStatusState => {
        this.restaurantStatus = restaurantStatusState.restaurantStatus;
        this.posSystemValue = restaurantStatusState.posSystem;
      });
  }

}
