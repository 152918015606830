import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { Select, Store } from '@ngxs/store';
import { combineLatest, Observable } from 'rxjs';

import { EnrollmentPage } from 'src/onboarding/shared/classes/enrollment-page.class';
import { ENROLLMENT_SECTIONS } from 'src/onboarding/shared/constants/enrollment-sections.constants';
import { IndividualOwner } from 'src/onboarding/shared/models/individual-owner.model';
import { UserProfile } from 'src/onboarding/shared/models/user-profile.model';
import { Invitation } from '../../model/invitation.model';

@Component({
  selector: 'rs-terms-and-agreement',
  templateUrl: 'terms-and-agreement.component.html',
  styleUrls: ['terms-and-agreement.component.scss']
})
export class TermsAndAgreementComponent extends EnrollmentPage implements OnInit {
  @Select(state => state.termsAndAgreement.requestSignerVisibility) requestSignerVisibility$: Observable<boolean>;
  @Select(state => state.termsAndAgreement.controlPersonVisibility) controlPersonVisibility$: Observable<boolean>;
  @Select(state => state.termsAndAgreement.controlPerson) controlPerson$: Observable<IndividualOwner>;
  @Select(state => state.termsAndAgreement.activeInvitation) activeInvitation$: Observable<Invitation>;
  @Select(state => state.layout.userProfile) userProfile$: Observable<UserProfile>;

  public controlPerson: IndividualOwner;
  public requestSignerVisibility: boolean;
  public activeInvitation: Invitation;
  public amIInvitedPerson: boolean;

  constructor(
    protected store: Store,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super(ENROLLMENT_SECTIONS.TERMS_AND_AGREEMENT, store);
  }

  ngOnInit() {
    super.ngOnInit();
    this.initSubscriptions();
  }

  public wasAnotherPersonInvited(): boolean {
    return this.activeInvitation && !this.amIInvitedPerson;
  }

  public invitationListVisibility(): boolean {
    return this.activeInvitation && !this.amIInvitedPerson && !this.requestSignerVisibility;
  }

  private initSubscriptions(): void {
    this.subscribeTo = this.controlPerson$
      .subscribe((controlPerson: IndividualOwner) => this.controlPerson = controlPerson);
    this.subscribeTo = this.requestSignerVisibility$
      .subscribe((requestSignerVisibility: boolean) => this.requestSignerVisibility = requestSignerVisibility);
    this.subscribeTo = combineLatest([
      this.activeInvitation$,
      this.userProfile$
    ])
      .subscribe(([activeInvitation, userProfile]: [Invitation, UserProfile]) => {
        this.activeInvitation = activeInvitation;
        this.amIInvitedPerson = activeInvitation?.email === userProfile?.email;
        this.changeDetectorRef.markForCheck();
      });
  }
}
