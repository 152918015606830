import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Action, Select, Selector, State, StateContext, Store } from '@ngxs/store';
import { forkJoin, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { RouterService } from 'src/onboarding/shared/services/router.service';
import { Spinner } from 'src/onboarding/shared/classes/spinner.class';
import { STEPS } from 'src/onboarding/shared/constants/steps.constants';
import { EnrollmentSteps } from 'src/onboarding/shared/enums/enrollment-steps.enum';
import { checkIsAllStepsCompleted, copy } from 'src/onboarding/shared/helpers';
import { AuthenticationData } from 'src/onboarding/shared/models/authentication.model';
import { Step } from 'src/onboarding/shared/models/step.model';
import { UserProfile } from 'src/onboarding/shared/models/user-profile.model';
import { SharedDataService } from 'src/onboarding/shared/services/shared-data.service';
import { InitRestaurantAddress } from '../../flow/address/ngxs/address.actions';
import { InitBankingInformation } from '../../flow/bank-account/ngxs/bank-account.actions';
import { InitOwners } from '../../flow/owners-information/ngxs/owners-information.actions';
import { InitCompanyInformation } from '../../flow/company-information/ngxs/company-information.actions';
import { InitOwnershipStructure } from '../../flow/ownership-structure/ngxs/ownership-structure.actions';
import { InitRestaurantName } from '../../flow/restaurant-name/ngxs/restaurant-name.actions';
import { InitRestaurantStatus } from '../../flow/restaurant-status/ngxs/restaurant-status.actions';
import { GetPaymentPlans, InitPaymentPlan } from '../../flow/rockspoon-plan/ngxs/rockspoon-plan.actions';
import { InitSalesDistribution } from '../../flow/sales-distribution/ngxs/sales-distribution.actions';
import { InitServiceType } from '../../flow/service-type/ngxs/service-type.actions';
import { InitWebsiteUrl } from '../../flow/website/ngxs/website.actions';
import { LayoutService } from '../layout.service';
import { BusinessEntityStatuses } from 'src/onboarding/shared/enums/business-entity-statuses.enum';
import { InitTermsAndAgreement } from '../../flow/terms-and-agreement/ngxs/terms-and-agreement.actions';
import {
  BackEndStepsNaming,
  BusinessEntityExtended,
  BusinessEntityReduced,
  onboardingStepsMap
} from 'src/onboarding/shared/models/business-entity.model';
import {
  InitSteps,
  SetStepAsCompleted,
  SetStepAsActive,
  GoToStep,
  SetSpinnerVisibility,
  RefreshSession,
  InitApplication,
  SetBusinessEntityId,
  RefreshApplication,
  SetStepAsUncompleted,
  CheckNecessaryStepsAsCompleted,
  FinishFlowIfStepsAreCompleted
} from './layout.actions';
import { environment } from 'src/environments/environment';
import { Address } from '../../flow/address/models/address.model';
import { UserRolesForBusinessEntity } from 'src/onboarding/shared/enums/common';

interface LayoutStateModel {
  steps: Step[];
  spinnerVisibility: boolean;
  userProfile: UserProfile;
  businessEntity: BusinessEntityExtended;
  contentVisibility: boolean;
  businessEntityId: string;
  isNecessaryStepsCompleted: boolean;
  isUserLeaderForBusinessEntity: boolean;
}

@State<LayoutStateModel>({
  name: 'layout',
  defaults: {
    steps: [],
    spinnerVisibility: false,
    userProfile: null,
    businessEntity: null,
    contentVisibility: false,
    businessEntityId: null,
    isNecessaryStepsCompleted: false,
    isUserLeaderForBusinessEntity: false
  }
})
@Injectable()
export class LayoutState extends Spinner {

  constructor(
    private sharedDataService: SharedDataService,
    private activatedRoute: ActivatedRoute,
    private layoutService: LayoutService,
    private routerService: RouterService,
    protected store: Store
  ) {
    super(store);
  }

  @Selector()
  static getBusinessEntity(state: LayoutStateModel): BusinessEntityExtended {
    return state.businessEntity;
  }

  @Selector()
  static getIsUserLeader(state: LayoutStateModel): boolean {
    return state.isUserLeaderForBusinessEntity;
  }

  @Selector()
  static getBusinessEntityId(state: LayoutStateModel): string {
    return state.businessEntityId;
  }

  @Selector()
  static getUserProfile(state: LayoutStateModel): UserProfile {
    return state.userProfile;
  }

  @Selector()
  static getSteps(state: LayoutStateModel): Step[] {
    return state.steps;
  }

  @Select()
  static getBusinessEntityStatus(state: LayoutStateModel): BusinessEntityStatuses {
    return state.businessEntity?.status;
  }

  @Action(SetBusinessEntityId)
  setBusinessEntityId({patchState}: StateContext<LayoutStateModel>, {id}: SetBusinessEntityId) {
    patchState({
      businessEntityId: id
    });
  }

  @Action(InitApplication)
  initApplication({patchState, getState}: StateContext<LayoutStateModel>) {
    this.showSpinner();

    return forkJoin([
      this.layoutService.getBusinessEntities(),
      this.layoutService.getUserProfile(),
      this.store.dispatch(new GetPaymentPlans())
    ])
      .pipe(
        tap({
          next: (responses: [BusinessEntityReduced[], UserProfile, any]) => {
            const [businessEntities, userProfile] = responses;
            let state: Partial<LayoutStateModel> = {
              userProfile,
              contentVisibility: true,
            };

            if (businessEntities.length) {
              const businessEntityId = businessEntities[businessEntities.length - 1].id;
              state.businessEntityId = businessEntityId;
              state.isUserLeaderForBusinessEntity = userProfile.businessEntities[businessEntityId] === UserRolesForBusinessEntity.leader;
            }

            patchState(state);
          }
        }),
        switchMap(() => {
          const { businessEntityId } = getState();
          return businessEntityId ? this.layoutService.getBusinessEntityById(businessEntityId) : of(null);
        }),
        tap({
          next: (businessEntity: any) => {
            if (businessEntity) {
              patchState({
                businessEntity
              });
              this.store.dispatch(new InitSteps());
              this.store.dispatch(new InitRestaurantName(businessEntity));
              this.store.dispatch(new InitRestaurantStatus(businessEntity));
              this.store.dispatch(new InitWebsiteUrl(businessEntity));
              this.store.dispatch(new InitRestaurantAddress(businessEntity));
              this.store.dispatch(new InitServiceType(businessEntity));
              this.store.dispatch(new InitSalesDistribution(businessEntity));
              this.store.dispatch(new InitPaymentPlan(businessEntity));
              this.store.dispatch(new InitOwnershipStructure(businessEntity));
              this.store.dispatch(new InitBankingInformation(businessEntity));
              this.store.dispatch(new InitCompanyInformation(businessEntity));
              this.store.dispatch(new InitOwners(businessEntity));
              this.store.dispatch(new InitTermsAndAgreement());
              this.getSelectedStep();
            }
          }
        }),
        tap({
          complete: () => {
            this.hideSpinner();
            this.store.dispatch(new FinishFlowIfStepsAreCompleted());
          }
        })
      );
  }

  @Action(RefreshSession)
  refreshSession() {
    return this.layoutService.refreshToken(this.sharedDataService.refreshToken)
      .pipe(
        tap({
          next: (authenticationData: AuthenticationData) => {
            this.sharedDataService.accessToken = authenticationData.accessToken;
            this.sharedDataService.refreshToken = authenticationData.refreshToken;
            this.store.dispatch(new InitApplication());
          }
        })
      );
  }

  @Action(SetSpinnerVisibility)
  setSpinnerVisibility({patchState}: StateContext<LayoutStateModel>, {spinnerVisibility}: SetSpinnerVisibility) {
    patchState({
      spinnerVisibility
    });
  }

  @Action(SetStepAsCompleted)
  setNavigationItemAsCompleted({patchState, getState}: StateContext<LayoutStateModel>, {stepId}: SetStepAsCompleted) {
    const steps: Step[] = copy(getState().steps.map(navItem => ({...navItem, active: false})));
    const stepIndex = steps.findIndex(step => step.id === stepId);

    steps[stepIndex].completed = true;
    patchState({
      steps
    });
  }

  @Action(SetStepAsUncompleted)
  setStepAsUncompleted({patchState, getState}: StateContext<LayoutStateModel>, {stepId}: SetStepAsCompleted) {
    const steps: Step[] = copy(getState().steps);
    const stepIndex = steps.findIndex(step => step.id === stepId);

    steps[stepIndex].completed = false;

    patchState({
      steps
    });
  }

  @Action(SetStepAsActive)
  setNavigationItemAsActive({patchState, getState}: StateContext<LayoutStateModel>, {stepId}: SetStepAsActive) {
    const prevStepsState = copy(getState().steps) as Step[];
    const stepIndex = prevStepsState.findIndex(step => step.id === stepId);

    if (!prevStepsState[stepIndex].active) {
      const steps: Step[] = copy(getState().steps.map(step => ({...step, active: false})));

      steps[stepIndex].active = true;
      patchState({
        steps
      });
    }
  }

  @Action(GoToStep)
  goToNavigationItem({getState}: StateContext<LayoutStateModel>, {stepId}: GoToStep) {
    this.scrollToStep(stepId);
    this.store.dispatch(new CheckNecessaryStepsAsCompleted());
  }

  @Action(InitSteps)
  initSteps({patchState, getState}: StateContext<LayoutStateModel>) {
    const {businessEntity} = getState();
    let steps: Step[];

    if (businessEntity) {
      steps = copy(getState().steps);
      Object.keys(businessEntity.onboardingSteps || {}).forEach((backEndStepKey: BackEndStepsNaming) => {
        if (businessEntity.onboardingSteps[backEndStepKey]) {
          steps = steps.map(step => {
            if (step.id === onboardingStepsMap.get(backEndStepKey)) {
              step.completed = true;
            }

            return step;
          })
        }
      });

      if (businessEntity.onboardingSteps.termsSigned) {
        this.store.dispatch(new SetStepAsCompleted(EnrollmentSteps.reviewInformation));
      }
    } else {
      steps = copy(STEPS) as Step[];
    }

    patchState({
      steps
    });
  }

  @Action(FinishFlowIfStepsAreCompleted)
  finishFlowIfStepsAreCompleted({getState}: StateContext<LayoutStateModel>) {
    const steps = getState().steps;
    const businessEntityId = getState().businessEntityId;

    if (steps.every(step => step.completed)) {
      location.replace(`${environment.endOfTheFlowUrl}${businessEntityId}`);
    }
  }

  @Action(RefreshApplication)
  refreshApplication() {
    const steps: Step[] = this.store.selectSnapshot(LayoutState.getSteps);

    if (checkIsAllStepsCompleted(steps)) {
      this.store.dispatch([
        new InitApplication(),
        new GoToStep(EnrollmentSteps.reviewInformation)
      ]);
    }
  }

  @Action(CheckNecessaryStepsAsCompleted)
  checkNecessaryStepsAsCompleted({patchState}: StateContext<LayoutStateModel>) {
    const steps: Step[] = this.store.selectSnapshot(LayoutState.getSteps);

    if (checkIsAllStepsCompleted(steps)) {
      patchState({
        isNecessaryStepsCompleted: true
      });
    }
  }

  private scrollToStep(stepId: string): void {
    setTimeout(() => {
      const enrollmentSection: HTMLElement = document.getElementById(stepId);

      enrollmentSection?.scrollIntoView({
        behavior: 'smooth'
      });
    }, 100);
  }

  private getSelectedStep(): void {
    const { step } = this.activatedRoute.snapshot.queryParams;

    if (step) {
      this.scrollToStep(EnrollmentSteps[step]);
      this.routerService.clearQueryParams('step');
    }
  }

}
