import { Component } from '@angular/core';

import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { EnrollmentSteps } from 'src/onboarding/shared/enums/enrollment-steps.enum';
import { getFullName } from 'src/onboarding/shared/helpers';
import { GoToStep } from '../../../layout/ngxs/layout.actions';

@Component({
  selector: 'rs-welcome',
  templateUrl: 'welcome.component.html',
  styleUrls: ['welcome.component.scss', 'welcome.component.responsive.scss']
})
export class WelcomeComponent {

  @Select(state => state.layout.userProfile) userProfile$: Observable<any>;

  constructor(
    private store: Store
  ) {}

  public getUserName(userProfile: any): any {
    return {fullName: getFullName(userProfile)};
  }

  public letsStart(): void {
    this.store.dispatch(new GoToStep(EnrollmentSteps.restaurantName));
  }

}
