import { Component, Input } from '@angular/core';
import { BusinessEntityExtended, BusinessInformation } from 'src/onboarding/shared/models/business-entity.model';

@Component({
  selector: 'rs-review-company-information',
  templateUrl: 'review-company-information.component.html',
  styleUrls: ['review-company-information.component.scss']
})
export class ReviewCompanyInformationComponent {

  @Input() set businessEntity({businessInformation}: BusinessEntityExtended) {
    this.businessInformation = businessInformation;
  }

  public businessInformation: BusinessInformation = null;

}
