<div class="rs-bank-account rs-enrollment-section" id="bankAccount">
  <div class="rs-enrollment-section__content">
    <div class="rs-step-title-bold">
      {{ "titles.Now-lets-get-your-money" |translate }}
      <mat-icon>sentiment_satisfied_alt</mat-icon>
    </div>
    <div class="rs-bank-account__form rs-form-control-outline" [formGroup]="bankingInformationForm">
      <div class="rs-row">
        <mat-form-field appearance="outline">
          <mat-label>{{ "placeholders.routing-number" | translate }}</mat-label>
          <input rsNumericControl formControlName="routingNumber" matInput placeholder="{{ 'placeholders.routing-number' | translate }}">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ "placeholders.account-number" | translate }}</mat-label>
          <input rsNumericControl formControlName="accountNumber" matInput placeholder="{{ 'placeholders.account-number' | translate }}">
        </mat-form-field>
      </div>
      <rs-section-footer
        [buttonText]=" 'buttons.save' | translate | uppercase "
        [disabled]="bankingInformationForm.invalid"
        (accepted)="saveBankingInfo()"
      ></rs-section-footer>
    </div>
  </div>
</div>
