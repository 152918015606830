import { Component, EventEmitter, Input, Output } from '@angular/core';

import { CHECKINGS_PROPOSITIONS } from './checkings-propositions.constant';

@Component({
  selector: 'rs-signing-checklist',
  templateUrl: './signing-checklist.component.html',
  styleUrls: ['./signing-checklist.component.scss']
})
export class SigningChecklistComponent {
  @Input() canBeSigned: boolean;
  @Output() changeSigningState = new EventEmitter<boolean>();

  public checkingsPropositions: string[] = CHECKINGS_PROPOSITIONS;

  public onChangeSigningState({checked}) {
    this.changeSigningState.emit(checked);
  }
}
