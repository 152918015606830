import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ENDPOINTS } from 'src/onboarding/shared/constants/endpoints';
import { ApiService } from 'src/onboarding/shared/services/api.service';
import { RestaurantName } from './models/restaurant-name.model';
import { IdResponse } from './models/id-response.model';

@Injectable({
  providedIn: 'root'
})
export class RestaurantNameService {

  constructor(
    private apiService: ApiService
  ) {}

  public createBusinessEntity(restaurantName: RestaurantName): Observable<IdResponse> {
    return this.apiService.post(ENDPOINTS.BUSINESS_ENTITY, restaurantName);
  }

  public updateBusinessEntity(businessEntityId: string, restaurantName: RestaurantName): Observable<void> {
    return this.apiService.put(ENDPOINTS.RESTAURANT_NAME(businessEntityId), restaurantName);
  }

}
