import { Component, Input } from '@angular/core';

import { BankingInformation, BusinessEntityExtended } from 'src/onboarding/shared/models/business-entity.model';

@Component({
  selector: 'rs-review-banking-information',
  templateUrl: 'review-banking-information.component.html',
  styleUrls: ['review-banking-information.component.scss']
})
export class ReviewBankingInformationComponent {

  @Input() set businessEntity({bankingInformation}: BusinessEntityExtended) {
    this.bankingInfo = bankingInformation;
  };

  public bankingInfo: BankingInformation = null;

}
