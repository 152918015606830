

export const MICRO_SERVICES = {
  AUTHENTICATION: 'authentication',
  MERCHANT_PROFILE: 'merchant-profile',
  USER_PROFILE: 'user-profile'
};

export const ENDPOINTS = {
  BUSINESS_ENTITY: `${MICRO_SERVICES.MERCHANT_PROFILE}/v2/business-entity`,
  REFRESH_TOKEN: `${MICRO_SERVICES.AUTHENTICATION}/v1/user/login/refresh`,
  USER_PROFILE_DATA: `${MICRO_SERVICES.USER_PROFILE}/v1/profile/me`,
  RESTAURANT_STATUS: businessEntityId => `${MICRO_SERVICES.MERCHANT_PROFILE}/v2/business-entity/${businessEntityId}/restaurant-status`,
  POS_REPLACEMENT: businessEntityId => `${MICRO_SERVICES.MERCHANT_PROFILE}/v2/business-entity/${businessEntityId}/pos-replacement`,
  WEBSITE: businessEntityId => `${MICRO_SERVICES.MERCHANT_PROFILE}/v2/business-entity/${businessEntityId}/website`,
  COMPANY_INFORMATION: businessEntityId => `${MICRO_SERVICES.MERCHANT_PROFILE}/v2/business-entity/${businessEntityId}/company-information`,
  SERVICE_TYPE: businessEntityId => `${MICRO_SERVICES.MERCHANT_PROFILE}/v2/business-entity/${businessEntityId}/service-model`,
  BANKING_INFORMATION: businessEntityId => `${MICRO_SERVICES.MERCHANT_PROFILE}/v2/business-entity/${businessEntityId}/banking-information`,
  ADDRESS: businessEntityId => `${MICRO_SERVICES.MERCHANT_PROFILE}/v2/business-entity/${businessEntityId}/address`,
  COMPANY_TYPE: businessEntityId => `${MICRO_SERVICES.MERCHANT_PROFILE}/v2/business-entity/${businessEntityId}/company-type`,
  CREATE_BUSINESS_LEADER: businessEntityId => `merchant-profile/v2/business-entity/${businessEntityId}/business-leader`,
  COMPANIES: businessEntityId => `merchant-profile/v2/business-entity/${businessEntityId}/principal/company`,
  INDIVIDUALS: businessEntityId => `merchant-profile/v2/business-entity/${businessEntityId}/principal/individual`,
  SALES_DISTRIBUTION: businessEntityId => `merchant-profile/v2/business-entity/${businessEntityId}/sales-information`,
  PLANS: `${MICRO_SERVICES.MERCHANT_PROFILE}/v2/plan`,
  CHOOSE_PLAN: businessEntityId => `${MICRO_SERVICES.MERCHANT_PROFILE}/v2/business-entity/${businessEntityId}/plan`,
  ADDRESS_AUTOCOMPLETE: 'geo-location/v1/location/autocomplete-address',
  ADDRESS_DETAILS: 'geo-location/v1/location/address',
  RESTAURANT_NAME: businessEntityId =>  `${MICRO_SERVICES.MERCHANT_PROFILE}/v2/business-entity/${businessEntityId}/restaurant-name`,
  VALIDATE: businessEntityId => `${MICRO_SERVICES.MERCHANT_PROFILE}/v2/business-entity/${businessEntityId}/validate`,
  INVITES: businessEntityId => `${MICRO_SERVICES.MERCHANT_PROFILE}/v2/business-entity/${businessEntityId}/invite`,
  CONTROL_PERSON: businessEntityId => `${MICRO_SERVICES.MERCHANT_PROFILE}/v2/business-entity/${businessEntityId}/control-person`,
  START_SIGNING: businessEntityId => `${MICRO_SERVICES.MERCHANT_PROFILE}/v2/business-entity/${businessEntityId}/start-signature`,
  GET_DOCUMENTS: envelopId => `signing/v1/envelope/${envelopId}/render`,
  VIEW_DOCUMENT: envelopId => `signing/v1/envelope/${envelopId}/view`,
  SIGN_DOCUMENTS: businessEntityId => `${MICRO_SERVICES.MERCHANT_PROFILE}/v2/business-entity/${businessEntityId}/sign`
};

export const ENDPOINTS_WITHOUT_ACCESS_TOKEN = [
  '/profile/join',
  '/user/login',
  '/profile/validate',
  'recovery/password',
  'assets'
];
