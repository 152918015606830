<div class="rs-add-owner">
  <div class="rs-add-owner__form">
    <div *ngIf="index">
      <div class="rs-step-title">
        {{ 'owners.list-another' | translate }}
      </div>
      <div class="rs-owners-subtitle">
        {{ 'titles.to-company-with-the-patriot' | translate }}
        <mat-icon>info</mat-icon>
      </div>
    </div>
    <rs-owner-type-selection
      (selectedOwnerType)="changeOwnerType($event)"
      [dropdownValue]="form.value.ownerType"
    ></rs-owner-type-selection>
    <rs-individual
      *ngIf="form.controls.isIndividual"
      [form]="form"
      (close)="closeForm()"
    ></rs-individual>
    <rs-company
      *ngIf="form.controls.isCompany"
      [form]="form"
      (close)="closeForm()"
    ></rs-company>
  </div>
</div>
