import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { NgxsModule } from '@ngxs/store';

import { SharedModule } from 'src/onboarding/shared/modules/shared.module';
import { LayoutComponent } from './components/layout/layout.component';
import { AddressModule } from '../flow/address/address.module';
import { LayoutState } from './ngxs/layout.state';
import { PopupModule } from 'src/onboarding/shared/components/popup/popup.module';
import { LeaveOnboardingComponent } from './components/leave-onboarding/leave-onboarding.component';
import { MaterialModule } from 'src/onboarding/shared/modules/material.module';
import { OwnersInformationModule } from '../flow/owners-information/owners-information.module';
import { WelcomeModule } from '../flow/welcome/welcome.module';
import { RestaurantNameModule } from '../flow/restaurant-name/restaurant-name.module';
import { RestaurantStatusModule } from '../flow/restaurant-status/restaurant-status.module';
import { WebsiteModule } from '../flow/website/website.module';
import { ServiceTypeModule } from '../flow/service-type/service-type.module';
import { RockspoonPlanModule } from '../flow/rockspoon-plan/rockspoon-plan.module';
import { OwnershipStructureModule } from '../flow/ownership-structure/ownership-structure.module';
import { CompanyInformationModule } from '../flow/company-information/company-information.module';
import { BankAccountModule } from '../flow/bank-account/bank-account.module';
import { NavigationModule } from 'src/onboarding/shared/components/navigation/navigation.module';
import { SalesDistributionModule } from '../flow/sales-distribution/sales-distribution.module';
import { ReviewModule } from '../flow/review/review.module';
import { TermsAndAgreementModule } from '../flow/terms-and-agreement/terms-and-agreement.module';
import { DocumentSigningModule } from '../flow/document-signing/document-signing.module';

@NgModule({
  imports: [
    SharedModule,
    MaterialModule,
    NgxsModule.forFeature([LayoutState]),
    NavigationModule,
    PopupModule,
    WelcomeModule,
    RestaurantNameModule,
    RestaurantStatusModule,
    WebsiteModule,
    AddressModule,
    ServiceTypeModule,
    RockspoonPlanModule,
    OwnershipStructureModule,
    CompanyInformationModule,
    OwnersInformationModule,
    BankAccountModule,
    TermsAndAgreementModule,
    SalesDistributionModule,
    RouterModule,
    ReviewModule,
    DocumentSigningModule
  ],
  declarations: [
    LayoutComponent,
    LeaveOnboardingComponent
  ],
  exports: [
    LayoutComponent
  ]
})
export class LayoutModule {}
