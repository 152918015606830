<div class="rs-review-restaurant-status" *ngIf="restaurantStatus">
  <div class="rs-review-restaurant-status__title rs-review-step">{{'review.restaurant-status' | translate}}</div>
  <div class="rs-review-restaurant-status__content" *ngIf="restaurantStatus">
    <div *ngIf="isOperational()">
      <div class="rs-review-restaurant-status__icon">
        <img src="assets/img-new/operational.png">
      </div>
      <div class="rs-review-restaurant-status__value rs-review-restaurant-status__value_operational">{{'review.operational' | translate}}</div>
    </div>
    <div *ngIf="isPreOperational()">
      <div class="rs-review-restaurant-status__icon">
        <img src="assets/img-new/pre-operational.png">
      </div>
      <div class="rs-review-restaurant-status__value rs-review-restaurant-status__value_pre-operational">{{'review.pre-operational' | translate}}</div>
    </div>
    <div *ngIf="isTemporaryClosed()">
      <div class="rs-review-restaurant-status__icon">
        <img src="assets/img-new/heart-broken.png">
      </div>
      <div class="rs-review-restaurant-status__value rs-review-restaurant-status__value_temporary-closed">{{'review.temporary-closed' | translate}}</div>
    </div>
  </div>
</div>
