import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ENDPOINTS } from 'src/onboarding/shared/constants/endpoints';
import { ApiService } from 'src/onboarding/shared/services/api.service';
import { CompanyInformation } from './models/company-information.model';

@Injectable({
  providedIn: 'root'
})
export class CompanyInformationService {

  constructor(
    private apiService: ApiService
  ) {}

  public setCompanyInformation(companyInformation: CompanyInformation, businessEntityId: string): Observable<any> {
    return this.apiService.put(ENDPOINTS.COMPANY_INFORMATION(businessEntityId), companyInformation);
  }

}
