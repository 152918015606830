import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'rs-control-with-mask',
  templateUrl: 'control-with-mask.component.html',
  styleUrls: ['control-with-mask.component.scss']
})
export class ControlWithMaskComponent {

  @Input() placeholder: string;
  @Input() rsFormControl: FormControl;
  @Input() mask: string;

  public isMaskVisible: boolean;

  public onFocus(): void {
    this.isMaskVisible = true;
  }

}
