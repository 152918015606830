import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ENDPOINTS } from 'src/onboarding/shared/constants/endpoints';
import { ApiService } from 'src/onboarding/shared/services/api.service';
import { RockspoonPlan } from './models/rockspoon-plan.model';

@Injectable({
  providedIn: 'root'
})
export class RockspoonPlanService {

  constructor(
    private apiService: ApiService
  ) {}

  public getPaymentPlans(): Observable<RockspoonPlan[]> {
    return this.apiService.get(ENDPOINTS.PLANS);
  }

  public choosePlan(businessEntityId: string, planId: string): Observable<void> {
    return this.apiService.put(ENDPOINTS.CHOOSE_PLAN(businessEntityId), {planId});
  }

}
