import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { delay, switchMap, tap } from 'rxjs/operators';

import { Spinner } from 'src/onboarding/shared/classes/spinner.class';
import { DocumentSigningService } from '../document-signing.service';
import {
  GetDocuments,
  InitSigning,
  SetCurrentDocumentId,
  SignDocuments,
  StartDocumentSignature,
  ViewDocument
} from './document-signing.actions';
import { StartSignature } from '../models/start-signature.model';
import { Document } from '../models/document.model';
import { DocumentShowState } from '../models/document-show-state.enum';
import { LayoutState } from '../../../layout/ngxs/layout.state';
import { GoToStep, SetStepAsCompleted } from '../../../layout/ngxs/layout.actions';
import { EnrollmentSteps } from 'src/onboarding/shared/enums/enrollment-steps.enum';
import { copy } from 'src/onboarding/shared/helpers';
import { environment } from 'src/environments/environment';
import { BackEndTemplateProcessor } from 'src/onboarding/shared/classes/BackEndTemplateProcessor.service';

interface DocumentSigningStateModel {
  envelopeId: string;
  activeDocumentId: string;
  documentShowState: DocumentShowState;
  documentsForView: Document[];
}

@State<DocumentSigningStateModel>({
  name: 'documentSigning',
  defaults: {
    envelopeId: null,
    activeDocumentId: null,
    documentShowState: DocumentShowState.UNSET,
    documentsForView: []
  }
})
@Injectable()
export class DocumentSigningState extends Spinner {
  constructor(
    protected readonly store: Store,
    private readonly documentSigningService: DocumentSigningService,
    private backEndTemplateProcessor: BackEndTemplateProcessor
  ) {
    super(store);
  }

  @Selector()
  static isAllDocumentsAreRead(state: DocumentSigningStateModel): boolean {
    return state.documentsForView.length ? state.documentsForView.every((document: Document) => document.viewed) : false;
  }

  @Selector()
  static currentDocument(state: DocumentSigningStateModel): Document {
    return state.documentsForView.find((documentForView: Document) => documentForView.id === state.activeDocumentId);
  }

  @Selector()
  static isLastDocument(state: DocumentSigningStateModel): boolean {
    const currentDocumentIndex = state.documentsForView.findIndex((documentForView: Document) => documentForView.id === state.activeDocumentId);

    return currentDocumentIndex === state.documentsForView.length - 1;
  }

  @Selector()
  static isFirstDocument(state: DocumentSigningStateModel): boolean {
    const currentDocumentIndex = state.documentsForView.findIndex((documentForView: Document) => documentForView.id === state.activeDocumentId);

    return currentDocumentIndex === 0;
  }

  @Action(InitSigning)
  initSigning() {
    this.showSpinner();

    return this.store.dispatch(new StartDocumentSignature())
      .pipe(
        switchMap(() => this.store.dispatch(new GetDocuments()))
      );
  }

  @Action(StartDocumentSignature)
  startDocumentSignature({ patchState }: StateContext<DocumentSigningStateModel>) {
    const businessEntityId = this.store.selectSnapshot(LayoutState.getBusinessEntityId);

    return this.documentSigningService.startSignatureDocuments(businessEntityId)
    .pipe(
      tap({
        next: ({ envelopeId }: StartSignature) => {
          patchState({ envelopeId });
        }
      })
    );
  }

  @Action(GetDocuments)
  getDocuments({ patchState, getState }: StateContext<DocumentSigningStateModel>) {
    const { envelopeId } = getState();

    this.showSpinner();

    return this.documentSigningService.getDocuments(envelopeId).pipe(
      delay(2000),
      tap({
        next: (documentsForView: Document[]) => {
          const documents = documentsForView
            .map((item, index) => ({
              ...item,
              htmlContent: this.backEndTemplateProcessor.process(item.htmlContent)
            }));
          patchState({
            documentsForView: documents,
            activeDocumentId: documents[0].id,
          });
        },
        complete: () => {
          this.hideSpinner();
          this.store.dispatch(new GoToStep(EnrollmentSteps.contracts));
        }
      })
    );
  }

  @Action(SetCurrentDocumentId)
  setCurrentDocumentId({ patchState }: StateContext<DocumentSigningStateModel>, { currentDocumentId }: SetCurrentDocumentId) {
    patchState({ activeDocumentId: currentDocumentId });
  }

  @Action(ViewDocument)
  viewDocument({ getState, patchState }: StateContext<DocumentSigningStateModel>, { documentId }: ViewDocument) {
    const { envelopeId } = getState();
    const documentsForView = copy(getState().documentsForView) as Document[];
    const documentIndex = documentsForView.findIndex(document => document.id === documentId);

    if (!documentsForView[documentIndex].viewed) {
      this.showSpinner();

      return this.documentSigningService.viewDocument(envelopeId, documentId)
        .pipe(
          tap({
            next: () => {
  
              documentsForView[documentIndex].viewed = true;
              patchState({
                documentsForView
              });
            },
            complete: () => this.hideSpinner()
          })
        );
    }
  }

  @Action(SignDocuments)
  signDocuments() {
    const businessEntityId = this.store.selectSnapshot(LayoutState.getBusinessEntityId);
    this.showSpinner();

    return this.documentSigningService.signDocuments(businessEntityId)
      .pipe(
        tap({
          complete: () => {
            this.hideSpinner();
            this.store.dispatch(new SetStepAsCompleted(EnrollmentSteps.contracts));
            location.replace(`${environment.endOfTheFlowUrl}${businessEntityId}`);
          }
        })
      );
  }

}
