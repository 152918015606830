import { Injectable } from '@angular/core';

import { Action, State, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { Spinner } from 'src/onboarding/shared/classes/spinner.class';
import { EnrollmentSteps } from 'src/onboarding/shared/enums/enrollment-steps.enum';
import { GoToStep, RefreshApplication, SetStepAsCompleted } from '../../../layout/ngxs/layout.actions';
import { LayoutState } from '../../../layout/ngxs/layout.state';
import { CompanyInformationService } from '../company-information.service';
import { CompanyInformation } from '../models/company-information.model';
import { InitCompanyInformation, SetCompanyInformation } from './company-information.actions';

interface CompanyInformationStateModel {
  companyInformation: CompanyInformation;
}

@State<CompanyInformationStateModel>({
  name: 'companyInformation',
  defaults: {
    companyInformation: null
  }
})
@Injectable()
export class CompanyInformationState extends Spinner {

  constructor(
    private companyInformationService: CompanyInformationService,
    protected store: Store
  ) {
    super(store);
  }

  @Action(InitCompanyInformation)
  initCompanyInformation({patchState}: StateContext<CompanyInformationStateModel>, {businessEntity}: InitCompanyInformation) {
    patchState({
      companyInformation: {
        ein: businessEntity.businessInformation.ein,
        legalEntityName: businessEntity.businessInformation.legalEntityName
      }
    })
  }

  @Action(SetCompanyInformation)
  setCompanyInformation({patchState}: StateContext<CompanyInformationStateModel>, {companyInformation}: SetCompanyInformation) {
    const { legalEntityName, ein, individual_ein, individual_ssn } = companyInformation;

    const companyInformationForRequest = {
      legalEntityName,
      ein: individual_ein || individual_ssn || ein
    };

    this.showSpinner();
    const id = this.store.selectSnapshot(LayoutState.getBusinessEntityId);

    return this.companyInformationService.setCompanyInformation(companyInformationForRequest, id)
      .pipe(
        tap({
          next: () => {
            patchState({ companyInformation });
          },
          complete: () => {
            this.hideSpinner();
            this.store.dispatch(new GoToStep(EnrollmentSteps.ownersInformation));
            this.store.dispatch(new SetStepAsCompleted(EnrollmentSteps.companyInformation));
            this.store.dispatch(new RefreshApplication());
          }
        })
      );

  }

}
