import { Component, Input, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { differentAddresses, getUSstateValue } from 'src/onboarding/shared/helpers';
import { BusinessEntityExtended } from 'src/onboarding/shared/models/business-entity.model';

@Component({
  selector: 'rs-review-address',
  templateUrl: 'review-address.component.html',
  styleUrls: ['review-address.component.scss']
})
export class ReviewAddressComponent implements OnChanges {

  @Input() businessEntity: BusinessEntityExtended;

  public businessAddressForm: FormGroup = null;
  public legalAddressForm: FormGroup = null;
  public different: boolean = false;
  public businessState: string = null;
  public legalState: string = null;
  public isFormInit: boolean = false;

  constructor(
    private formBuilder: FormBuilder
  ) {}

  public ngOnChanges(): void {
    this.different = differentAddresses(this.businessEntity.businessInformation.businessAddress, this.businessEntity.businessInformation.legalAddress);

    this.initForms();
  }

  private initForms(): void {
    const {businessAddress, legalAddress} = this.businessEntity.businessInformation;

    this.legalAddressForm = this.formBuilder.group({
      address1: [legalAddress ? legalAddress.address1 : ''],
      address2: [legalAddress ? legalAddress.address2 : ''],
      city: [legalAddress ? legalAddress.city : ''],
      state: [legalAddress ? legalAddress.state.split('-')[1] : ''],
      zipcode: [legalAddress ? legalAddress.zipcode : '']
    });
    this.businessAddressForm = this.formBuilder.group({
      address1: [businessAddress ? businessAddress.address1 : ''],
      address2: [businessAddress ? businessAddress.address2 : ''],
      city: [businessAddress ? businessAddress.city : ''],
      state: [businessAddress ? businessAddress.state.split('-')[1] : ''],
      zipcode: [businessAddress ? businessAddress.zipcode : '']
    });

    if (businessAddress) {
      this.businessState = getUSstateValue(businessAddress.state);
      this.legalState = getUSstateValue(businessAddress.state);
    }

    this.isFormInit = true;
  }

  public checkIsDifferentAndLegalState(): boolean {
    return this.different && !!this.legalState;
  }


}
