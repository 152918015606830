import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'rs-reset-password',
  template: ''
})
export class ResetPasswordComponent implements OnInit {

  constructor(
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    const { token } = this.route.snapshot.queryParams;

    location.replace(`${environment.authenticationPortalUrl}/resetPassword?token=${token}`);
  }

}
