import { NgModule } from '@angular/core';

import { NgxsModule } from '@ngxs/store';
import { AddressFormModule } from 'src/onboarding/shared/components/address-form/address-form.module';

import { ControlWithMaskModule } from 'src/onboarding/shared/components/control-with-mask/control-with-mask.module';
import { IndividualFormModule } from 'src/onboarding/shared/components/individual-form/individual-form.module';
import { PercentControlModule } from 'src/onboarding/shared/components/percent-control/percent-control.module';
import { PhoneControlModule } from 'src/onboarding/shared/components/phone-control/phone-control.module';
import { ConfirmationModule } from 'src/onboarding/shared/components/popup/confirmation/confirmation.module';
import { SectionFooterModule } from 'src/onboarding/shared/components/section-footer/section-footer.module';
import { DirectivesModule } from 'src/onboarding/shared/directives/directives.module';
import { MaterialModule } from 'src/onboarding/shared/modules/material.module';
import { SharedModule } from 'src/onboarding/shared/modules/shared.module';
import { PipesModule } from 'src/onboarding/shared/pipes/pipes.module';
import { AddOwnerComponent } from './component/add-owner/add-owner.component';
import { CompanyComponent } from './component/company/company.component';
import { IndividualComponent } from './component/individual/individual.component';
import { OwnerTypeSelection } from './component/owner-type-selection/owner-type-selection.component';
import { OwnerComponent } from './component/owner/owner.component';
import { OwnersInformationComponent } from './component/owners-information/owners-information.component';
import { OwnersInformationState } from './ngxs/owners-information.state';

@NgModule({
  imports: [
    SharedModule,
    MaterialModule,
    SectionFooterModule,
    PhoneControlModule,
    PercentControlModule,
    NgxsModule.forFeature([OwnersInformationState]),
    PipesModule,
    ControlWithMaskModule,
    DirectivesModule,
    AddressFormModule,
    ConfirmationModule,
    IndividualFormModule
  ],
  declarations: [
    OwnersInformationComponent,
    IndividualComponent,
    CompanyComponent,
    OwnerTypeSelection,
    AddOwnerComponent,
    OwnerComponent
  ],
  exports: [
    OwnersInformationComponent
  ]
})
export class OwnersInformationModule {}
