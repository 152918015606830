import { Component, Input } from '@angular/core';

import { OWNERSHIP_STRUCTURES } from 'src/onboarding/shared/constants/common';
import { copy } from 'src/onboarding/shared/helpers';
import { BusinessEntityExtended } from 'src/onboarding/shared/models/business-entity.model';
import { ListItem } from 'src/onboarding/shared/models/list-item.model';

@Component({
  selector: 'rs-review-ownership-structure',
  templateUrl: 'review-ownership-structure.component.html',
  styleUrls: ['review-ownership-structure.component.scss']
})
export class ReviewOwnershipStructureComponent {

  @Input() set businessEntity({companyType}: BusinessEntityExtended) {
    companyType ?
      this.selectedOwnershipStructure = this.ownershipStructures.find(structure =>  structure.value === companyType).title : null;
  };

  public ownershipStructures: ListItem[] = copy(OWNERSHIP_STRUCTURES);
  public selectedOwnershipStructure: string = null;

}
