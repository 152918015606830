import { Injectable } from '@angular/core';

import { Action, State, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { Spinner } from 'src/onboarding/shared/classes/spinner.class';
import { EnrollmentSteps } from 'src/onboarding/shared/enums/enrollment-steps.enum';
import { GoToStep, RefreshApplication, SetStepAsCompleted } from '../../../layout/ngxs/layout.actions';
import { LayoutState } from '../../../layout/ngxs/layout.state';
import { RockspoonPlan } from '../models/rockspoon-plan.model';
import { RockspoonPlanService } from '../rockspoon-plan.service';
import { GetPaymentPlans, InitPaymentPlan, SelectPlan } from './rockspoon-plan.actions';

interface RockspoonPlanStateModel {
  plans: RockspoonPlan[];
  selectedPlanId: string;
}

@State<RockspoonPlanStateModel>({
  name: 'rockspoonPlan',
  defaults: {
    plans: [],
    selectedPlanId: null
  }
})
@Injectable()
export class RockspoonPlanState extends Spinner {

  constructor(
    protected store: Store,
    private rockspoonPlanService: RockspoonPlanService
  ) {
    super(store);
  }

  @Action(InitPaymentPlan)
  initPaymentPlan({patchState}: StateContext<RockspoonPlanStateModel>, {businessEntity}: InitPaymentPlan) {
    patchState({
      selectedPlanId: businessEntity.planId
    });
  }

  @Action(GetPaymentPlans)
  getPaymentPlans({patchState}: StateContext<RockspoonPlanStateModel>) {
    return this.rockspoonPlanService.getPaymentPlans()
      .pipe(
        tap({
          next: (paymentPlans: any[]) => {
            patchState({
              plans: paymentPlans
            });
          }
        })
      );
  }

  @Action(SelectPlan)
  setPaymentPlans({patchState}: StateContext<RockspoonPlanStateModel>, {rockspoonPlanId}: SelectPlan){
    this.showSpinner()

    return this.rockspoonPlanService.choosePlan(
      this.store.selectSnapshot(LayoutState.getBusinessEntityId),
      rockspoonPlanId
    )
      .pipe(
        tap({
          next: () => {
            patchState({
              selectedPlanId: rockspoonPlanId
            });
          },
          complete: () => {
            this.hideSpinner();
            this.store.dispatch(new SetStepAsCompleted(EnrollmentSteps.rockspoonPlan));
            this.store.dispatch(new GoToStep(EnrollmentSteps.ownershipStructure));
            this.store.dispatch(new RefreshApplication());
          }
        })
      );
  }

}
