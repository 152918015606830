import { NgModule } from '@angular/core';

import { NgxsModule } from '@ngxs/store';

import { AddressFormModule } from 'src/onboarding/shared/components/address-form/address-form.module';
import { IconButtonModule } from 'src/onboarding/shared/components/icon-button/icon-button.module';
import { NavigationModule } from 'src/onboarding/shared/components/navigation/navigation.module';
import { PlanItemModule } from 'src/onboarding/shared/components/plan-item/plan-item.module';
import { SectionFooterModule } from 'src/onboarding/shared/components/section-footer/section-footer.module';
import { MaterialModule } from 'src/onboarding/shared/modules/material.module';
import { SharedModule } from 'src/onboarding/shared/modules/shared.module';
import { PipesModule } from 'src/onboarding/shared/pipes/pipes.module';
import { ReviewAddressComponent } from './components/address/review-address.component';
import { ReviewBankingInformationComponent } from './components/banking-information/review-banking-information.component';
import { ReviewCompanyInformationComponent } from './components/company-information/review-company-information.component';
import { ReviewOwnersInformationComponent } from './components/owners-information/review-owners-information.component';
import { ReviewOwnershipStructureComponent } from './components/ownership-structure/review-ownership-structure.component';
import { ReviewRestaurantNameComponent } from './components/restaurant-name/review-restaurant-name.component';
import { ReviewRestaurantStatusComponent } from './components/restaurant-status/review-restaurant-status.component';
import { ReviewSalesDistribution } from './components/review-sales-distribution/review-sales-distribution.component';
import { ReviewComponent } from './components/review/review.component';
import { ReviewRockspoonPlanComponent } from './components/rockspoon-plan/review-rockspoon-plan.component';
import { ReviewServiceTypeComponent } from './components/service-type/review-service-type.component';
import { ReviewWebsiteComponent } from './components/website/review-website.component';
import { ReviewState } from './ngxs/review.state';

@NgModule({
  imports: [
    SharedModule,
    MaterialModule,
    NavigationModule,
    AddressFormModule,
    PlanItemModule,
    PipesModule,
    SectionFooterModule,
    IconButtonModule,
    NgxsModule.forFeature([ReviewState])
  ],
  declarations: [
    ReviewComponent,
    ReviewRestaurantNameComponent,
    ReviewRestaurantStatusComponent,
    ReviewAddressComponent,
    ReviewRockspoonPlanComponent,
    ReviewServiceTypeComponent,
    ReviewWebsiteComponent,
    ReviewOwnershipStructureComponent,
    ReviewCompanyInformationComponent,
    ReviewOwnersInformationComponent,
    ReviewBankingInformationComponent,
    ReviewSalesDistribution
  ],
  exports: [
    ReviewComponent
  ]
})
export class ReviewModule {}
