import { NgModule } from '@angular/core';

import { SharedModule } from 'src/onboarding/shared/modules/shared.module';
import { ResetPasswordComponent } from './components/reset-password.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    ResetPasswordComponent
  ]
})
export class AuthModule {}
