import { Component, Input } from '@angular/core';

import { SERVICE_TYPES } from 'src/onboarding/shared/constants/common';
import { BusinessEntityExtended } from 'src/onboarding/shared/models/business-entity.model';

@Component({
  selector: 'rs-review-service-type',
  templateUrl: 'review-service-type.component.html',
  styleUrls: ['review-service-type.component.scss']
})
export class ReviewServiceTypeComponent {

  @Input() set businessEntity({serviceModel}: BusinessEntityExtended) {
    this.serviceType = SERVICE_TYPES[serviceModel];
  };

  public serviceType: string = null;

}
