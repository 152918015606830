<div class="rs-company" *ngIf="form">
  <div class="rs-form-control-outline" [formGroup]="form">
    <div class="rs-company__full-width">
      <mat-form-field appearance="outline">
        <mat-label>{{'placeholders.legal-entity-name' | translate}}</mat-label>
        <input formControlName="businessName" type="text" matInput placeholder="{{'placeholders.legal-entity-name' | translate}}">
        <mat-error *ngIf="form.get('businessName').hasError('required')">
          {{ "placeholders.required" | translate }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="rs-row">
      <rs-control-with-mask [rsFormControl]="form.controls.tin" [placeholder]="'placeholders.tin'" mask="00-0000000"></rs-control-with-mask>
      <rs-percent-control [cantBeZero]="true" [rsFormControl]="form.controls.share" placeholder="{{'placeholders.share' | translate}}"></rs-percent-control>
    </div>
    <div class="rs-row">
      <div class="rs-company__phone-control">
        <rs-phone-control [rsPhoneControl]="form.controls.phone"></rs-phone-control>
      </div>
      <mat-form-field appearance="outline">
        <mat-label>{{'placeholders.email' | translate}}*</mat-label>
        <input formControlName="email" type="text" matInput placeholder="Email Address*">
        <mat-error *ngIf="form.get('email').hasError('required')">
          {{ "placeholders.required" | translate }}
        </mat-error>
        <mat-error *ngIf="form.get('email').hasError('pattern')">
          {{ "placeholders.wrong-email" | translate }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="rs-step-title-bold-small">{{'address.legal' | translate}}</div>
    <rs-address-form [inputForm]="form" [withCountry]="true"></rs-address-form>
  </div>
  <div
    *ngIf="!isEdit"
    class="rs-single-cancel-btn"
  >
    <button
      rsConfirmation
      (accepted)="closeForm()"
      mat-raised-button
      text="{{ 'popups.are-you-sure' | translate }}"
    >
      {{ 'buttons.delete' | translate | uppercase }}
    </button>
  </div>
  <rs-section-footer
    *ngIf="isEdit"
    cancelButtonText="{{ 'buttons.cancel' | translate }}"
    buttonText="{{ 'buttons.save' | translate }}"
    (accepted)="saveEditForm()"
    (canceled)="closeEditForm()"
    [disabled]="form.invalid"
  ></rs-section-footer>
</div>
