import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { MatRadioChange } from '@angular/material/radio';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { EnrollmentPage } from 'src/onboarding/shared/classes/enrollment-page.class';
import { OWNERSHIP_STRUCTURES } from 'src/onboarding/shared/constants/common';
import { ENROLLMENT_SECTIONS } from 'src/onboarding/shared/constants/enrollment-sections.constants';
import { EnrollmentSteps } from 'src/onboarding/shared/enums/enrollment-steps.enum';
import { GoToStep } from '../../../layout/ngxs/layout.actions';
import { CompanyInformation } from '../models/company-information.model';
import { SetCompanyInformation } from '../ngxs/company-information.actions';

@Component({
  selector: 'rs-company-information',
  templateUrl: 'company-information.component.html',
  styleUrls: ['company-information.component.scss']
})
export class CompanyInformationComponent extends EnrollmentPage implements OnInit {

  @Select(state => state.companyInformation.companyInformation) companyInformation$: Observable<CompanyInformation>;
  @Select(state => state.ownershipStructure.ownershipStructure) ownershipStructure$: Observable<string>;

  public companyInformationForm: FormGroup;
  public isIndividual: boolean;
  public isFormValid: boolean;
  public isCreationStarted: boolean = false;

  constructor(
    protected store: Store,
    private formBuilder: FormBuilder
  ) {
    super(ENROLLMENT_SECTIONS.COMPANY_INFORMATION, store);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.initSubscriptions();
  }

  public goToNext(): void {
    let valueForRequest: CompanyInformation = null;
    const { legalEntityName, ein, individual_ein, individual_ssn } = this.companyInformationForm.getRawValue();
    !this.isIndividual ?
      valueForRequest = { legalEntityName, ein } :
      valueForRequest = { legalEntityName, individual_ein, individual_ssn };

    this.store.dispatch(new SetCompanyInformation(valueForRequest));
    this.isCreationStarted = true;
  }

  public initForm(companyInformation: CompanyInformation): void {
    this.companyInformationForm = this.formBuilder.group({
      legalEntityName: [companyInformation ? companyInformation.legalEntityName : null, Validators.required],
      ein: [companyInformation ? companyInformation.ein : null, Validators.required],
      individual_ein: {value: companyInformation ? (companyInformation.individual_ein || companyInformation.ein) : null, disabled: true},
      individual_ssn: {value: companyInformation ? (companyInformation.individual_ssn || companyInformation.ein) : null, disabled: true}
    });
  }

  private initSubscriptions(): void {
    this.subscribeTo = this.companyInformation$
      .subscribe((companyInformation: CompanyInformation) => {
        if (!this.isCreationStarted) {
          this.initForm(companyInformation);
          this.isFormValid = this.companyInformationForm.valid;
        }

        this.subscribeToForm();
      });
    this.subscribeTo = this.ownershipStructure$
      .subscribe(ownershipStructure => this.isIndividual = ownershipStructure === OWNERSHIP_STRUCTURES[0].value);
  }

  public subscribeToForm(): void {
    this.subscribeTo = this.companyInformationForm.valueChanges
      .subscribe(form => {
        if (this.isIndividual) {
          const { legalEntityName, individual_ein, individual_ssn } = form;
          this.isFormValid = legalEntityName && (individual_ein || individual_ssn);
        } else {
          this.isFormValid = this.companyInformationForm.valid;
        }
      });
  }

  public reactOnRadioBtnChange(event: MatRadioChange): void {
    if (event.value === 'individual_ein') {
      this.companyInformationForm.patchValue({individual_ssn: null});
      this.companyInformationForm.controls['individual_ein'].enable();
      this.companyInformationForm.controls['individual_ssn'].disable();
    } else if (event.value === 'individual_ssn'){
      this.companyInformationForm.patchValue({individual_ein: null});
      this.companyInformationForm.controls['individual_ssn'].enable();
      this.companyInformationForm.controls['individual_ein'].disable();
    }
  }

}
