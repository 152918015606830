export const CACHE = {
  STEPS: 'steps',
  RESTAURANT_STATUS: 'restaurantStatus',
  DO_YOU_HAVE_WEBSITE: 'doYouHaveWebsite',
  WEBSITE_URL: 'websiteUrl',
  LEGAL_ADDRESS: 'legalAddress',
  BUSINESS_ADDRESS: 'businessAddress',
  BUSINESS_TYPE: 'businessType',
  SERVICE_TYPE: 'serviceType',
  USER_ALLOWED_TO_SIGN_DOCUMENT: 'userAllowedToSignDocument',
  REQUESTED_SIGNATURE_OWNER: 'requestedSignatureOwner',
  IS_SIGNATURE_REQUEST_RESENT: 'isSignatureRequestResent',
  COMPANIES: 'companies',
  INDIVIDUALS: 'individuals',
  ROCKSPOON_PLAN: 'rockspoonPlan',
  BANKING_INFO: 'bankingInfo',
  ASSISTANCE_SUPPORT: 'assistanceSupport',
  RESTAURANT_NAME: 'restaurantName',
  POS_SYSTEM: 'posSystem',
  OWNERSHIP_STRUCTURE: 'ownershipStructure',
  COMPANY_INFORMATION: 'companyInformation',
  PAYMENT_DETAILS: 'paymentDetails',
  BILLING_ADDRESS: 'billingAddress',
  ACCESS_TOKEN: 'accessToken',
  REFRESH_TOKEN: 'refreshToken',
  BUSINESS_ENTITY_ID: 'businessEntityId',
  USER_PROFILE: 'userProfile',
  SIGN_URL: 'signUrl',
  VIEW_URL: 'viewUrl',
  SIGNED: 'signed',
  SALES_DISTRIBUTION: 'salesDistribution'
};
