<div class="rs-restaurant-status rs-enrollment-section" id="restaurantStatus">
  <div class="rs-enrollment-section__content" *ngIf="!posSystemVisibility">
    <div class="rs-restaurant-status__title rs-step-title">{{'enrollment-step-titles.restaurant-status' | translate}}</div>
    <div class="rs-restaurant-status__items">
      <div class="rs-restaurant-status__item" *ngFor="let restaurantStatus of restaurantStatuses">
        <rs-restaurant-status-item (click)="reactOnSelectRestaurantStatus(restaurantStatus.value)" [selected]="isStatusSelected(restaurantStatus.value)" [icon]="restaurantStatus.icon" [title]="restaurantStatus.title"></rs-restaurant-status-item>
      </div>
    </div>
  </div>
  <div class="rs-enrollment-section__content" *ngIf="posSystemVisibility">
    <rs-radio-list
      caption="{{'enrollment-step-titles.which-pos' | translate}}"
      [items]="posSystems"
      acceptButtonText="{{'buttons.save' | translate}}"
      cancelButtonText="{{'buttons.cancel' | translate}}"
      [radioControl]="posSystemControl"
      [value]="posSystemValue"
      (accepted)="reactOnPosSystemAccepted($event)"
      (canceled)="reactOnPosSystemCanceled($event)"
      isShowOtherChoice="true"
    ></rs-radio-list>
  </div>
</div>
