<div class="rs-accept-input rs-form-control-outline">
  <mat-form-field appearance="outline">
    <mat-label>{{placeholder}}</mat-label>
    <input [formControl]="rsFormControl" matInput [placeholder]="placeholder">
    <button
      [disabled]="isAcceptButtonDisabled()"
      mat-button
      matSuffix 
      mat-icon-button
      (click)="reactOnAccept()"
    >
      <mat-icon>check</mat-icon>
    </button>
  </mat-form-field>
</div>
