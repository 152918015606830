<div class="rs-owner-type-selection">
  <div class="rs-row">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'placeholders.owner-type' | translate }}</mat-label>
      <mat-select (selectionChange)="selectOwnerType($event.value)" [(ngModel)]="dropdownValue">
        <mat-option *ngFor="let ownerType of ownerTypes" [value]="ownerType">{{ ownerType }}</mat-option>
      </mat-select>
    </mat-form-field>
    <div></div>
  </div>
</div>
