<div class="rs-sales-distribution rs-enrollment-section" id="salesDistribution">
  <div class="rs-enrollment-section__content">
    <div class="rs-step-title">{{'enrollment-step-titles.sales-distribution' | translate}}</div>
    <div class="rs-sales-distribution__subtitle">{{'sales-distribution.subtitle' | translate}}</div>
    <div class="rs-sales-distribution__controls" [formGroup]="salesDistributionForm">
      <div class="rs-row">
        <rs-percent-control
          [rsFormControl]="salesDistributionForm.controls.cardPresentTransactions"
          placeholder="{{ 'placeholders.card-percentage' | translate }}">
        </rs-percent-control>
      </div>
      <div class="rs-row">
        <rs-percent-control
          [rsFormControl]="salesDistributionForm.controls.internetTransactions"
          placeholder="{{ 'placeholders.internet-percentage' | translate }}">
        </rs-percent-control>
      </div>
      <div class="rs-row">
        <rs-percent-control
          [rsFormControl]="salesDistributionForm.controls.mailPhoneTransactions"
          placeholder="{{ 'placeholders.phone-percentage' | translate }}">
        </rs-percent-control>
      </div>
    </div>
    <div class="rs-sales-distribution__footer">
      <rs-section-footer
        [disabled]="isSaveDisabled()"
        (accepted)="save()"
        buttonText="{{'buttons.save' | translate}}"
      ></rs-section-footer>
    </div>
  </div>
</div>
