import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { MatCheckboxChange } from '@angular/material/checkbox';
import { Store } from '@ngxs/store';

import { LayoutState } from 'src/onboarding/modules/enrollment/layout/ngxs/layout.state';

@Component({
  selector: 'rs-individual',
  templateUrl: 'individual.component.html',
  styleUrls: ['individual.component.scss']
})
export class IndividualComponent {

  @Input() form: FormGroup;
  @Input() isEdit: boolean;
  @Output() close = new EventEmitter<void>();
  @Output() closeEdit = new EventEmitter<void>();
  @Output() saveEdit = new EventEmitter<void>();

  constructor(
    private store: Store
  ) {}

  public reactOnChangeIAmOwner(checkboxChange: MatCheckboxChange): void {
    const { firstName, lastName, email } = this.form.controls;
    const userProfile = this.store.selectSnapshot(LayoutState.getUserProfile);

    if (checkboxChange.checked) {
      firstName.reset(userProfile.firstName);
      lastName.reset(userProfile.lastName);
      email.reset(userProfile.email);
    } else {
      firstName.reset('');
      lastName.reset('');
      email.reset('');
    }
  }

  public closeForm(): void {
    this.close.emit();
  }

  public saveEditForm(): void {
    this.saveEdit.emit();
  }

  public closeEditForm(): void {
    this.closeEdit.emit();
  }

}
