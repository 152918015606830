import { NgModule } from '@angular/core';
import { DatePickerValidationCheckerDirective } from './date-picker-handler.directive';

import { NumericControlDirective } from './numeric-control.directive';
import { OpenLeaveOnboardingDirective } from './open-leave-onboarding.directive';

@NgModule({
  declarations: [
    NumericControlDirective,
    OpenLeaveOnboardingDirective,
    DatePickerValidationCheckerDirective
  ],
  exports: [
    NumericControlDirective,
    OpenLeaveOnboardingDirective,
    DatePickerValidationCheckerDirective
  ]
})
export class DirectivesModule {}
