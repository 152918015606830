<div class="rs-signing-checklist w-100">
  <mat-checkbox (change)="onChangeSigningState($event)" [disabled]="!canBeSigned">
    <p class="rs-checkbox-label mb-1" [ngClass]="{ 'rs-disabled' : !canBeSigned }">{{ 'terms-and-agreement.sign-agreement-checkbox' | translate }}</p>
    <p class="rs-checkbox-hint">{{ 'terms-and-agreement.sign-agreement-checkbox-hint' | translate }}</p>
  </mat-checkbox>

  <p class="d-flex mb-0" *ngFor="let proposition of checkingsPropositions">
    <span class="rs-checklist-mark d-flex justify-content-center align-items-center">
      <mat-icon>done</mat-icon>
    </span>
    <span class="rs-checklist-text">{{ proposition | translate }}</span>
  </p>
</div>
