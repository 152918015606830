import { HttpClient } from '@angular/common/http';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { environment } from 'src/environments/environment';
import countries from '../../assets/files/countries.json';
import { Address } from '../modules/enrollment/flow/address/models/address.model';
import { ERRORS } from './constants/errors';
import { EnrollmentSteps } from './enums/enrollment-steps.enum';
import { Country, State } from './models/country.model';
import { Error } from './models/error.model';
import { Step } from './models/step.model';

export const checkIfStepAvailable = (steps: Step[], stepId: EnrollmentSteps, isUserLeader: boolean): boolean => {
  const step = steps.find(step => step.id === stepId);
  const dependsOnStepWhichIsNotCompleted = steps.some(currentStep => step?.dependsOn?.includes(currentStep.id) ? !currentStep.completed : false);

  if (step?.onlyForLeads) {
    return !dependsOnStepWhichIsNotCompleted && isUserLeader;
  } else {
    return !dependsOnStepWhichIsNotCompleted;
  }
}

export const cutBirthDate = (dateOfBirth: string): string => {
  return dateOfBirth.replace('T00:00:00Z', '');
}

export const getCookie = (name) => {
  let matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export const goToAuthenticationApp = () => {
  location.replace(`${environment.authenticationPortalUrl}/login?client=${environment.client}`);
};

export const copy = (obj: any) => JSON.parse(JSON.stringify(obj));

export const HttpLoaderFactory = (http: HttpClient) => {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

export const getFullName = (obj: any) => `${obj.firstName} ${obj.lastName}`;

export const getStates = () => {
  const countriesArr: Country[] = copy(countries);
  const countryName = 'United States';
  const { states } = countriesArr.find(currentCountry => currentCountry.name === countryName);
  const statesArr: State[] = copy(states.sort((firstState: State, secondState: State) => firstState.name.localeCompare(secondState.name)));

  return statesArr;
};

export const getStateByCode = (fullCode: string) => {
  const clippedCode = fullCode.slice(3);
  const code = getStates().find( country => country.code === clippedCode);

  return code;
}

export const getErrorContentByCode = (error: {code: number, message: string}) => {
  const errorDescription: Error = ERRORS.find((currentError: Error) => currentError.code === error?.code);

  return errorDescription?.errorContent ? errorDescription?.errorContent : error?.message;
};

export const getValidProneNumber = (phoneNumber: string): string => {
  const from = phoneNumber.lastIndexOf('+');

  return phoneNumber.substr(from);
};

export const getKeyFromEnum = (enumObj, findingKey) => {
  let value: string = null;
  Object.keys(enumObj).forEach( key => {
    if (enumObj[key] === findingKey) {
      value = key;
    }
  });

  return value;
};

export const getRandomString = (length: number) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;

  for ( var i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

export const differentAddresses = (businessAddress: Address, legalAddress: Address): boolean => {
  if (
    (businessAddress !== null && businessAddress !== undefined) &&
    (businessAddress?.address1 !== legalAddress?.address1 ||
    businessAddress?.address2 !== legalAddress?.address2 ||
    businessAddress?.city !== legalAddress?.city ||
    businessAddress?.state !== legalAddress?.state ||
    businessAddress?.zipcode !== legalAddress?.zipcode)
  ) {
    return true;
  }

  return false;
}

export const getUSstateValue = (state: string): string => {
  return state?.includes('US-') ? state?.split('-')[1] : state;
}

export const checkIsAllStepsCompleted = (steps: Step[]): boolean => {
  const isAllStepsCompleted: boolean = steps
    .map((step: Step) => {
      if (
        (step.id !== EnrollmentSteps.termsAndAgreement) &&
        (step.id !== EnrollmentSteps.reviewInformation) &&
        (step.id !== EnrollmentSteps.contracts)
      ) {
        return step.completed;
      }
    })
    .filter(value => value !== undefined)
    .every(value => value);

      return isAllStepsCompleted;
}
