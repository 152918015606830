import { Injectable } from '@angular/core';

import { Action, State, StateContext, Store } from '@ngxs/store';
import { switchMap, tap } from 'rxjs/operators';

import { Spinner } from 'src/onboarding/shared/classes/spinner.class';
import { EnrollmentSteps } from 'src/onboarding/shared/enums/enrollment-steps.enum';
import { GoToStep, RefreshApplication, RefreshSession, SetBusinessEntityId, SetStepAsCompleted } from '../../../layout/ngxs/layout.actions';
import { LayoutState } from '../../../layout/ngxs/layout.state';
import { IdResponse } from '../models/id-response.model';
import { RestaurantNameService } from '../restaurant-name.service';
import { CreateRestaurantName, InitRestaurantName, SetRestaurantName, UpdateRestaurantName } from './restaurant-name.actions';

interface RestaurantNameStateModel {
  restaurantName: string;
  jobDescription: string;
}

@State<RestaurantNameStateModel>({
  name: 'restaurantName',
  defaults: {
    restaurantName: null,
    jobDescription: null
  }
})
@Injectable()
export class RestaurantNameState extends Spinner {

  constructor(
    protected store: Store,
    private restaurantNameService: RestaurantNameService
  ) {
    super(store);
  }

  @Action(UpdateRestaurantName)
  updateRestaurantName({patchState}: StateContext<RestaurantNameStateModel>, {restaurantName, businessEntityId}: UpdateRestaurantName) {
    this.showSpinner();

    return this.restaurantNameService.updateBusinessEntity(businessEntityId, restaurantName)
      .pipe(
        tap({
          next: () => {
            const { name, jobDescription } = restaurantName;

            patchState({
              restaurantName: name,
              jobDescription
            });
          },
          complete: () => this.finishStep()
        })
      );
  }

  @Action(CreateRestaurantName)
  createRestaurantName({patchState}: StateContext<RestaurantNameStateModel>, {restaurantName}: CreateRestaurantName) {
    this.showSpinner();

    return this.restaurantNameService.createBusinessEntity(restaurantName)
      .pipe(
        switchMap((idResponse: IdResponse) => {
          const { name, jobDescription } = restaurantName;

          patchState({
            restaurantName: name,
            jobDescription
          });
          this.store.dispatch(new SetBusinessEntityId(idResponse.id));
          return this.store.dispatch(new RefreshSession());
        }),
        tap({
          complete: () => this.finishStep()
        })
      );
  }

  @Action(SetRestaurantName)
  setRestaurantName(stateContext, {restaurantName}: SetRestaurantName) {
    const businessEntityId = this.store.selectSnapshot(LayoutState.getBusinessEntityId);
    const action = businessEntityId ?
      new UpdateRestaurantName(restaurantName, businessEntityId) :
      new CreateRestaurantName(restaurantName);

    this.store.dispatch(action);
  }

  private finishStep(): void {
    this.store.dispatch(new SetStepAsCompleted(EnrollmentSteps.restaurantName));
    this.store.dispatch(new GoToStep(EnrollmentSteps.restaurantStatus));
    this.hideSpinner();
    this.store.dispatch(new RefreshApplication());
  }

  @Action(InitRestaurantName)
  initRestaurantName({patchState}: StateContext<RestaurantNameStateModel>, {businessEntity}: InitRestaurantName) {
    if (businessEntity) {
      patchState({
        jobDescription: businessEntity.jobDescription,
        restaurantName: businessEntity.name
      });
    }
  }

}
