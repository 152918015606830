import { IndividualOwner } from 'src/onboarding/shared/models/individual-owner.model';
import { BusinessLeader } from '../model/business-leader.model';

export class InitTermsAndAgreement {
  static readonly type = '[Terms and Agreement] Init';
}

export class CreateBusinessLeader {
  static readonly type = '[Business Leader] Create';

  constructor(
    public businessLeader: BusinessLeader,

  ) {}
}

export class SetRequestSignerVisibility {
  static readonly type = '[Request Signer Visibility] Set';

  constructor(
    public visibility: boolean
  ) {}
}

export class YesIAm {
  static readonly type = '[I Am] Yes';
}

export class CreateControlPerson {
  static readonly type = '[Control Person] Create';

  constructor(
    public controlPerson: IndividualOwner
  ) {}
}

export class CreateControlPersonFromIndividualOwner {
  static readonly type = '[Control Person] Create From Individual Owner';

  constructor(
    public individualOwner: IndividualOwner
  ) {}
}
