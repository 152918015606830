<div class="rs-individual-form rs-form-control-outline" [formGroup]="individualForm">
  <div class="rs-row">
    <mat-form-field appearance="outline">
      <mat-label>{{'placeholders.first-name' | translate}}*</mat-label>
      <input formControlName="firstName" type="text" matInput placeholder="{{'placeholders.first-name' | translate}}">
      <mat-error *ngIf="individualForm.get('firstName').hasError('required')">
        {{ "placeholders.required" | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{'placeholders.last-name' | translate}}*</mat-label>
      <input formControlName="lastName" type="text" matInput placeholder="{{'placeholders.last-name' | translate}}">
      <mat-error *ngIf="individualForm.get('lastName').hasError('required')">
        {{ "placeholders.required" | translate }}
      </mat-error>
    </mat-form-field>
  </div>
  <div class="rs-row">
    <div class="rs-individual-form__phone-control">
      <rs-phone-control [rsPhoneControl]="individualForm.controls.phone"></rs-phone-control>
    </div>
    <mat-form-field appearance="outline">
      <mat-label>{{'placeholders.email' | translate}}*</mat-label>
      <input formControlName="email" type="text" matInput placeholder="Email Address*">
      <mat-error *ngIf="individualForm.get('email').hasError('required')">
        {{ "placeholders.required" | translate }}
      </mat-error>
      <mat-error *ngIf="individualForm.get('email').hasError('pattern')">
        {{ "placeholders.wrong-email" | translate }}
      </mat-error>
    </mat-form-field>
  </div>
  <div class="rs-row">
    <mat-form-field appearance="outline">
      <mat-label>{{'placeholders.nationality' | translate}}*</mat-label>
      <mat-select formControlName="nationality">
        <mat-option *ngFor="let nationality of nationalities" [value]="nationality">{{ nationality }}</mat-option>
      </mat-select>
      <mat-error *ngIf="individualForm.get('nationality').hasError('required')">
        {{ "placeholders.required" | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{'placeholders.secondary-nationality' | translate}}</mat-label>
      <mat-select formControlName="secondaryNationality">
        <mat-option>{{'placeholders.none' | translate}}</mat-option>
        <mat-option *ngFor="let nationality of nationalities" [value]="nationality">{{ nationality }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="rs-row">
    <mat-form-field appearance="outline">
      <mat-label>{{'placeholders.residing-country' | translate}}*</mat-label>
      <mat-select formControlName="residingCountry">
        <mat-option *ngFor="let country of countries" [value]="country.name">{{country.name}}</mat-option>
      </mat-select>
      <mat-error *ngIf="individualForm.get('residingCountry').hasError('required')">
        {{ "placeholders.required" | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{'placeholders.birth-date' | translate}}*</mat-label>
      <input
        [matDatepickerFilter]="mustBeInPast"
        formControlName="birthDate"
        matInput type="text"
        placeholder="{{'placeholders.birth-date' | translate}}"
        (click)="picker.open()"
        [matDatepicker]="picker"
        rsDatePickerValidationChecker
        [control]="individualForm"
        [picker]="picker">
      <mat-datepicker #picker></mat-datepicker>
      <mat-error *ngIf="individualForm.get('birthDate').hasError('required')">
        {{ "placeholders.required" | translate }}
      </mat-error>
    </mat-form-field>
  </div>
  <div class="rs-row">
    <rs-control-with-mask [rsFormControl]="individualForm.controls.socialSecurityNumber" [placeholder]="'placeholders.social-security-number'" mask="000-00-0000"></rs-control-with-mask>
    <rs-percent-control *ngIf="isControlPerson" [cantBeZero]="true" [rsFormControl]="individualForm.controls.ownership" placeholder="{{ 'owners.share' | translate: {name: restaurantName} }}"></rs-percent-control>
  </div>
  <rs-address-form [inputForm]="addressForm" [withCountry]="true"></rs-address-form>
  <div class="rs-individual-form__section-title">{{'placeholders.document-info' | translate}}</div>
  <div class="rs-row">
    <mat-form-field appearance="outline">
      <mat-label>{{'placeholders.document-issuer' | translate}}*</mat-label>
      <input formControlName="documentIssuer" type="text" matInput placeholder="{{'placeholders.document-issuer' | translate}}*">
      <mat-error *ngIf="individualForm.get('documentIssuer').hasError('required')">
        {{ "placeholders.required" | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{'placeholders.issuing-state' | translate}}*</mat-label>
      <mat-select formControlName="issuingState">
        <mat-option [value]="state.code" *ngFor="let state of states">{{ state.name }}</mat-option>
      </mat-select>
      <mat-error *ngIf="individualForm.get('issuingState').hasError('required')">
        {{ "placeholders.required" | translate }}
      </mat-error>
    </mat-form-field>
  </div>
  <div class="rs-row">
    <mat-form-field appearance="outline">
      <mat-label>{{'placeholders.issuing-date' | translate}}*</mat-label>
      <input
        [matDatepickerFilter]="mustBeInPast"
        formControlName="issueDate"
        matInput type="text"
        placeholder="{{'placeholders.issuing-date' | translate}}"
        (click)="picker2.open()"
        [matDatepicker]="picker2"
        rsDatePickerValidationChecker
        [control]="individualForm"
        [picker]="picker2">
      <mat-error *ngIf="individualForm.get('issueDate').hasError('required')">
        {{ "placeholders.required" | translate }}
      </mat-error>
      <mat-datepicker #picker2></mat-datepicker>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{'placeholders.expire-date' | translate}}*</mat-label>
      <input
        [matDatepickerFilter]="checkFutureTime()"
        formControlName="expireDate"
        matInput
        type="text"
        placeholder="{{'placeholders.expire-date' | translate}}"
        (click)="picker3.open()"
        [matDatepicker]="picker3"
        rsDatePickerValidationChecker
        [control]="individualForm"
        [picker]="picker3">
      <mat-error *ngIf="individualForm.get('expireDate').hasError('required')">
        {{ "placeholders.required" | translate }}
      </mat-error>
      <mat-datepicker #picker3></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="rs-row">
    <mat-form-field appearance="outline">
      <mat-label>{{'placeholders.document-type' | translate}}*</mat-label>
      <mat-select formControlName="documentType">
        <mat-option *ngFor="let documentType of documentTypes" [value]="documentType.value">{{documentType.key}}</mat-option>
      </mat-select>
      <mat-error *ngIf="individualForm.get('documentType').hasError('required')">
        {{ "placeholders.required" | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{'placeholders.id-number' | translate}}*</mat-label>
      <input formControlName="idNumber" type="text" matInput placeholder="{{'placeholders.id-number' | translate}}">
      <mat-error *ngIf="individualForm.get('idNumber').hasError('required')">
        {{ "placeholders.required" | translate }}
      </mat-error>
    </mat-form-field>
  </div>
</div>
