<div class="rs-plan-item">
  <div class="rs-plan-item__label">{{ plan.planName }}</div>
  <div class="rs-plan-item__info">{{plan.summary}}</div>
  <div class="rs-plan-item__prices">
    <div class="rs-plan-item__prices-price" *ngFor="let fee of plan.rockspoonFees">
      <mat-icon>check_circle</mat-icon>
      <div>
        <p>{{ fee.title }}</p>
        <span>{{getPercentage(fee)}}% + {{getMoney(fee) | currency}}</span>
      </div>
    </div>
  </div>
  <div class="rs-plan-item__info">{{plan.summary2}}</div>
</div>
