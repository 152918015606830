import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

import { OTHER } from '../../constants/common';
import { ListItem } from '../../models/list-item.model';

@Component({
  selector: 'rs-radio-list',
  templateUrl: 'radio-list.component.html',
  styleUrls: ['radio-list.component.scss']
})
export class RadioListComponent implements OnInit, OnChanges {

  @Output() accepted = new EventEmitter<string>();
  @Output() canceled = new EventEmitter<boolean>();

  @Input() caption: string;
  @Input() items: ListItem[];
  @Input() acceptButtonText: string;
  @Input() cancelButtonText: string;
  @Input() radioControl: FormControl;
  @Input() value: string;
  @Input() isShowOtherChoice: boolean;

  @ViewChild('otherValueInput') otherValueInput: ElementRef<HTMLInputElement>;

  public otherValue: string;
  public actualValue: string;

  public ngOnInit(): void {
    this.checkValue();
  }

  public ngOnChanges(): void {
    this.checkValue();
  }

  public isNextButtonValid(): boolean {
    let valid: boolean;

    if (this.radioControl.valid) {
      valid = true;

      if (this.radioControl.value === OTHER) {
        valid = !!this.otherValue;
      }
    } else {
      valid = false;
    }

    return valid;
  }

  public isOtherValueDisabled(): boolean {
    return this.radioControl.value !== OTHER;
  }

  public reactOnChange(): void {
    if (this.radioControl.value === OTHER) {
      setTimeout(() => {
        this.otherValueInput.nativeElement.focus();
      });
    } else {
      this.otherValue = '';
    }
  }

  public reactOnAcceptedClick(): void {
    this.accepted.emit(this.radioControl.value === OTHER ? this.otherValue : this.radioControl.value);
    setTimeout(() => {
      this.checkValue();
    });
  }

  public reactOnCanceledClick(): void {
    this.canceled.emit();
  }

  private checkValue(): void {
    if (this.value) {
      if (this.items.find(item => item.value === this.value)) {
        this.actualValue = this.value;
      } else {
        this.actualValue = OTHER;
        this.otherValue = this.value;
      }
    }

    this.radioControl.reset(this.actualValue);
  }

}
