import { BusinessEntityExtended } from 'src/onboarding/shared/models/business-entity.model';
import { Address } from '../models/address.model';

export class SaveRestaurantAddress {
  static readonly type = '[Restaurant Address] Save';

  constructor(
    public businessAddress: Address,
    public legalAddress: Address
  ) {}
}

export class InitRestaurantAddress {
  static readonly type = '[Restaurant Address] Init';

  constructor(
    public businessEntity: BusinessEntityExtended
  ) {}
}
