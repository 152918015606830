import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';
import { TermsAndAgreementState } from 'src/onboarding/modules/enrollment/flow/terms-and-agreement/ngxs/terms-and-agreement.state';

import { LayoutState } from 'src/onboarding/modules/enrollment/layout/ngxs/layout.state';

@Injectable({
  providedIn: 'root'
})
export class BackEndTemplateProcessor {

  private fromTemplateKeyToData = new Map<string, string>([
    ['address', 'getBusinessEntityAddress'],
    ['companyName', 'getCompanyName'],
    ['controlPersonName', 'getControlPersonFullName'],
    ['jobTitle', 'getBusinessEntityJobTitle']
  ]);

  constructor(
    private store: Store
  ) {}

  public process(content: string): string {
    if (content.includes('{{') && content.includes('}}')) {
      const startIndex = content.indexOf('{{');
      const endIndex = content.indexOf('}}');
      const key = content.substring(startIndex + 2, endIndex);
      const data = this[this.fromTemplateKeyToData.get(key)]();

      return this.process(content.substring(0, startIndex) + data + content.substring(endIndex + 2));
    } else {
      return content;
    }
  }

  private getControlPersonFullName(): string {
    return this.store.selectSnapshot(TermsAndAgreementState.getControlPersonFullName);
  }

  private getBusinessEntityAddress(): string {
    const address = this.store.selectSnapshot(LayoutState.getBusinessEntity).businessInformation.businessAddress;
    
    return `${address.country} ${address.city} ${address.address1} ${address.address2}`;
  }

  private getCompanyName(): string {
    return this.store.selectSnapshot(LayoutState.getBusinessEntity).name;
  }

  private getBusinessEntityJobTitle(): string {
    return this.store.selectSnapshot(LayoutState.getBusinessEntity).jobDescription;
  }

}