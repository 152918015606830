import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import { FormGroup } from '@angular/forms';

import { getStateByCode, getStates } from 'src/onboarding/shared/helpers';
import { State } from 'src/onboarding/shared/models/country.model';

@Component({
  selector: 'rs-company',
  templateUrl: 'company.component.html',
  styleUrls: ['company.component.scss']
})
export class CompanyComponent implements OnInit, OnChanges {

  @Input() form: FormGroup;
  @Input() isEdit: boolean;
  @Output() close = new EventEmitter<void>();
  @Output() closeEdit = new EventEmitter<void>();
  @Output() saveEdit = new EventEmitter<void>();

  public companyForm: FormGroup;
  public states: State[];

  public ngOnInit(): void {
    this.states = getStates();
  }

  public ngOnChanges(): void {
    this.updateFormValues();
  }

  public closeForm(): void {
    this.close.emit();
  }

  public saveEditForm(): void {
    this.saveEdit.emit();
  }

  public closeEditForm(): void {
    this.closeEdit.emit();
  }

  private updateFormValues(): void {
    if (this.isEdit) {
      const currentStateCode = getStateByCode(this.form.value.state).code;
      this.form.get('state').setValue(currentStateCode);
    }
  }

}
