<div class="rs-request-signer">
  <div class="rs-request-signer__choose-title">{{'terms-and-agreement.choose-signer' | translate}}</div>
  <div class="rs-request-signer__choose-value">
    <mat-form-field appearance="outline">
      <mat-label>{{'placeholders.owner' | translate}}</mat-label>
      <mat-select (selectionChange)="reactOnChooseSigner($event)">
        <mat-option *ngFor="let owner of individuals" [value]="owner">{{owner | rsFullName}}</mat-option>
        <mat-option value="other">{{'placeholders.other' | translate}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="rs-request-signer__other rs-form-control-outline" *ngIf="otherForm" [formGroup]="otherForm">
    <div class="rs-row">
      <mat-form-field appearance="outline">
        <mat-label>{{'placeholders.first-name' | translate}}*</mat-label>
        <input formControlName="firstName" type="text" matInput placeholder="{{'placeholders.first-name' | translate}}*">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{'placeholders.first-name' | translate}}*</mat-label>
        <input formControlName="lastName" type="text" matInput placeholder="{{'placeholders.first-name' | translate}}*">
      </mat-form-field>
    </div>
    <div class="rs-row">
      <mat-form-field appearance="outline">
        <mat-label>{{'placeholders.email' | translate}}*</mat-label>
        <input formControlName="email" type="text" matInput placeholder="{{'placeholders.email' | translate}}*">
      </mat-form-field>
      <div></div>
    </div>
    <rs-section-footer
      *ngIf="otherForm.valid"
      (accepted)="requestSignature()"
      buttonText="REQUEST SIGNATURE FROM {{otherForm.value.firstName}}"
      cancelButtonText="{{otherForm.valid ? 'cancel' : ''}}"
      (canceled)="cancel()"
    ></rs-section-footer>
  </div>
  <rs-section-footer *ngIf="singleCancelVisibility()" (accepted)="cancel()" buttonText="cancel"></rs-section-footer>
</div>
