<div class="rs-ownership-structure rs-enrollment-section" id="ownershipStructure">
  <div class="rs-enrollment-section__content">
    <rs-radio-list
      caption="What is your business' ownership structure?"
      [items]="ownershipStructures"
      acceptButtonText="{{'buttons.save' | translate}}"
      [radioControl]="ownershipStructuresControl"
      [value]="ownershipStructure"
      (accepted)="goToNextStep($event)"
    ></rs-radio-list>
  </div>
</div>
