import { Component, Input, OnChanges, OnInit } from '@angular/core';

import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { RockspoonPlan } from 'src/onboarding/modules/enrollment/flow/rockspoon-plan/models/rockspoon-plan.model';
import { Unsubscribe } from 'src/onboarding/shared/classes/unsubscribe.class';
import { BusinessEntityExtended } from 'src/onboarding/shared/models/business-entity.model';

@Component({
  selector: 'rs-review-rockspoon-plan',
  templateUrl: 'review-rockspoon-plan.component.html',
  styleUrls: ['review-rockspoon-plan.component.scss']
})
export class ReviewRockspoonPlanComponent extends Unsubscribe implements OnChanges, OnInit {

  @Select(state => state.rockspoonPlan.plans) plans$: Observable<RockspoonPlan[]>;

  @Input() businessEntity: BusinessEntityExtended;

  public selectedPlan: RockspoonPlan;

  private plans: RockspoonPlan[];

  public ngOnInit(): void {
    this.initSubscriptions();
  }

  public ngOnChanges(): void {
    this.getSelectedPlan();
  }

  private initSubscriptions(): void {
    this.subscribeTo = this.plans$
      .subscribe((plans: RockspoonPlan[]) => {
        this.plans = plans;
        this.getSelectedPlan();
      });
  }

  private getSelectedPlan(): void {
    if (this.plans) {
      this.selectedPlan = this.plans.find(plan => plan.id === this.businessEntity.planId);
    }
  }

}
