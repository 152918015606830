import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { EnrollmentPage } from 'src/onboarding/shared/classes/enrollment-page.class';
import { ENROLLMENT_SECTIONS } from 'src/onboarding/shared/constants/enrollment-sections.constants';
import { EnrollmentSteps } from 'src/onboarding/shared/enums/enrollment-steps.enum';
import { GoToStep } from '../../../layout/ngxs/layout.actions';
import { SalesDistribution } from '../models/sales-distribution.model';
import { SaveSalesDistribution } from '../ngxs/sales-distribution.actions';

@Component({
  selector: 'rs-sales-distribution',
  templateUrl: 'sales-distribution.component.html',
  styleUrls: ['sales-distribution.component.scss']
})
export class SalesDistributionComponent extends EnrollmentPage implements OnInit {

  @Select(state => state.salesDistribution.salesDistribution) salesDistribution$: Observable<SalesDistribution>;

  public salesDistributionForm: FormGroup;

  private salesDistribution: SalesDistribution;

  constructor(
    private formBuilder: FormBuilder,
    protected store: Store
  ) {
    super(ENROLLMENT_SECTIONS.SALES_DISTRIBUTION, store);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.initForm();
    this.initSubscription();
  }

  public save(): void {
    this.store.dispatch(new SaveSalesDistribution(new SalesDistribution(this.salesDistributionForm.value)));
  }

  public isSaveDisabled(): boolean {
    return this.salesDistributionForm.invalid ||
      (Number(this.salesDistributionForm.value.cardPresentTransactions) +
       Number(this.salesDistributionForm.value.internetTransactions) +
       Number(this.salesDistributionForm.value.mailPhoneTransactions)
      ) !== 100;
  }

  private initForm(): void {
    this.salesDistributionForm = this.formBuilder.group({
      cardPresentTransactions: [this.salesDistribution ? this.salesDistribution.cardPresentTransactions : '', Validators.required],
      internetTransactions: [this.salesDistribution ? this.salesDistribution.internetTransactions : '', Validators.required],
      mailPhoneTransactions: [this.salesDistribution ? this.salesDistribution.mailPhoneTransactions : '', Validators.required]
    });
  }

  private initSubscription(): void {
    this.subscribeTo = this.salesDistribution$
      .subscribe((salesDistribution: SalesDistribution) => {
        this.salesDistribution = salesDistribution;
        this.initForm();
      });
  }

}
