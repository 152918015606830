<div class="rs-review-owners-information" *ngIf="individuals || companies">
  <div class="rs-review-step">{{ "review.owners-information" | translate }}</div>
  <div class="rs-review-owners-information__owners">
    <div *ngIf="individuals" class="rs-review-owners-information__owners-wrapper">
      <div *ngFor="let individual of individuals" class="rs-review-owners-information__owners-owner">
        <div class="rs-review-owners-information__owners-owner-label individual">Individual</div>
        <div class="rs-review-owners-information__owners-owner-name">{{ individual | rsFullName }}</div>
        <div class="rs-review-owners-information__owners-owner-key">{{ "owners.ownership" | translate }} %</div>
        <div class="rs-review-owners-information__owners-owner-value">{{ individual.share }}%</div>
        <div class="rs-review-owners-information__owners-owner-key">{{ "placeholders.email" | translate }}</div>
        <div class="rs-review-owners-information__owners-owner-value">{{ individual.email }}</div>
        <div class="rs-review-owners-information__owners-owner-value"></div>
      </div>
    </div>
    <div *ngIf="companies" class="rs-review-owners-information__owners-wrapper">
      <div *ngFor="let company of companies" class="rs-review-owners-information__owners-owner">
        <div class="rs-review-owners-information__owners-owner-label company">Company</div>
        <div class="rs-review-owners-information__owners-owner-name">{{ company.businessName }}</div>
        <div class="rs-review-owners-information__owners-owner-key">{{ "owners.ownership" | translate }} %</div>
        <div class="rs-review-owners-information__owners-owner-value">{{ company.share }}%</div>
        <div class="rs-review-owners-information__owners-owner-key">{{ "placeholders.email" | translate }}</div>
        <div class="rs-review-owners-information__owners-owner-value">{{ company.email }}</div>
        <div class="rs-review-owners-information__owners-owner-value"></div>
      </div>
    </div>
  </div>
</div>
