import { Component, Input } from '@angular/core';

import { Store } from '@ngxs/store';
import * as moment from 'moment';

import { DEFAULT_TIMESTAMP_VALUE } from 'src/onboarding/shared/constants/common';
import { Invitation } from '../../model/invitation.model';
import { CreateBusinessLeader, SetRequestSignerVisibility } from '../../ngxs/terms-and-agreement.actions';

@Component({
  selector: 'rs-invited-list',
  templateUrl: 'invited-list.component.html',
  styleUrls: ['invited-list.component.scss']
})
export class InvitedListComponent {

  @Input() activeInvitation: Invitation;

  constructor(
    private store: Store
  ) {}

  public inviteAnotherPerson(): void {
    this.store.dispatch(new SetRequestSignerVisibility(true));
  }

  public wasInviteResent(invitation: Invitation): boolean {
    return moment(invitation.createdAt).valueOf() !== moment(invitation.lastInviteSent).valueOf() &&
           moment(invitation.lastInviteSent).valueOf() !== DEFAULT_TIMESTAMP_VALUE;
  }

  public resend(invitation: Invitation): void {
    const [firstName, lastName] = invitation.inviteName.split(' ');

    this.store.dispatch( new CreateBusinessLeader({
      email: invitation.email,
      firstName,
      lastName
    }));
  }

  public getDate(date: string): string {
    return moment(date).format('ddd, MMM DD YYYY');
  }

  public isContentVisible(): boolean {
    return !!this.activeInvitation;
  }

}
