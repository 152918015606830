import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ENDPOINTS } from 'src/onboarding/shared/constants/endpoints';
import { IndividualOwner } from 'src/onboarding/shared/models/individual-owner.model';
import { ApiService } from 'src/onboarding/shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class OwnersInformationService {

  constructor(
    private apiService: ApiService
  ) {}

  public saveCompanies(businessEntityId: string | number, companies: any[]): Observable<void> {
    return this.apiService.put(ENDPOINTS.COMPANIES(businessEntityId), companies);
  }

  public saveIndividuals(businessEntityId: string | number, individuals: IndividualOwner[]): Observable<void> {
    return this.apiService.put(ENDPOINTS.INDIVIDUALS(businessEntityId), individuals);
  }

}
