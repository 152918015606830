import { Injectable } from '@angular/core';

import { Action, State, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { Spinner } from 'src/onboarding/shared/classes/spinner.class';
import { EnrollmentSteps } from 'src/onboarding/shared/enums/enrollment-steps.enum';
import { GoToStep, RefreshApplication, SetStepAsCompleted } from '../../../layout/ngxs/layout.actions';
import { LayoutState } from '../../../layout/ngxs/layout.state';
import { BankAccountService } from '../bank-account.service';
import { BankingInfo } from '../models/banking-info.model';
import { InitBankingInformation, SetBankingInformation } from './bank-account.actions';

interface BankingInfoStateModel {
  bankingInfo: BankingInfo;
}

@State<BankingInfoStateModel>({
  name: 'bankingInfo',
  defaults: {
    bankingInfo: null
  }
})
@Injectable()
export class BankingInfoState extends Spinner {

  constructor(
    protected store: Store,
    private bankAccountService: BankAccountService
  ) {
    super(store);
  }

  @Action(InitBankingInformation)
  initBankingInformation({patchState}: StateContext<BankingInfoStateModel>, {businessEntity}: InitBankingInformation) {
    patchState({
      bankingInfo: {
        routingNumber: businessEntity.bankingInformation.routingNumber,
        accountNumber: businessEntity.bankingInformation.accountNumber
      }
    });
  }

  @Action(SetBankingInformation)
  setBankingInformation({patchState}: StateContext<BankingInfoStateModel>, {bankingInfo}: SetBankingInformation) {
    this.showSpinner();

    return this.bankAccountService.saveBankingInformation(
      bankingInfo,
      this.store.selectSnapshot(LayoutState.getBusinessEntityId)
    )
      .pipe(
        tap({
          next: () => {
            patchState({
              bankingInfo
            });
          },
          complete: () => {
            this.store.dispatch(new SetStepAsCompleted(EnrollmentSteps.bankAccount));
            this.store.dispatch(new GoToStep(EnrollmentSteps.reviewInformation));
            this.hideSpinner();
            this.store.dispatch(new RefreshApplication());
          }
        })
      )
  }

}
