import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { EnrollmentPage } from 'src/onboarding/shared/classes/enrollment-page.class';
import { ENROLLMENT_SECTIONS } from 'src/onboarding/shared/constants/enrollment-sections.constants';
import { EnrollmentSteps } from 'src/onboarding/shared/enums/enrollment-steps.enum';
import { OwnerTypes } from 'src/onboarding/shared/enums/owners.enum';
import { IndividualOwner } from 'src/onboarding/shared/models/individual-owner.model';
import { GoToStep } from 'src/onboarding/modules/enrollment/layout/ngxs/layout.actions';
import { CompanyOwner } from 'src/onboarding/shared/models/company-owner.model';
import { OwnersForm } from 'src/onboarding/shared/classes/owners';
import {
  ChangeOwnersFlowStatus,
  DeleteCompany,
  DeleteIndividual,
  SaveOwners,
  UpdateCompany,
  UpdateIndividual
} from '../../ngxs/owners-information.actions';

@Component({
  selector: 'rs-owners-information',
  templateUrl: 'owners-information.component.html',
  styleUrls: [
    'owners-information.component.scss',
    'owners-information.component.responsive.scss'
  ],
})
export class OwnersInformationComponent extends EnrollmentPage implements OnInit {
  @Select(state => state.restaurantName.restaurantName) restaurantName$: Observable<string>;
  @Select(state => state.ownersInformation.companies) companies$: Observable<CompanyOwner[]>;
  @Select(state => state.ownersInformation.individuals) individuals$: Observable<IndividualOwner[]>;
  @Select(state => state.ownersInformation.isCreationStarted) isCreationStarted$: Observable<boolean>;

  public owners: FormGroup[] = [];
  public isShowBottomSelection: boolean = true;
  public isFormsValid: boolean = false;
  public isIndividualEdit: boolean = false;
  public isCompanyEdit: boolean = false;
  public editIndividualFormIndex: number | string = null;
  public editCompanyFormIndex: number | string = null;
  public ownerFormForEdit: FormGroup = null;

  constructor(
    protected store: Store
  ) {
    super(ENROLLMENT_SECTIONS.OWNERS_INFORMATION, store);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.initFormsSubscription();
  }

  private initFormsSubscription(): void {
    this.owners.forEach( form => {
      this.subscribeTo = form.valueChanges
        .subscribe(() => this.checkOwnerFormValid());
    });
    this.checkOwnerFormValid();
  }

  public addOwner(ownerType: string): void {
    this.store.dispatch(new ChangeOwnersFlowStatus(true));
    this.isShowBottomSelection = false;

    ownerType === OwnerTypes.individual ?
      this.owners.push(OwnersForm.getIndividualOwnerForm()) :
      this.owners.push(OwnersForm.getCompanyOwnerForm());

    setTimeout(() => {
      this.isShowBottomSelection = true;
    });

    this.initFormsSubscription();
  }

  public changeOwnerType(ownerType: string, idx: string): void {
    this.store.dispatch(new ChangeOwnersFlowStatus(true));
    this.owners[idx] = ownerType === OwnerTypes.individual ?
      OwnersForm.getIndividualOwnerForm() : OwnersForm.getCompanyOwnerForm();

    this.initFormsSubscription();
  }

  public closeOwner(idx: string): void {
    this.owners.splice(+idx, 1);

    if (!this.owners.length) {
      this.store.dispatch(new GoToStep(EnrollmentSteps.ownersInformation));
    }

    this.initFormsSubscription();
  }

  public checkOwnerFormValid(): void {
    this.isFormsValid = this.owners.every(form => form.valid);
  }

  public skip(): void {
    this.store.dispatch(new GoToStep(EnrollmentSteps.bankAccount));
    this.owners = [];
  }

  public saveOwners(): void {
    const individualOwnersForms: FormGroup[] = this.owners.filter( form => form.value.isIndividual);
    const companyOwnersForms: FormGroup[] = this.owners.filter( form => form.value.isCompany);
    const individualOwners: IndividualOwner[] = [];
    const companyOwners: CompanyOwner[] = [];

    if (individualOwnersForms.length) {
      individualOwnersForms.forEach((form: FormGroup) => {
        const individualOwnerForm = new IndividualOwner(form.value, form.value);

        individualOwners.push(individualOwnerForm);
      });
    }

    if (companyOwnersForms.length) {
      companyOwnersForms.forEach((form: FormGroup) => {
        const companyOwnerForm = new CompanyOwner(form.value, form.value);

        companyOwners.push(companyOwnerForm);
      });
    }

    this.store.dispatch(new SaveOwners(individualOwners, companyOwners));

    this.subscribeTo = this.isCreationStarted$
      .subscribe((isCreationStarted: boolean) => !isCreationStarted ? this.owners = [] : null);
  }

  public editIndividual(owner: IndividualOwner, idx: string): void {
    this.editIndividualFormIndex = idx;
    this.ownerFormForEdit = OwnersForm.getPatchedIndividualForm(owner);
    this.isCompanyEdit = false;
    this.isIndividualEdit = true;
  }

  public editCompany(owner: CompanyOwner, idx: string): void {
    this.editCompanyFormIndex = idx;
    this.ownerFormForEdit = OwnersForm.getPatchedCompanyOwnerForm(owner);
    this.isIndividualEdit = false;
    this.isCompanyEdit = true;
  }

  public closeEditForm(isScrollDown: boolean): void {
    this.ownerFormForEdit = null;
    this.isIndividualEdit = false;
    this.isCompanyEdit = false;

    !isScrollDown ? this.store.dispatch(new GoToStep(EnrollmentSteps.ownersInformation)) : null;
  }

  public saveEditForm(): void {
    const formValue = this.ownerFormForEdit.value;
    let updatedOwner;

    if (typeof formValue.phone === 'string') {
      formValue.phone = {
        countryCode: formValue.phone.slice(0, 2),
        number: formValue.phone.slice(0, 2) + formValue.phone
      }
    }

    if (this.ownerFormForEdit.value.ownerType === OwnerTypes.individual) {
      updatedOwner = new IndividualOwner(this.ownerFormForEdit.value, this.ownerFormForEdit.value);
      this.store.dispatch(new UpdateIndividual(updatedOwner, this.editIndividualFormIndex));
    } else if (this.ownerFormForEdit.value.ownerType === OwnerTypes.company) {
      updatedOwner = new CompanyOwner(this.ownerFormForEdit.value, this.ownerFormForEdit.value);
      this.store.dispatch(new UpdateCompany(updatedOwner, this.editCompanyFormIndex));
    }

    this.subscribeTo = this.isCreationStarted$
      .subscribe((isCreationStarted: boolean) => !isCreationStarted ? this.closeEditForm(true) : null);
  }


  public deleteIndividual(idx: string): void {
    this.store.dispatch(new DeleteIndividual(idx));
    this.closeEditForm(false);
  }

  public deleteCompany(idx: string): void {
    this.store.dispatch(new DeleteCompany(idx));
    this.closeEditForm(false);
  }

}
