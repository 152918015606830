import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';

import { Observable } from 'rxjs';

import { HEADERS } from '../constants/common';
import { SharedDataService } from '../services/shared-data.service';
import { ENDPOINTS_WITHOUT_ACCESS_TOKEN } from '../constants/endpoints';

@Injectable()
export class AccessTokenInterceptor implements HttpInterceptor {

  private sharedDataService: SharedDataService;

  constructor(
    private injector: Injector
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.sharedDataService = this.injector.get(SharedDataService);

    if (!ENDPOINTS_WITHOUT_ACCESS_TOKEN.some(route => request.url.includes(route))) {
      request = request.clone({ headers: request.headers.set(HEADERS.ACCESS_TOKEN, this.sharedDataService.accessToken)});
    }

    return next.handle(request);
  }

}
