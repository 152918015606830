import { NgModule } from '@angular/core';

import { DirectivesModule } from '../../directives/directives.module';
import { MaterialModule } from '../../modules/material.module';
import { SharedModule } from '../../modules/shared.module';
import { SideNavItemComponent } from './side-nav-item/side-nav-item.component';
import { SideNavComponent } from './side-nav/side-nav.component';

@NgModule({
  imports: [
    SharedModule,
    MaterialModule,
    DirectivesModule
  ],
  declarations: [
    SideNavComponent,
    SideNavItemComponent
  ],
  exports: [
    SideNavComponent
  ]
})
export class NavigationModule {}
