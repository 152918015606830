import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Unsubscribe } from '../../classes/unsubscribe.class';
import { ENDPOINTS } from '../../constants/endpoints';
import { ZIPCODE_MASK } from '../../constants/inputs-masks';
import { getStates } from '../../helpers';
import { AddressDetails, State } from '../../models/country.model';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'rs-address-form',
  templateUrl: 'address-form.component.html',
  styleUrls: ['address-form.component.scss']
})
export class AddressFormComponent extends Unsubscribe implements OnInit, OnChanges {

  @Output() formComplete = new EventEmitter<any>();
  @Input() inputForm: FormGroup;
  @Input() disabled: boolean;
  @Input() set state(state: string) {
    this.inputForm?.controls.state.reset(state);
  };
  @Input() withCountry: boolean;

  public states: State[];
  public zipcodeMask = ZIPCODE_MASK;

  constructor(
    private apiService: ApiService
  ){
    super();
  }

  public ngOnInit(): void {
    this.formSubscribe();
    this.states = getStates();
    this.checkDisabled();
  }

  public ngOnChanges(): void {
    this.checkDisabled();
  }

  public reactOnSelectAddressFromAutocomplete({selectedValue, suggestionId}): void {
    const [address1Value, cityValue, stateValue] = selectedValue.split(',').map(str => str.trim());
    const { address1, city, state } = this.inputForm.controls;

    this.subscribeTo = this.apiService.get(ENDPOINTS.ADDRESS_DETAILS, {suggestionId})
      .subscribe(({zipcode, stateCode}: AddressDetails) => {
        this.inputForm.controls.state.reset(stateCode);

        if (zipcode) {
          this.inputForm.patchValue({zipcode});
        }
      });

    address1.reset(address1Value);
    city.reset(cityValue);
    state.reset(stateValue);
  }

  private formSubscribe(): void {
    this.inputForm.valueChanges.subscribe( () => {
      if (this.inputForm.valid) {
        this.formComplete.emit(this.inputForm.value);
      }
    });
  }

  private checkDisabled(): void {
    if (this.disabled) {
      Object.keys(this.inputForm.controls).forEach((key: string) => this.inputForm.controls[key].disable());
    }
  }

}
