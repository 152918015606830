import { Component, Input, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { BusinessEntityExtended } from 'src/onboarding/shared/models/business-entity.model';

@Component({
  selector: 'rs-review-sales-distribution',
  templateUrl: 'review-sales-distribution.component.html',
  styleUrls: ['review-sales-distribution.component.scss']
})
export class ReviewSalesDistribution implements OnChanges {

  @Input() businessEntity: BusinessEntityExtended = null;

  public salesDistributionForm: FormGroup = null;

  constructor(
    public formBuilder: FormBuilder
  ) {}

  public ngOnChanges(): void {
    this.initForm();
  }

  private initForm(): void {
    this.salesDistributionForm = this.formBuilder.group({
      cardPresentTransactions: [{value: this.businessEntity.salesInformation.cardPresentTransactions, disabled: true}],
      internetTransactions: [{value: this.businessEntity.salesInformation.internetTransactions, disabled: true}],
      mailPhoneTransactions: [{value: this.businessEntity.salesInformation.mailPhoneTransactions, disabled: true}]
    });
  }

}
