import { Component, Input } from '@angular/core';

@Component({
  selector: 'rs-icon-button',
  templateUrl: 'icon-button.component.html',
  styleUrls: ['icon-button.component.scss']
})
export class IconButtonComponent {

  @Input() caption: string;
  @Input() selected: boolean;
  @Input() icon: string;
  @Input() disabled: boolean = false;

}
