<div class="rs-message">
  <rs-message>
    <div id="header">
      <div class="rs-message__header">
        {{'website.great-news' | translate}}
        <mat-icon>sentiment_satisfied</mat-icon>
      </div>
    </div>
    <div id="content" innerHTML="{{'website.in-addition' | translate}}"></div>
  </rs-message>
</div>
