import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { switchMap, tap } from 'rxjs/operators';

import { Spinner } from 'src/onboarding/shared/classes/spinner.class';
import { NOTIFICATION_TYPES } from 'src/onboarding/shared/constants/common';
import { OwnersInformationService } from '../owners-information.service';
import {
  ChangeOwnersFlowStatus,
  DeleteCompany,
  DeleteIndividual,
  InitOwners,
  SaveOwners,
  UpdateCompany,
  UpdateIndividual
} from './owners-information.actions';
import { IndividualOwner } from 'src/onboarding/shared/models/individual-owner.model';
import { EnrollmentSteps } from 'src/onboarding/shared/enums/enrollment-steps.enum';
import { GoToStep, RefreshApplication, SetStepAsCompleted, SetStepAsUncompleted } from '../../../layout/ngxs/layout.actions';
import { LayoutState } from '../../../layout/ngxs/layout.state';
import { CompanyOwner } from 'src/onboarding/shared/models/company-owner.model';
import { copy, cutBirthDate } from 'src/onboarding/shared/helpers';
import { NotifierService } from 'angular-notifier';
import { TranslationService } from 'src/onboarding/shared/services/translation.service';

interface OwnersInformationStateModel {
  companies: CompanyOwner[];
  individuals: IndividualOwner[];
  isCreationStarted: boolean;
}

@State<OwnersInformationStateModel>({
  name: 'ownersInformation',
  defaults: {
    companies: [],
    individuals: [],
    isCreationStarted: false
  }
})
@Injectable()
export class OwnersInformationState extends Spinner {

  constructor(
    protected store: Store,
    private ownersInformationService: OwnersInformationService,
    private notifierService: NotifierService,
    private translationService: TranslationService
  ) {
    super(store);
  }

  @Selector()
  static isCreationStarted({isCreationStarted}: OwnersInformationStateModel): boolean {
    return isCreationStarted;
  }

  @Selector()
  static companies({companies}: OwnersInformationStateModel): CompanyOwner[] {
    return companies;
  }

  @Selector()
  static individuals({individuals}: OwnersInformationStateModel): IndividualOwner[] {
    return individuals;
  }

  @Action(ChangeOwnersFlowStatus)
  changeOwnersFlowStatus({patchState}: StateContext<OwnersInformationStateModel>, {flowStatus}) {
    patchState({
      isCreationStarted: flowStatus
    });
  }

  @Action(DeleteIndividual)
  deleteIndividual({patchState, getState}: StateContext<OwnersInformationStateModel>, {index}) {
    const prevOwners = copy(getState().individuals);
    const id = this.store.selectSnapshot(LayoutState.getBusinessEntityId);

    prevOwners.splice(index, 1);

    this.showSpinner();

    return this.ownersInformationService.saveIndividuals(id, this.cutBirthDate(prevOwners))
      .pipe(
        tap({
          next: () => {
            patchState({
              individuals: prevOwners
            });
          },
          complete: () => {
            this.hideSpinner();
            this.checkStepAfterDelete();
            this.store.dispatch(new ChangeOwnersFlowStatus(false));
            this.store.dispatch(new RefreshApplication());
          }
        })
      )
  }

  @Action(DeleteCompany)
  deleteCompany({patchState, getState}: StateContext<OwnersInformationStateModel>, {index}) {
    const prevOwners = copy(getState().companies);
    const id = this.store.selectSnapshot(LayoutState.getBusinessEntityId);

    prevOwners.splice(index, 1);
    this.showSpinner();

    return this.ownersInformationService.saveCompanies(id, prevOwners)
      .pipe(
        tap({
          next: () => {
            patchState({
              companies: prevOwners
            });
          },
          complete: () => {
            this.hideSpinner();
            this.checkStepAfterDelete();
            this.store.dispatch(new ChangeOwnersFlowStatus(false));
            this.store.dispatch(new RefreshApplication());
          }
        })
      )
  }

  @Action(UpdateIndividual)
  updateIndividual({patchState, getState}: StateContext<OwnersInformationStateModel>, {individualOwner, index}) {
    const individualOwners = copy(getState().individuals);
    const companyOwners = copy(getState().companies);
    const id = this.store.selectSnapshot(LayoutState.getBusinessEntityId);
    const updatedOwner = copy(individualOwner);
    const ownersForCheck = individualOwners;

    ownersForCheck.splice(index, 1);

    if (this.checkOverallShare([...ownersForCheck, individualOwner], companyOwners)) {
      individualOwners[index] = updatedOwner;
      this.showSpinner();

      return this.ownersInformationService.saveIndividuals(id, this.cutBirthDate(individualOwners))
        .pipe(
          tap({
            next: () => {
              patchState({
                individuals: individualOwners
              });
            },
            complete: () => {
              this.hideSpinner();
              this.store.dispatch(new ChangeOwnersFlowStatus(false));
              this.store.dispatch(new RefreshApplication());
            }
          })
        )
    } else this.showShareErrorPopup();
  }

  @Action(UpdateCompany)
  updateCompany({patchState, getState}: StateContext<OwnersInformationStateModel>, {companyOwner, index}) {
    const companyOwners = copy(getState().companies);
    const individualOwners = copy(getState().individuals);
    const id = this.store.selectSnapshot(LayoutState.getBusinessEntityId);
    const updatedOwner = copy(companyOwner);
    const ownersForCheck = companyOwners;

    ownersForCheck.splice(index, 1);

    if (this.checkOverallShare(individualOwners, [...ownersForCheck, companyOwner])) {
      companyOwners[index] = updatedOwner;
      this.showSpinner();

      return this.ownersInformationService.saveCompanies(id, companyOwners)
        .pipe(
          tap({
            next: () => {
              patchState({
                companies: companyOwners
              });
            },
            complete: () => {
              this.hideSpinner();
              this.store.dispatch(new ChangeOwnersFlowStatus(false));
              this.store.dispatch(new RefreshApplication());
            }
          })
        )
    } else this.showShareErrorPopup();
  }

  @Action(SaveOwners)
  saveOwners({patchState, getState}: StateContext<OwnersInformationStateModel>, {individualOwners, companyOwners}) {

    const individuals = copy(getState().individuals);
    const companies = copy(getState().companies);

    if (this.checkOverallShare([...individuals, ...individualOwners], [...companies, ...companyOwners])) {
      const id = this.store.selectSnapshot(LayoutState.getBusinessEntityId);
      const prevIndividualOwners = copy(getState().individuals);
      const prevCompanyOwners = copy(getState().companies);
      const updatedIndividuals = [...prevIndividualOwners, ...individualOwners];
      const updatedCompanies = [...prevCompanyOwners, ...companyOwners];

      this.showSpinner();

      return this.ownersInformationService.saveIndividuals(id, this.cutBirthDate(updatedIndividuals))
        .pipe(
          tap({
            next: () => {
              patchState({
                individuals: updatedIndividuals || []
              });
            }
          }),
          switchMap(() => this.ownersInformationService.saveCompanies(id, updatedCompanies)),
          tap({
            next: () => {
              patchState({
                companies: updatedCompanies || []
              });
            },
            complete: () => {
              this.store.dispatch(new SetStepAsCompleted(EnrollmentSteps.ownersInformation));
              this.store.dispatch(new GoToStep(EnrollmentSteps.ownersInformation));
              this.hideSpinner();
              this.store.dispatch(new ChangeOwnersFlowStatus(false));
              this.store.dispatch(new RefreshApplication());
            }
          })
        );

    } else this.showShareErrorPopup();
  }

  @Action(InitOwners)
  initOwners({patchState}: StateContext<OwnersInformationStateModel>, {businessEntity}: InitOwners) {
    const { individual = [], companies = [] } = businessEntity.principals;

    patchState({
      companies,
      individuals: individual
    });
  }

  private showShareErrorPopup(): void {
    this.hideSpinner();
    this.store.dispatch(new ChangeOwnersFlowStatus(true));
    this.notifierService.notify(NOTIFICATION_TYPES.ERROR, this.translationService.getTranslation('popups.share-warning'));
  }

  private checkOverallShare(individuals: IndividualOwner[], companies: CompanyOwner[]): boolean {
    const allOwners = [...individuals, ...companies];

    return allOwners.reduce((acc, {share}) => acc + (+share), 0) <= 100;
  }

  private cutBirthDate(individuals: IndividualOwner[]): IndividualOwner[] {
    const updatedIndividuals = individuals.map((owner: IndividualOwner) => ({...owner, dateOfBirth: cutBirthDate(owner.dateOfBirth)}));
    return updatedIndividuals;
  }

  private checkStepAfterDelete(): void {
    const individuals = this.store.selectSnapshot(OwnersInformationState.individuals);
    const companies = this.store.selectSnapshot(OwnersInformationState.companies);

    !individuals.length && !companies.length ?
      this.store.dispatch(new SetStepAsUncompleted(EnrollmentSteps.ownersInformation)) : null;
  }

}
