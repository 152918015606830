import { Injectable } from '@angular/core';

import { Action, State, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

import { RestaurantStatuses } from 'src/onboarding/shared/enums/restaurant-statuses.enum';
import { InitRestaurantStatus, SetRestaurantStatus } from './restaurant-status.actions';
import { Spinner } from 'src/onboarding/shared/classes/spinner.class';
import { RestaurantStatusService } from '../restaurant-status.service';
import { EnrollmentSteps } from 'src/onboarding/shared/enums/enrollment-steps.enum';
import { GoToStep, RefreshApplication, SetStepAsCompleted } from '../../../layout/ngxs/layout.actions';
import { LayoutState } from '../../../layout/ngxs/layout.state';

export interface RestaurantStatusStateModel {
  restaurantStatus: RestaurantStatuses;
  posSystem: string;
}

@State<RestaurantStatusStateModel>({
  name: 'restaurantStatus',
  defaults: {
    restaurantStatus: null,
    posSystem: ''
  }
})
@Injectable()
export class RestaurantStatusState extends Spinner {

  constructor(
    protected store: Store,
    private restaurantStatusService: RestaurantStatusService
  ) {
    super(store);
  }

  @Action(InitRestaurantStatus)
  initRestaurantStatus({patchState}: StateContext<RestaurantStatusStateModel>, {businessEntity}: InitRestaurantStatus) {
    patchState({
      restaurantStatus: businessEntity.restaurantStatus,
      posSystem: businessEntity.posReplacement
    });
  }

  @Action(SetRestaurantStatus)
  setRestaurantStatus({patchState}: StateContext<RestaurantStatusStateModel>, {restaurantStatus, posSystem}: SetRestaurantStatus) {
    this.showSpinner();
    const id = this.store.selectSnapshot(LayoutState.getBusinessEntityId);
    const requestsArr = [this.restaurantStatusService.setRestaurantStatus(restaurantStatus, id)];

    if (posSystem) {
      requestsArr.push(this.restaurantStatusService.setPosReplacement(posSystem, id));
    }

    return forkJoin(requestsArr)
      .pipe(
        tap({
          next: () => {
            patchState({
              posSystem,
              restaurantStatus
            });
          },
          complete: () => {
            this.goToNextStep();
            this.hideSpinner();
            this.store.dispatch(new RefreshApplication());
          }
        })
      );
  }

  private goToNextStep(): void {
    this.store.dispatch([
      new SetStepAsCompleted(EnrollmentSteps.restaurantStatus),
      new GoToStep(EnrollmentSteps.website)
    ]);
  }

}
