<div class="rs-agreements-list w-100">
  <h3 class="rs-agreements-list__title font-weight-bold">{{ 'terms-and-agreement.agreements' | translate }}</h3>
  <button *ngFor="let document of documents"
          class="rs-agreements-list__item d-flex justify-content-between align-items-center w-100"
          [matTooltip]="document.name"
          [ngClass]="{ 'rs-active' : document.id === activeDocumentId }"
          (click)="onGoToAgreementClick(document.id)">
    <span class="rs-agreements-name">{{ document.name }}</span>
    <span class="rs-agreements-status d-flex justify-content-center align-items-center"
          [ngClass]="{ 'rs-complete' : document.viewed }">
      <mat-icon>done</mat-icon>
    </span>
  </button>
</div>
