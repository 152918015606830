<div
  class="rs-side-nav-item"
  (click)="goToNavigationItem()"
  [ngClass]="{
    'rs-side-nav-item_completed': step.completed,
    'rs-side-nav-item_active': step.active,
    'rs-side-nav-item_disabled': isStepDisabled
  }"
>
  <div class="rs-side-nav-item__top"></div>
  <div class="rs-side-nav-item__content">
    <div class="rs-side-nav-item__content-icon">
      <img class="rs-side-nav-item__content-icon_init" src="assets/img-new/spoon-icon-gray.png">
      <img class="rs-side-nav-item__content-icon_completed" src="assets/img-new/spoon-icon.png">
    </div>
    <div class="rs-side-nav-item__content-title">{{step.title}}</div>
  </div>
  <div class="rs-side-nav-item__bottom"></div>
</div>
