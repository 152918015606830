import { Injectable } from '@angular/core';

import { Action, State, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { Spinner } from 'src/onboarding/shared/classes/spinner.class';
import { EnrollmentSteps } from 'src/onboarding/shared/enums/enrollment-steps.enum';
import { GoToStep, RefreshApplication, SetStepAsCompleted } from '../../../layout/ngxs/layout.actions';
import { LayoutState } from '../../../layout/ngxs/layout.state';
import { SalesDistribution } from '../models/sales-distribution.model';
import { SalesDistributionService } from '../sales-distribution.service';
import { InitSalesDistribution, SaveSalesDistribution } from './sales-distribution.actions';

interface SalesDistributionStateModel {
  salesDistribution: SalesDistribution;
}

@State<SalesDistributionStateModel>({
  name: 'salesDistribution',
  defaults: {
    salesDistribution: null
  }
})
@Injectable()
export class SalesDistributionState extends Spinner {

  constructor(
    private salesDistributionService: SalesDistributionService,
    protected store: Store
  ) {
    super(store);
  }

  @Action(InitSalesDistribution)
  initSalesDistribution({patchState}: StateContext<SalesDistributionStateModel>, {businessEntity}: InitSalesDistribution) {
    const { cardPresentTransactions, internetTransactions, mailPhoneTransactions } = businessEntity.salesInformation;

    patchState({
      salesDistribution: {
        cardPresentTransactions: cardPresentTransactions ? cardPresentTransactions : null,
        internetTransactions: internetTransactions ? internetTransactions : null,
        mailPhoneTransactions: mailPhoneTransactions ? mailPhoneTransactions : null,
      }
    });
  }

  @Action(SaveSalesDistribution)
  saveSalesDistribution({patchState}: StateContext<SalesDistributionStateModel>, {salesDistribution}: SaveSalesDistribution) {
    this.showSpinner();

    return this.salesDistributionService.saveSalesDistribution(
      salesDistribution,
      this.store.selectSnapshot(LayoutState.getBusinessEntityId)
    )
      .pipe(
        tap({
          next: () => {
            patchState({
              salesDistribution
            });
          },
          complete: () => {
            this.hideSpinner();
            this.store.dispatch(new SetStepAsCompleted(EnrollmentSteps.salesDistribution));
            this.store.dispatch(new GoToStep(EnrollmentSteps.rockspoonPlan));
            this.store.dispatch(new RefreshApplication());
          }
        })
      );
  }

}
