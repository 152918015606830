import { NgModule } from '@angular/core';

import { SectionFooterModule } from 'src/onboarding/shared/components/section-footer/section-footer.module';
import { MaterialModule } from 'src/onboarding/shared/modules/material.module';
import { SharedModule } from 'src/onboarding/shared/modules/shared.module';
import { RestaurantNameComponent } from './component/restaurant-name.component';

@NgModule({
  imports: [
    SharedModule,
    MaterialModule,
    SectionFooterModule
  ],
  declarations: [
    RestaurantNameComponent
  ],
  exports: [
    RestaurantNameComponent
  ]
})
export class RestaurantNameModule {}
