import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  constructor(
    private translateService: TranslateService
  ) {}

  public async getTranslationAsync(path: string): Promise<string> {
    return this.translateService.get(path).toPromise();
  }

  public getTranslation(path: string): string {
    return this.translateService.instant(path);
  }

}
