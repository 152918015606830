import { BusinessEntityExtended } from 'src/onboarding/shared/models/business-entity.model';

export class SaveWebsiteUrl {
  static readonly type = '[Website Url] Save';
}

export class SetAnswerAboutWebsite {
  static readonly type = '[Answer About Website] Set';

  constructor(
    public doYouHaveWebsite: boolean,
    public websiteUrl: string
  ) {}
}

export class InitWebsiteUrl {
  static readonly type = '[Website Url] Init';

  constructor(
    public businessEntity: BusinessEntityExtended
  ) {}
}
