import { DEFAULT_COUNTRY } from 'src/onboarding/shared/constants/common';

export class Address {

  public address1: string;
  public address2: string;
  public city: string;
  public country: string;
  public state: string;
  public zipcode: string;

  constructor(addressFormData: Address) {
    this.address1 = addressFormData.address1;
    this.address2 = addressFormData.address2;
    this.city = addressFormData.city;
    this.country = DEFAULT_COUNTRY;
    this.state = `US-${addressFormData.state}`;
    this.zipcode = addressFormData.zipcode;
  }

}
