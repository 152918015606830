<div id="contracts" class="rs-document-signing__wrapper rs-enrollment-section">
  <div *ngIf="currentDocument$ | async" class="rs-document-signing d-flex flex-wrap rs-enrollment-section__content">
    <section class="rs-content d-flex w-100">
      <rs-document
        class="rs-document"
        [id]="activeDocumentId"
        [htmlContent]="(currentDocument$ | async).htmlContent"
        (scrolledToBottom)="documentHasViewed()">
      </rs-document>
      <div class="rs-agreements-list-component">
        <rs-agreements-list [documents]="(documents$ | async)"
                            [activeDocumentId]="activeDocumentId">
        </rs-agreements-list>
        <div class="rs-document-signing__hint">The document will only be considered as read once you scroll to the end of it.</div>
      </div>
    </section>
    <section class="rs-signing-checklist-component w-100">
      <rs-signing-checklist
        [canBeSigned]="(isAllDocumentsAreRead$ | async)"
        (changeSigningState)="changeSigningState($event)"
      ></rs-signing-checklist>
    </section>
    <section class="rs-actions">
      <button
        [disabled]="!isUserAgreedWithCheckList"
        mat-raised-button
        color="primary"
        class="rs-blue-button"
        (click)="signDocuments()">
        {{ 'buttons.agree-and-continue' | translate }}
      </button>
    </section>
  </div>  
</div>