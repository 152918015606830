import { BusinessEntityExtended } from 'src/onboarding/shared/models/business-entity.model';
import { BankingInfo } from '../models/banking-info.model';

export class SetBankingInformation {
  static readonly type = '[Banking Information] Set';

  constructor(
    public bankingInfo: BankingInfo
  ) {}
};

export class InitBankingInformation {
  static readonly type = '[Banking Information] Init';

  constructor(
    public businessEntity: BusinessEntityExtended
  ) {}
}
