import { Directive, HostListener, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDatepickerBase } from '@angular/material/datepicker/datepicker-base';

@Directive({
  selector: '[rsDatePickerValidationChecker]'
})
export class DatePickerValidationCheckerDirective {

  @Input() control: FormGroup;
  @Input() formControlName: string;
  @Input() picker: MatDatepickerBase<any, any>;

  @HostListener('blur')
  checkDatepickerValidBlur(): void {
    if (this.picker.opened) {
      this.control.controls[`${this.formControlName}`].setErrors(null);
    } else if (!this.control.controls[`${this.formControlName}`].value) {
      this.control.controls[`${this.formControlName}`].setErrors({required: true});
    }
  }

}
