import { BusinessEntityExtended } from 'src/onboarding/shared/models/business-entity.model';

export class GetPaymentPlans {
  static readonly type = '[Payment Plans] Get';
}

export class SelectPlan {
  static readonly type = '[Plan] Select';

  constructor(
    public rockspoonPlanId: string
  ) {}
}

export class InitPaymentPlan {
  static readonly type = '[Payment Plan] Init';

  constructor(
    public businessEntity: BusinessEntityExtended
  ) {}
}
