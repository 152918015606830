import { Component, OnInit } from '@angular/core';

import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import {
  InitSigning,
  SignDocuments,
  ViewDocument
} from '../../ngxs/document-signing.actions';
import { Document } from '../../models/document.model';
import { DocumentSigningState } from '../../ngxs/document-signing.state';
import { EnrollmentPage } from 'src/onboarding/shared/classes/enrollment-page.class';
import { EnrollmentSteps } from 'src/onboarding/shared/enums/enrollment-steps.enum';
import { Step } from 'src/onboarding/shared/models/step.model';

@Component({
  selector: 'rs-document-signing',
  templateUrl: './document-signing.component.html',
  styleUrls: ['./document-signing.component.scss']
})
export class DocumentSigningComponent extends EnrollmentPage implements OnInit {
  @Select(DocumentSigningState.currentDocument) currentDocument$!: Observable<Document>;
  @Select(DocumentSigningState.isLastDocument) isLast$!: Observable<Document[]>;
  @Select(DocumentSigningState.isAllDocumentsAreRead) isAllDocumentsAreRead$!: Observable<boolean>;
  @Select(state => state.documentSigning.documentsForView) documents$!: Observable<Document[]>;
  @Select(state => state.documentSigning.activeDocumentId) activeDocumentId$!: Observable<string>;
  @Select(state => state.layout.steps) steps$: Observable<Step[]>;

  public activeDocumentId: string = '';
  public isUserAgreedWithCheckList: boolean = false;
  public isTermsAndAgreementStepCompleted: boolean;

  constructor(protected readonly store: Store) {
    super(EnrollmentSteps.contracts, store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.dispatchActions();
    this.initSubscriptions();
  }

  public documentHasViewed(): void {
    this.store.dispatch([new ViewDocument(this.activeDocumentId)]);
  }

  public changeSigningState(isUserAgreedWithCheckList: boolean): void {
    this.isUserAgreedWithCheckList = isUserAgreedWithCheckList;
  }

  public signDocuments(): void {
    if (this.isUserAgreedWithCheckList) {
      this.store.dispatch(new SignDocuments());
    }
  }

  private dispatchActions(): void {
    this.store.dispatch(new InitSigning());
  }

  private initSubscriptions(): void {
    this.subscribeTo = this.activeDocumentId$
      .subscribe((activeDocumentId: string) => this.activeDocumentId = activeDocumentId);
    this.subscribeTo = this.steps$
      .subscribe((steps: Step[]) => {
        this.isTermsAndAgreementStepCompleted = steps.find(step => step.id === EnrollmentSteps.termsAndAgreement).completed;
      });
  }
}
