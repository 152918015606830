import { Component, Input, OnChanges } from '@angular/core';

import { BusinessEntityExtended } from 'src/onboarding/shared/models/business-entity.model';

@Component({
  selector: 'rs-review-restaurant-name',
  templateUrl: 'review-restaurant-name.component.html',
  styleUrls: ['review-restaurant-name.component.scss']
})
export class ReviewRestaurantNameComponent implements OnChanges {

  @Input() businessEntity: BusinessEntityExtended;

  public restaurantName: string;

  public ngOnChanges(): void {
    this.restaurantName = this.businessEntity?.name;
  }

}
