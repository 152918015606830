import { Directive, HostListener } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { Overlay } from '@angular/cdk/overlay';

import { LeaveOnboardingComponent } from 'src/onboarding/modules/enrollment/layout/components/leave-onboarding/leave-onboarding.component';

@Directive({
  selector: '[rsOpenLeaveOnboarding]'
})
export class OpenLeaveOnboardingDirective {

  constructor(
    private dialog: MatDialog,
    private overlay: Overlay
  ) {}

  @HostListener('click')
  openLeaveOnboarding(): void {
    this.dialog.open(LeaveOnboardingComponent, {
      width: '787px',
      scrollStrategy: this.overlay.scrollStrategies.noop()
    });
  }

}
