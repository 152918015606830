import { Component, OnInit } from '@angular/core';

import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { EnrollmentPage } from 'src/onboarding/shared/classes/enrollment-page.class';
import { SERVICE_TYPES } from 'src/onboarding/shared/constants/common';
import { ENROLLMENT_SECTIONS } from 'src/onboarding/shared/constants/enrollment-sections.constants';
import { EnrollmentSteps } from 'src/onboarding/shared/enums/enrollment-steps.enum';
import { GoToStep } from '../../../layout/ngxs/layout.actions';
import { SetServiceType } from '../ngxs/service-type.actions';

@Component({
  selector: 'rs-service-type',
  templateUrl: 'service-type.component.html',
  styleUrls: ['service-type.component.scss']
})
export class ServiceTypeComponent extends EnrollmentPage implements OnInit {

  @Select(state => state.serviceType.serviceType) serviceType$: Observable<string>;

  public serviceTypes = Object.values(SERVICE_TYPES);
  public warningVisibility: boolean;
  private selectedServiceType: string = null;

  constructor(
    protected store: Store
  ) {
    super(ENROLLMENT_SECTIONS.SERVICE_TYPE, store);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.initSubscriptions();
  }

  private initSubscriptions(): void {
    this.subscribeTo = this.serviceType$
      .subscribe( serviceType => this.selectedServiceType = serviceType);
  }

  public selectServiceType(serviceType: string): void {
    this.warningVisibility = serviceType !== SERVICE_TYPES.table;
    this.store.dispatch(new SetServiceType(serviceType));
  }

  public isServiceTypeSelected(serviceType: string): boolean {
    return serviceType === this.selectedServiceType;
  }

  public goToNextStep(): void {
    this.store.dispatch(new GoToStep(EnrollmentSteps.salesDistribution));
  }

}
