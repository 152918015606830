import { environment } from 'src/environments/environment';
import { RestaurantStatuses } from '../enums/restaurant-statuses.enum';
import { KeyValue } from '../models/key-value.model';
import { RestaurantStatus } from '../models/restaurant-status.model';

export const SCROLLED_DOWN_VALUE = 98;

export const DEFAULT_COUNTRY = 'USA';

export const OTHER = 'other';

export const DEFAULT_TIMESTAMP_VALUE = -62135596800000;

export const COMMON_CONSTANTS = {
  API_URL: environment.apiUrl,
  DEFAULT_LANGUAGE: 'en'
};

export const RESTAURANT_STATUSES: RestaurantStatus[] = [
  {
    icon: 'operational',
    title: 'Fully Operational',
    value: RestaurantStatuses.operational
  },
  {
    icon: 'pre-operational',
    title: 'Pre-operational',
    value: RestaurantStatuses.preOperational
  },
  {
    icon: 'heart-broken',
    title: 'Temporarily closed',
    value: RestaurantStatuses.temporarilyClosed
  }
];

export const POS_SYSTEMS = [
  {
    title: 'Aloha/NCR',
    value: 'Aloha/NCR'
  },
  {
    title: 'Micros/Oracle',
    value: 'Micros/Oracle'
  },
  {
    title: 'Touch Bistro',
    value: 'Touch Bistro'
  },
  {
    title: 'Toast',
    value: 'Toast'
  },
  {
    title: 'Square',
    value: 'Square'
  },
  {
    title: 'Revel',
    value: 'Revel'
  },
  {
    title: 'Squirrel',
    value: 'Squirrel'
  },
  {
    title: 'Clover',
    value: 'Clover'
  },
  {
    title: 'ShopKeep',
    value: 'ShopKeep'
  },
  {
    title: 'None',
    value: 'None'
  }
];

export const ACCOUNT_TYPES = [
  'Checking',
  'Savings'
];

export const OWNERSHIP_STRUCTURES = [
  {
    title: 'Individual/Sole Proprietor or Single-member LLC',
    value: 'individual'
  },
  {
    title: 'C Corporation',
    value: 'cCorp'
  },
  {
    title: 'S Corporation',
    value: 'sCorp'
  },
  {
    title: 'Partnership',
    value: 'partnership'
  },
  {
    title: 'Trust/Estate',
    value: 'trustEstate'
  },
  {
    title: 'Limited Liability Company',
    value: 'llc'
  }
];

export enum SERVICE_TYPES {
  table ='Table Service',
  counter = 'Counter Service',
  both = 'Both'
};

export const JOB_POSITIONS = [
  'Owner',
  'Manager',
  'Chef',
  'Office Administrator',
  'Server/Bartender'
];



export const DOCUMENT_TYPES: KeyValue[] = [
  {
    key: 'State Driver’s License',
    value: 'DriversLicence'
  },
  {
    key: 'USA Military ID',
    value: 'UsaMilitaryId'
  },
  {
    key: 'USA State ID',
    value: 'UsaStateId'
  },
  {
    key: 'USA Passport',
    value: 'PassportUsa'
  },
  {
    key: 'Foreign Passport',
    value: 'PassportForeign'
  },
  {
    key: 'USA Green Card',
    value: 'UsaResidentAlienId'
  },
  {
    key: 'Student ID',
    value: 'StudentId'
  },
  {
    key: 'Tribal ID',
    value: 'TribalId'
  },
  {
    key: 'Canadian Driver`s License',
    value: 'DlCanada'
  },
  {
    key: 'Mexican Driver`s License',
    value: 'DlMexico'
  },
  {
    key: 'Other Foreign ID',
    value: 'OtherIdForeign'
  }
];

export const OWNER_TYPES: string[] = [
  'Individual',
  'Company'
];

export const HEADERS = {
  ACCESS_TOKEN: 'access_token'
};

export const NOTIFICATION_TYPES = {
  DEFAULT: 'default',
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error'
};

export const DIALOG_SIZES = {
  MIDDLE: '1000px',
  SMALL: '700px'
};

export const MAX_INPUT_LENGTH: number = 32;
