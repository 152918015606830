<div class="rs-website rs-enrollment-section" id="website">
  <div class="rs-enrollment-section__content">
    <div class="rs-step-title">{{'enrollment-step-titles.website' | translate}}</div>
    <div class="rs-website__controls">
      <rs-accept-input (accepted)="reactOnAcceptWebsiteUrl()" [rsFormControl]="urlControl" placeholder="Enter URL"></rs-accept-input>
      <rs-icon-button icon="sentiment_dissatisfied" [selected]="doNotHaveWebsite()" (click)="reactOnDoNotHaveWebsite()">{{'website.i-do-not-have' | translate}}</rs-icon-button>
    </div>
    <rs-sad-message *ngIf="doNotHaveWebsite()"></rs-sad-message>
    <rs-great-message *ngIf="userHasAcceptedWebsite()"></rs-great-message>
    <rs-section-footer *ngIf="isNextButtonVisible()" (accepted)="next()" buttonText="{{'buttons.save' | translate}}"></rs-section-footer>
  </div>
</div>
