import { NgModule } from '@angular/core';

import { SectionFooterModule } from 'src/onboarding/shared/components/section-footer/section-footer.module';
import { MaterialModule } from 'src/onboarding/shared/modules/material.module';
import { SharedModule } from 'src/onboarding/shared/modules/shared.module';
import { PipesModule } from 'src/onboarding/shared/pipes/pipes.module';
import { WelcomeComponent } from './component/welcome.component';

@NgModule({
  imports: [
    SharedModule,
    MaterialModule,
    SectionFooterModule,
    PipesModule
  ],
  declarations: [
    WelcomeComponent
  ],
  exports: [
    WelcomeComponent
  ]
})
export class WelcomeModule {}
