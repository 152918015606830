<div class="rs-restaurant-name rs-enrollment-section" id="restaurantName">
  <div class="rs-enrollment-section__content">
    <div class="rs-restaurant-name__title rs-step-title">{{'restaurant-name.title' | translate}}</div>
    <div class="rs-restaurant-name__controls rs-form-control-outline" [formGroup]="restaurantNameForm">
      <mat-form-field appearance="outline">
        <mat-label>{{'placeholders.restaurant-name' | translate}}</mat-label>
        <input formControlName="name" type="text" matInput>
        <mat-error *ngIf="restaurantNameForm.get('name').hasError('required')">{{ "placeholders.required" | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{'placeholders.job-position' | translate}}</mat-label>
        <mat-select formControlName="jobDescription">
          <mat-option *ngFor="let jobPosition of jobPositions" value="{{ jobPosition }}">{{ jobPosition }}</mat-option>
        </mat-select>
        <mat-error *ngIf="restaurantNameForm.get('jobDescription').hasError('required')">{{ "placeholders.required" | translate }}</mat-error>
      </mat-form-field>
    </div>
    <rs-section-footer buttonText="{{'buttons.save' | translate}}" (accepted)="goToNext()" [disabled]="restaurantNameForm.invalid"></rs-section-footer>
  </div>
</div>
