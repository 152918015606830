import { Component, Input } from '@angular/core';

import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { Step } from 'src/onboarding/shared/models/step.model';
import { SetRequestSignerVisibility, YesIAm } from '../../ngxs/terms-and-agreement.actions';

@Component({
  selector: 'rs-are-you-signer',
  templateUrl: 'are-you-signer.component.html',
  styleUrls: ['are-you-signer.component.scss']
})
export class AreYouSignerComponent {

  @Select(state => state.layout.steps) steps$: Observable<Step[]>;

  @Input() wasAnotherPersonInvited: boolean;
  @Input() amIInvitedPerson: boolean;

  public iAmAuthorized: boolean;

  constructor(
    private store: Store
  ) {}

  public reactOnAnswer(answer: boolean): void {
    this.iAmAuthorized = answer;

    if (answer && !this.amIInvitedPerson) {
      this.store.dispatch(new YesIAm());
    } else
    if (!this.wasAnotherPersonInvited) {
      this.store.dispatch(new SetRequestSignerVisibility(true));
    }
  }

}

