<div class="rs-control-with-mask">
  <mat-form-field appearance="outline">
    <mat-label>{{placeholder | translate}}*</mat-label>
    <input (focus)="onFocus()" [formControl]="rsFormControl" matInput [mask]="mask" [showMaskTyped]="isMaskVisible">
    <mat-error *ngIf="rsFormControl.invalid && rsFormControl.touched">
      {{ "placeholders.required" | translate }}
    </mat-error>
    <ng-content></ng-content>
  </mat-form-field>
</div>
