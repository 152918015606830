import { NgModule } from '@angular/core';

import { MaterialModule } from 'src/onboarding/shared/modules/material.module';
import { SharedModule } from 'src/onboarding/shared/modules/shared.module';
import { PopupModule } from '../popup.module';
import { ConfirmationPopupComponent } from './confirmation.component';
import { ConfirmationPopupDirective } from './confirmation.directive';

@NgModule({
  imports: [
    SharedModule,
    MaterialModule,
    PopupModule
  ],
  declarations: [
    ConfirmationPopupComponent,
    ConfirmationPopupDirective
  ],
  exports: [
    ConfirmationPopupComponent,
    ConfirmationPopupDirective
  ]
})
export class ConfirmationModule {}
