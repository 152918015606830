<div class="rs-invited-list" *ngIf="isContentVisible()">
  <!-- <div class="rs-invited-list__item-wrapper" *ngFor="let invitation of invitations"> -->
  <div class="rs-invited-list__title">{{'terms-and-agreement.signature-request-sent' | translate}} {{activeInvitation.inviteName}}!</div>
    <div class="rs-invited-list__item">
      <div class="rs-invited-list__item-title">{{activeInvitation.inviteName}}</div>
      <div class="rs-invited-list__item-row">
        <div class="rs-invited-list__item-row-key">{{'placeholders.email' | translate}}</div>
        <div class="rs-invited-list__item-row-value">{{activeInvitation.email}}</div>
      </div>
      <div class="rs-invited-list__item-row" *ngIf="activeInvitation.phone">
        <div class="rs-invited-list__item-row-key">{{'placeholders.mobile-number' | translate}}</div>
        <div class="rs-invited-list__item-row-value">{{activeInvitation.phone}}</div>
      </div>
      <div class="rs-invited-list__item-status">
        <div class="rs-invited-list__item-status-sent rs-invited-list__item-status_completed">
          <div class="rs-invited-list__item-status-circle"></div>
          <div class="rs-invited-list__item-status-line"></div>
          <div class="rs-invited-list__item-status-value">{{'terms-and-agreement.sent' | translate}}</div>
          <div class="rs-invited-list__item-status-date">{{getDate(activeInvitation.createdAt)}}</div>
        </div>
        <div *ngIf="wasInviteResent(activeInvitation)" class="rs-invited-list__item-status-resent rs-invited-list__item-status_completed">
          <div class="rs-invited-list__item-status-line"></div>
          <div class="rs-invited-list__item-status-value">{{'terms-and-agreement.re-sent' | translate}}</div>
          <div class="rs-invited-list__item-status-date">{{getDate(activeInvitation.lastInviteSent)}}</div>
        </div>
        <div class="rs-invited-list__item-status-signed">
          <div class="rs-invited-list__item-status-circle"></div>
          <div class="rs-invited-list__item-status-line"></div>
          <div class="rs-invited-list__item-status-value">{{'terms-and-agreement.signed' | translate}}</div>
          <div class="rs-invited-list__item-status-date"></div>
        </div>
      </div>
    </div>
    <rs-section-footer
      buttonText="{{'terms-and-agreement.resend-signature' | translate}}"
      (accepted)="resend(activeInvitation)"
      cancelButtonText="Invite Another Person"
      (canceled)="inviteAnotherPerson()"
    ></rs-section-footer>
  <!-- </div> -->
</div>
