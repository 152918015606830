import { Injectable } from '@angular/core';

import { Action, State, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { Spinner } from 'src/onboarding/shared/classes/spinner.class';
import { EnrollmentSteps } from 'src/onboarding/shared/enums/enrollment-steps.enum';
import { GoToStep, RefreshApplication, SetStepAsCompleted } from '../../../layout/ngxs/layout.actions';
import { LayoutState } from '../../../layout/ngxs/layout.state';
import { WebsiteService } from '../website.service';
import { InitWebsiteUrl, SaveWebsiteUrl, SetAnswerAboutWebsite } from './website.actions';

interface WebsiteStateModel {
  doYouHaveWebsite: boolean;
  websiteUrl: string;
}

@State<WebsiteStateModel>({
  name: 'websiteUrl',
  defaults: {
    doYouHaveWebsite: null,
    websiteUrl: ""
  }
})
@Injectable()
export class WebsiteUrlState extends Spinner {

  constructor(
    private websiteService: WebsiteService,
    protected store: Store
  ) {
    super(store);
  }

  @Action(InitWebsiteUrl)
  initWebsiteUrl({patchState}: StateContext<WebsiteStateModel>, {businessEntity}: InitWebsiteUrl) {
    patchState({
      websiteUrl: businessEntity.url,
      doYouHaveWebsite: businessEntity.url ? true : businessEntity.onboardingSteps.website ? false : null
    });
  }

  @Action(SetAnswerAboutWebsite)
  setAnswerAboutWebsite({patchState}: StateContext<WebsiteStateModel>, {doYouHaveWebsite, websiteUrl}: SetAnswerAboutWebsite) {
    patchState({
      doYouHaveWebsite,
      websiteUrl
    });
  }

  @Action(SaveWebsiteUrl)
  saveWebsiteUrl({getState}: StateContext<WebsiteStateModel>, {}: SaveWebsiteUrl) {
    this.showSpinner();

    return this.websiteService.saveWebsiteUrl(
      getState().websiteUrl,
      this.store.selectSnapshot(LayoutState.getBusinessEntityId)
    )
      .pipe(
        tap({
          complete: () => {
            this.hideSpinner();
            this.goToNextStep();
            this.store.dispatch(new RefreshApplication());
          }
        })
      );
  }

  private goToNextStep(): void {
    this.store.dispatch(new SetStepAsCompleted(EnrollmentSteps.website));
    this.store.dispatch(new GoToStep(EnrollmentSteps.address));
  }

}
