import { EnrollmentSteps } from '../enums/enrollment-steps.enum';
import { Step } from '../models/step.model';

const enrollmentFlowSteps: EnrollmentSteps[] = [
  EnrollmentSteps.restaurantName,
  EnrollmentSteps.restaurantStatus,
  EnrollmentSteps.website,
  EnrollmentSteps.address,
  EnrollmentSteps.serviceType,
  EnrollmentSteps.salesDistribution,
  EnrollmentSteps.rockspoonPlan,
  EnrollmentSteps.ownershipStructure,
  EnrollmentSteps.companyInformation,
  EnrollmentSteps.ownersInformation,
  EnrollmentSteps.bankAccount
];

export const STEPS: Step[] = [
  {
    completed: false,
    active: true,
    title: 'Business Name',
    id: EnrollmentSteps.restaurantName
  },
  {
    completed: false,
    active: false,
    title: 'Current Status',
    id: EnrollmentSteps.restaurantStatus,
    dependsOn: [
      EnrollmentSteps.restaurantName
    ]
  },
  {
    completed: false,
    active: false,
    title: 'Website',
    id: EnrollmentSteps.website,
    dependsOn: [
      EnrollmentSteps.restaurantName
    ]
  },
  {
    completed: false,
    active: false,
    title: 'Address',
    id: EnrollmentSteps.address,
    dependsOn: [
      EnrollmentSteps.restaurantName
    ]
  },
  {
    completed: false,
    active: false,
    title: 'Service Type',
    id: EnrollmentSteps.serviceType,
    dependsOn: [
      EnrollmentSteps.restaurantName
    ]
  },
  {
    completed: false,
    active: false,
    title: 'Sales Distribution',
    id: EnrollmentSteps.salesDistribution,
    dependsOn: [
      EnrollmentSteps.restaurantName
    ]
  },
  {
    completed: false,
    active: false,
    title: 'RockSpoon Plan',
    id: EnrollmentSteps.rockspoonPlan,
    dependsOn: [
      EnrollmentSteps.restaurantName
    ]
  },
  {
    completed: false,
    active: false,
    title: 'Ownership Structure',
    id: EnrollmentSteps.ownershipStructure,
    dependsOn: [
      EnrollmentSteps.restaurantName
    ]
  },
  {
    completed: false,
    active: false,
    title: 'Company Information',
    id: EnrollmentSteps.companyInformation,
    dependsOn: [
      EnrollmentSteps.restaurantName
    ]
  },
  {
    completed: false,
    active: false,
    title: 'Owner Information',
    id: EnrollmentSteps.ownersInformation,
    dependsOn: [
      EnrollmentSteps.restaurantName
    ]
  },
  {
    completed: false,
    active: false,
    title: 'Banking',
    id: EnrollmentSteps.bankAccount,
    dependsOn: [
      EnrollmentSteps.restaurantName
    ]
  },
  {
    completed: false,
    active: false,
    title: 'Review Information',
    id: EnrollmentSteps.reviewInformation,
    dependsOn: [...enrollmentFlowSteps]
  },
  {
    completed: false,
    active: false,
    title: 'Control Person',
    id: EnrollmentSteps.termsAndAgreement,
    dependsOn: [
      ...enrollmentFlowSteps,
      EnrollmentSteps.reviewInformation
    ]
  },
  {
    completed: false,
    active: false,
    title: 'Terms & Conditions',
    id: EnrollmentSteps.contracts,
    dependsOn: [
      ...enrollmentFlowSteps,
      EnrollmentSteps.reviewInformation,
      EnrollmentSteps.termsAndAgreement
    ],
    onlyForLeads: true
  }
];
