import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { EnrollmentPage } from 'src/onboarding/shared/classes/enrollment-page.class';
import { JOB_POSITIONS } from 'src/onboarding/shared/constants/common';
import { EnrollmentSteps } from 'src/onboarding/shared/enums/enrollment-steps.enum';
import { RestaurantName } from '../models/restaurant-name.model';
import { SetRestaurantName } from '../ngxs/restaurant-name.actions';

@Component({
  selector: 'rs-restaurant-name',
  templateUrl: 'restaurant-name.component.html',
  styleUrls: ['restaurant-name.component.scss', 'restaurant-name.component.responsive.scss']
})
export class RestaurantNameComponent extends EnrollmentPage implements OnInit {

  @Select(state => state.restaurantName) restaurantName$: Observable<RestaurantName>;

  public restaurantNameForm: FormGroup;
  public jobPositions = JOB_POSITIONS;

  private restaurantName: RestaurantName;

  constructor(
    protected store: Store,
    private formBuilder: FormBuilder
  ) {
    super(EnrollmentSteps.restaurantName, store);
  }

  ngOnInit() {
    super.ngOnInit();
    this.initSubscriptions();
  }

  public goToNext(): void {
    this.store.dispatch(new SetRestaurantName(this.restaurantNameForm.getRawValue()));
  }

  private initSubscriptions(): void {
    this.subscribeTo = this.restaurantName$
      .subscribe(restaurantName => {
        this.restaurantName = restaurantName;
        this.initForm();
      });
  }

  private initForm(): void {
    this.restaurantNameForm = this.formBuilder.group({
      name: [this.restaurantName.restaurantName ? this.restaurantName.restaurantName : '', Validators.required],
      jobDescription: [this.restaurantName.jobDescription ? this.restaurantName.jobDescription : '', Validators.required]
    });
  }

}
