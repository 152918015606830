import moment from 'moment';

import { Address } from 'src/onboarding/modules/enrollment/flow/address/models/address.model';
import { getValidProneNumber } from '../helpers';
import { DocumentDetails } from './document-details.model';
import { IndividualData } from './forms/individual.model';

export class IndividualOwner {
  public dateOfBirth: string;
  public documentDetails: DocumentDetails;
  public email: string;
  public firstName: string;
  public lastName: string;
  public legalAddress: Address;
  public nationality: string;
  public phoneNumber: string;
  public residingCountry: string;
  public secondaryNationality: string;
  public share: number;
  public socialSecurityNumber: string;
  public socialSecurityType: string;

  constructor(individualData: IndividualData, individualAddress: Address) {
    this.dateOfBirth = moment(individualData.birthDate).format('YYYY-MM-DD');
    this.documentDetails = new DocumentDetails(individualData);
    this.email = individualData.email;
    this.firstName = individualData.firstName;
    this.lastName = individualData.lastName;
    this.legalAddress = new Address(individualAddress);
    this.nationality = individualData.nationality;
    this.phoneNumber = getValidProneNumber(individualData.phone.number);
    this.residingCountry = individualData.residingCountry;
    this.secondaryNationality = individualData.secondaryNationality;
    this.share = Number.parseFloat(individualData.ownership);
    this.socialSecurityNumber = individualData.socialSecurityNumber;
    this.socialSecurityType = 'temp';
  }
}
