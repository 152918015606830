import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ENDPOINTS } from 'src/onboarding/shared/constants/endpoints';
import { ApiService } from 'src/onboarding/shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceTypeService {

  constructor(
    private apiService: ApiService
  ) {}

  public setBusinessType(serviceModel: string, businessEntityId: string | number): Observable<void> {
    return this.apiService.put(ENDPOINTS.SERVICE_TYPE(businessEntityId), {serviceModel});
  }

}
