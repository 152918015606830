import { NgModule } from '@angular/core';

import { SharedModule } from 'src/onboarding/shared/modules/shared.module';
import { SuccessComponent } from './success.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    SuccessComponent
  ]
})
export class SuccessModule {}
