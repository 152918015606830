<div class="rs-address-form rs-form-control-outline" [formGroup]="inputForm">
  <div class="rs-address-form__wrapper">
    <div class="rs-row full-width" *ngIf="withCountry">
      <mat-form-field appearance="outline">
        <mat-label>{{'placeholders.country' | translate}}*</mat-label>
        <mat-select formControlName="country" [disabled]="true" [value]="defaultCountry">
          <mat-option value="USA">United States</mat-option>
        </mat-select>
        <mat-error *ngIf="inputForm.get('country').hasError('required')">
          {{ "placeholders.required" | translate }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="rs-address-form__row">
      <rs-address-control (selectedAutocompletedValue)="reactOnSelectAddressFromAutocomplete($event)" [addressControl]="inputForm.get('address1')"></rs-address-control>
    </div>
    <div class="rs-address-form__row">
      <mat-form-field appearance="outline">
        <mat-label>{{'placeholders.address-2' | translate}}</mat-label>
        <input formControlName="address2" placeholder="{{'placeholders.address-2' | translate}}*" type="text" matInput maxlength="32">
        <mat-error *ngIf="inputForm.get('address2').hasError('maxlength')">{{ "placeholders.max-input-length" | translate }}</mat-error>
      </mat-form-field>
    </div>
    <div class="rs-address-form__row">
      <mat-form-field appearance="outline">
        <mat-label>{{'placeholders.city' | translate}}*</mat-label>
        <input formControlName="city" placeholder="{{'placeholders.city' | translate}}*" type="text" matInput maxlength="32">
        <mat-error *ngIf="inputForm.get('city').hasError('required')">{{ "placeholders.required" | translate }}</mat-error>
        <mat-error *ngIf="inputForm.get('city').hasError('maxlength')">{{ "placeholders.max-input-length" | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{'placeholders.state' | translate}}*</mat-label>
        <mat-select formControlName="state">
          <mat-option *ngFor="let state of states" [value]="state.code">{{state.name}}</mat-option>
        </mat-select>
        <mat-error *ngIf="inputForm.get('state').hasError('required')">{{ "placeholders.required" | translate }}</mat-error>
      </mat-form-field>
    </div>
    <div class="rs-address-form__row">
      <div>
        <rs-control-with-mask [rsFormControl]="inputForm.controls.zipcode" [placeholder]="'placeholders.zip-code'" mask="00000"></rs-control-with-mask>
      </div>
      <div></div>
    </div>
  </div>
</div>
