import { Injectable } from '@angular/core';

import { Action, State, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { Spinner } from 'src/onboarding/shared/classes/spinner.class';
import { EnrollmentSteps } from 'src/onboarding/shared/enums/enrollment-steps.enum';
import { GoToStep, RefreshApplication, SetStepAsCompleted } from '../../../layout/ngxs/layout.actions';
import { LayoutState } from '../../../layout/ngxs/layout.state';
import { AddressService } from '../address.service';
import { Address } from '../models/address.model';
import { InitRestaurantAddress, SaveRestaurantAddress } from './address.actions';

export interface AddressStateModel {
  legalAddress: Address;
  businessAddress: Address;
}

@State<AddressStateModel>({
  name: 'address',
  defaults: {
    legalAddress: null,
    businessAddress: null
  }
})
@Injectable()
export class AddressState extends Spinner {

  constructor(
    private addressService: AddressService,
    protected store: Store
  ) {
    super(store);
  }

  @Action(InitRestaurantAddress)
  initRestaurantAddress({patchState}: StateContext<AddressStateModel>, {businessEntity}: InitRestaurantAddress) {
    patchState({
      businessAddress: businessEntity.businessInformation.businessAddress,
      legalAddress: businessEntity.businessInformation.legalAddress
    });
  }

  @Action(SaveRestaurantAddress)
  saveRestaurantAddress({patchState}: StateContext<AddressStateModel>, {businessAddress, legalAddress}: SaveRestaurantAddress) {
    this.showSpinner();

    return this.addressService.saveRestaurantAddress(
      new Address(legalAddress),
      new Address(businessAddress),
      this.store.selectSnapshot(LayoutState.getBusinessEntityId)
    )
      .pipe(
        tap({
          next: () => {
            patchState({
              legalAddress,
              businessAddress
            });
          },
          complete: () => {
            this.store.dispatch(new SetStepAsCompleted(EnrollmentSteps.address));
            this.store.dispatch(new GoToStep(EnrollmentSteps.serviceType));
            this.hideSpinner();
            this.store.dispatch(new RefreshApplication());
          }
        })
      );
  }

}
