import { NgModule } from '@angular/core';
import { DirectivesModule } from '../../directives/directives.module';

import { MaterialModule } from '../../modules/material.module';
import { SharedModule } from '../../modules/shared.module';
import { AddressFormModule } from '../address-form/address-form.module';
import { ControlWithMaskModule } from '../control-with-mask/control-with-mask.module';
import { PercentControlModule } from '../percent-control/percent-control.module';
import { PhoneControlModule } from '../phone-control/phone-control.module';
import { IndividualFormComponent } from './individual-form.component';

@NgModule({
  imports: [
    SharedModule,
    MaterialModule,
    PhoneControlModule,
    DirectivesModule,
    PercentControlModule,
    ControlWithMaskModule,
    AddressFormModule
  ],
  declarations: [
    IndividualFormComponent
  ],
  exports: [
    IndividualFormComponent
  ]
})
export class IndividualFormModule {}
