import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'rs-percent-control',
  templateUrl: 'percent-control.component.html',
  styleUrls: ['percent-control.component.scss']
})
export class PercentControlComponent implements OnInit {

  @Input() placeholder: string;
  @Input() rsFormControl: FormControl;
  @Input() cantBeZero: boolean = false;

  public ngOnInit(): void {
    setTimeout(() => {
      this.rsFormControl.reset(this.rsFormControl.value);
    });

    this.rsFormControl.valueChanges.subscribe(value => {
      if (this.cantBeZero && !value) {
        this.rsFormControl.setErrors({required: true});
      }
    });
  }

}
