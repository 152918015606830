import { BusinessEntityExtended } from 'src/onboarding/shared/models/business-entity.model';
import { CompanyInformation } from '../models/company-information.model';

export class SetCompanyInformation {
  static readonly type = '[Company Information] Set';

  constructor(
    public companyInformation: CompanyInformation
  ) {}
}

export class InitCompanyInformation {
  static readonly type = '[Company Information] Init';

  constructor(
    public businessEntity: BusinessEntityExtended
  ) {}
}
