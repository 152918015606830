export class GoToStep {
  static readonly type = '[Step] Go To';

  constructor(
    public stepId: string
  ) {}
}

export class SetStepAsActive {
  static readonly type = '[Step] Set As Active';

  constructor(
    public stepId: string
  ) {}
}

export class SetStepAsCompleted {
  static readonly type = '[Step] Set As Completed';

  constructor(
    public stepId: string
  ) {}
}

export class SetStepAsUncompleted {
  static readonly type = '[Step] Set As Uncompleted'

  constructor(
    public stepId: string
  ) {}
}

export class InitSteps {
  static readonly type = '[Steps] Init';
}

export class FinishFlowIfStepsAreCompleted {
  static readonly type = '[Flow] Finish';
}

export class SetSpinnerVisibility {
  static readonly type = '[Spinner Visibility] Set';

  constructor(
    public spinnerVisibility: boolean
  ) {}
}

export class RefreshSession {
  static readonly type = '[Session] Refresh';
}

export class InitApplication {
  static readonly type = '[Application] Init';
}

export class SetBusinessEntityId {
  static readonly type = '[Business Entity ID] Set';

  constructor(
    public id: string
  ) {}
}

export class RefreshApplication {
  static readonly type = '[Application] Refresh'
}

export class CheckNecessaryStepsAsCompleted {
  static readonly type = '[Application] Necessary steps'
}
