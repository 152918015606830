import { Component, Input } from '@angular/core';

import { Store } from '@ngxs/store';

import { GoToStep } from 'src/onboarding/modules/enrollment/layout/ngxs/layout.actions';
import { LayoutState } from 'src/onboarding/modules/enrollment/layout/ngxs/layout.state';
import { checkIfStepAvailable } from 'src/onboarding/shared/helpers';
import { Step } from 'src/onboarding/shared/models/step.model';

@Component({
  selector: 'rs-side-nav-item',
  templateUrl: 'side-nav-item.component.html',
  styleUrls: ['side-nav-item.component.scss', 'side-nav-item.component.responsive.scss']
})
export class SideNavItemComponent {

  @Input() step: Step;
  @Input() isReview: boolean;
  @Input() set steps(steps: Step[]) {
    this.isStepDisabled = !checkIfStepAvailable(
      steps,
      this.step.id,
      this.store.selectSnapshot(LayoutState.getIsUserLeader)
    );
  }

  public isStepDisabled: boolean;

  constructor(
    private store: Store
  ) {}

  public goToNavigationItem() {
    if (!this.isStepDisabled) {
      this.store.dispatch(new GoToStep(this.step.id));
    }
  }

}
