import { Component, Input } from '@angular/core';

import { RockspoonFee, RockspoonPlan } from 'src/onboarding/modules/enrollment/flow/rockspoon-plan/models/rockspoon-plan.model';

@Component({
  selector: 'rs-plan-item',
  templateUrl: 'plan-item.component.html',
  styleUrls: ['plan-item.component.scss']
})
export class PlanItemComponent {

  @Input() plan: RockspoonPlan;

  public getPercentage(fee: RockspoonFee): number {
    return Number((fee.percentage * 100).toPrecision(2));
  }

  public getMoney(fee: RockspoonFee): number {
    return Number(`0.${fee.fixed}`);
  }

}
