import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ENDPOINTS } from 'src/onboarding/shared/constants/endpoints';
import { ApiService } from 'src/onboarding/shared/services/api.service';
import { BankingInfo } from './models/banking-info.model';

@Injectable({
  providedIn: 'root'
})
export class BankAccountService {

  constructor(
    private apiService: ApiService
  ) {}

  public saveBankingInformation(bankingInformation: BankingInfo, businessEntityId: string): Observable<void> {
    return this.apiService.put(ENDPOINTS.BANKING_INFORMATION(businessEntityId), bankingInformation);
  }

}
