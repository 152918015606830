import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import nationalities from '../../../../assets/files/nationalities.json';
import countries from '../../../../assets/files/countries.json';
import { Country, State } from '../../models/country.model';
import { copy, getStates } from '../../helpers';
import { mustBeInFuture, mustBeInPast } from '../../constants/datepicker-filters';
import { Unsubscribe } from '../../classes/unsubscribe.class';
import { RestaurantName } from 'src/onboarding/modules/enrollment/flow/restaurant-name/models/restaurant-name.model';
import { DOCUMENT_TYPES } from '../../constants/common';
import { KeyValue } from 'src/onboarding/shared/models/key-value.model';

@Component({
  selector: 'rs-individual-form',
  templateUrl: 'individual-form.component.html',
  styleUrls: ['individual-form.component.scss']
})
export class IndividualFormComponent extends Unsubscribe implements OnInit {

  @Select(state => state.restaurantName) restaurantName$: Observable<RestaurantName>;

  @Input() individualForm: FormGroup;
  @Input() addressForm: FormGroup;

  public mustBeInPast = mustBeInPast;
  public mustBeInFuture = mustBeInFuture;
  public nationalities: string[] = copy(nationalities);
  public countries: Country[] = copy(countries);
  public restaurantName: string = null;
  public states: State[];
  public documentTypes: KeyValue[] = copy(DOCUMENT_TYPES);
  public isControlPerson: boolean;

  public ngOnInit(): void {
    this.states = getStates();
    this.initSubscriptions();
    this.checkFutureTime = this.checkFutureTime.bind(this);
    this.getIsControlPerson();
  }

  public checkFutureTime() {
    if (this.individualForm.value.expireDate) {
      this.individualForm.controls.expireDate.setErrors(null);
    }

    return mustBeInFuture;
  }

  private initSubscriptions(): void {
    this.subscribeTo = this.restaurantName$
      .subscribe(({restaurantName}) => this.restaurantName = restaurantName);
  }

  private getIsControlPerson(): void {
    this.isControlPerson = !!this.individualForm.controls.ownership;
  }

}
