import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { NgxsModule } from '@ngxs/store';
import { LayoutModule as CdkLayoutModule } from '@angular/cdk/layout';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NotifierModule, NotifierOptions } from 'angular-notifier';

import { COMMON_CONSTANTS } from '../constants/common';
import { HttpLoaderFactory } from '../helpers';
import { OnboardingState } from 'src/onboarding/onboarding.state';
import { RestaurantNameState } from 'src/onboarding/modules/enrollment/flow/restaurant-name/ngxs/restaurant-name.state';
import { OwnershipStructureState } from 'src/onboarding/modules/enrollment/flow/ownership-structure/ngxs/ownership-structure.state';
import { BankingInfoState } from 'src/onboarding/modules/enrollment/flow/bank-account/ngxs/bank-account.state';
import { RockspoonPlanState } from 'src/onboarding/modules/enrollment/flow/rockspoon-plan/ngxs/rockspoon-plan.state';
import { RestaurantStatusState } from 'src/onboarding/modules/enrollment/flow/restaurant-status/ngxs/restaurant-status.state';
import { ServiceTypeState } from 'src/onboarding/modules/enrollment/flow/service-type/ngxs/service-type.state';
import { OwnersInformationState } from 'src/onboarding/modules/enrollment/flow/owners-information/ngxs/owners-information.state';
import { TermsAndAgreementState } from 'src/onboarding/modules/enrollment/flow/terms-and-agreement/ngxs/terms-and-agreement.state';
import { DocumentSigningState } from '../../modules/enrollment/flow/document-signing/ngxs/document-signing.state';
import { environment } from 'src/environments/environment';

const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 12
    },
    vertical: {
      position: 'bottom',
      distance: 12,
      gap: 10
    }
  },
  theme: 'material',
  behaviour: {
    autoHide: 5000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
      easing: 'ease'
    },
    overlap: 150
  }
};
export const TranslateModuleConfig = {
  loader: {
    provide: TranslateLoader,
    useFactory: HttpLoaderFactory,
    deps: [HttpClient]
  },
  defaultLanguage: COMMON_CONSTANTS.DEFAULT_LANGUAGE
};

@NgModule({
  imports: [
    NgxsModule.forRoot([
      OnboardingState,
      RestaurantNameState,
      OwnershipStructureState,
      BankingInfoState,
      RockspoonPlanState,
      RestaurantStatusState,
      ServiceTypeState,
      OwnersInformationState,
      TermsAndAgreementState,
      DocumentSigningState
    ], {
      developmentMode: !environment.production
    }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    TranslateModule.forRoot(TranslateModuleConfig),
    CdkLayoutModule,
    NotifierModule.withConfig(customNotifierOptions)
  ],
  exports: [
    NgxsModule,
    NgxsReduxDevtoolsPluginModule,
    TranslateModule,
    CdkLayoutModule,
    NotifierModule
  ]
})

export class PluginsModule {}
