import { NgModule } from '@angular/core';

import { MaterialModule } from 'src/onboarding/shared/modules/material.module';
import { SharedModule } from 'src/onboarding/shared/modules/shared.module';
import { AgreementsListComponent } from './component/agreements-list/agreements-list.component';
import { DocumentSigningComponent } from './component/document-signing/document-signing.component';
import { SigningChecklistComponent } from './component/signing-checklist/signing-checklist.component';
import { DocumentComponent } from './component/document/document.component';
import { SanitizeHtmlPipe } from './component/document/pipe/sanitize-html.pipe';

@NgModule({
  imports: [
    SharedModule,
    MaterialModule
  ],
  declarations: [
    DocumentSigningComponent,
    AgreementsListComponent,
    SigningChecklistComponent,
    DocumentComponent,
    SanitizeHtmlPipe
  ],
  exports: [
    DocumentSigningComponent
  ]
})
export class DocumentSigningModule {}
