import { NgModule } from '@angular/core';

import { SectionFooterModule } from 'src/onboarding/shared/components/section-footer/section-footer.module';
import { DirectivesModule } from 'src/onboarding/shared/directives/directives.module';
import { MaterialModule } from 'src/onboarding/shared/modules/material.module';
import { SharedModule } from 'src/onboarding/shared/modules/shared.module';
import { BankAccountComponent } from './component/bank-account.component';

@NgModule({
  imports: [
    SharedModule,
    MaterialModule,
    SectionFooterModule,
    DirectivesModule
  ],
  declarations: [
    BankAccountComponent
  ],
  exports: [
    BankAccountComponent
  ]
})
export class BankAccountModule {}
