import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';

export const EXPIRATION_DATE_MASK = {
  guide: false,
  showMask: true,
  mask: [/\d/,/\d/, '/', /[0-4]/, /\d/],
  pipe: createAutoCorrectedDatePipe('mm/yy', { minYear: 2000 }),
  keepCharPositions: true
};

export const CARD_NUMBER_MASK = {
  guide: false,
  showMask: true,
  mask: [
    /(x|\d)/, /(x|\d)/, /(x|\d)/, /(x|\d)/, '-',
    /(x|\d)/, /(x|\d)/,/(x|\d)/,/(x|\d)/, '-',
    /(x|\d)/, /(x|\d)/,/(x|\d)/,/(x|\d)/, '-',
    /\d/, /\d/, /\d/, /\d/
  ],
  keepCharPositions: true
};

export const CVV_MASK = {
  guide: false,
  showMask: true,
  mask: [/[0-9]/, /[0-9]/, /[0-9]/],
  pipe: createAutoCorrectedDatePipe('mm/yy', { minYear: 2000 }),
  keepCharPositions: true
};

export const ZIPCODE_MASK = {
  guide: false,
  showMask: true,
  mask: [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
  keepCharPositions: true
};

export const SOCIAL_SECURITY_NUMBER_MASK = {
  guide: false,
  showMask: true,
  mask: [
    /[0-9]/, /[0-9]/, /[0-9]/, '-',
    /[0-9]/, /[0-9]/, '-',
    /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/
  ],
  keepCharPositions: true
};

export const ID_NUMBER_MASK = {
  guide: false,
  showMask: true,
  mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
  keepCharPositions: true
};
