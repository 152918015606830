import { Component, Input } from '@angular/core';
import { BusinessEntityExtended } from 'src/onboarding/shared/models/business-entity.model';
import { CompanyOwner } from 'src/onboarding/shared/models/company-owner.model';
import { IndividualOwner } from 'src/onboarding/shared/models/individual-owner.model';

@Component({
  selector: 'rs-review-owners-information',
  templateUrl: 'review-owners-information.component.html',
  styleUrls: ['review-owners-information.component.scss']
})
export class ReviewOwnersInformationComponent {

  @Input() set businessEntity({principals}: BusinessEntityExtended) {
    this.individuals = principals.individual;
    this.companies = principals.companies;
  }

  public individuals: IndividualOwner[] = null;
  public companies: CompanyOwner[] = null;

}
