import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { EnrollmentPage } from 'src/onboarding/shared/classes/enrollment-page.class';
import { OWNERSHIP_STRUCTURES } from 'src/onboarding/shared/constants/common';
import { ENROLLMENT_SECTIONS } from 'src/onboarding/shared/constants/enrollment-sections.constants';
import { copy } from 'src/onboarding/shared/helpers';
import { ListItem } from 'src/onboarding/shared/models/list-item.model';
import { CompanyTypes } from '../models/company-type.model';
import { SetOwnershipStructure } from '../ngxs/ownership-structure.actions';

@Component({
  selector: 'rs-ownership-structure',
  templateUrl: 'ownership-structure.component.html'
})
export class OwnershipStructureComponent extends EnrollmentPage implements OnInit {

  @Select(state => state.ownershipStructure.ownershipStructure) ownershipStructure$: Observable<CompanyTypes>;

  public ownershipStructures: ListItem[] = copy(OWNERSHIP_STRUCTURES);
  public ownershipStructuresControl = new FormControl('', Validators.required);
  public ownershipStructure: CompanyTypes;

  constructor(
    protected store: Store
  ) {
    super(ENROLLMENT_SECTIONS.OWNERSHIP_STRUCTURE, store);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.initSubscriptions();
  }

  public goToNextStep(ownershipStructure: CompanyTypes): void {
    this.store.dispatch(new SetOwnershipStructure(ownershipStructure));
  }

  private initSubscriptions(): void {
    this.subscribeTo = this.ownershipStructure$
      .subscribe(ownershipStructure => {
        this.ownershipStructure = ownershipStructure;
        this.ownershipStructuresControl.reset(ownershipStructure);
      });
  }

}
