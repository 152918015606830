import { NgModule } from '@angular/core';

import { MaterialModule } from '../../modules/material.module';
import { SharedModule } from '../../modules/shared.module';
import { SectionFooterModule } from '../section-footer/section-footer.module';
import { RadioListComponent } from './radio-list.component';

@NgModule({
  imports: [
    SharedModule,
    MaterialModule,
    SectionFooterModule
  ],
  declarations: [
    RadioListComponent
  ],
  exports: [
    RadioListComponent
  ]
})
export class RadioListModule {}
