import { NgModule } from '@angular/core';

import { MaterialModule } from '../../modules/material.module';
import { SharedModule } from '../../modules/shared.module';
import { AddressControlModule } from '../address-control/address-control.module';
import { ControlWithMaskModule } from '../control-with-mask/control-with-mask.module';
import { AddressFormComponent } from './address-form.component';

@NgModule({
  imports: [
    SharedModule,
    MaterialModule,
    ControlWithMaskModule,
    AddressControlModule
  ],
  declarations: [
    AddressFormComponent
  ],
  exports: [
    AddressFormComponent
  ]
})
export class AddressFormModule {}
