import { BusinessEntityExtended } from 'src/onboarding/shared/models/business-entity.model';

export class SetServiceType {
  static readonly type = '[Service Type] Set';

  constructor(
    public serviceType: string
  ) {}
}

export class InitServiceType {
  static readonly type = '[Service Type] Init';

  constructor(
    public businessEntity: BusinessEntityExtended
  ) {}
}
