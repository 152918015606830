import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { AcceptInputModule } from 'src/onboarding/shared/components/accept-input/accept-input.module';
import { IconButtonModule } from 'src/onboarding/shared/components/icon-button/icon-button.module';
import { MessageModule } from 'src/onboarding/shared/components/message/message.module';
import { SectionFooterModule } from 'src/onboarding/shared/components/section-footer/section-footer.module';
import { MaterialModule } from 'src/onboarding/shared/modules/material.module';
import { SharedModule } from 'src/onboarding/shared/modules/shared.module';
import { GreatMessageComponent } from './components/great-message/great-message.component';
import { SadMessageComponent } from './components/sad-message/sad-message.component';
import { WebsiteComponent } from './components/website/website.component';
import { WebsiteUrlState } from './ngxs/website.state';

@NgModule({
  imports: [
    SharedModule,
    AcceptInputModule,
    IconButtonModule,
    MessageModule,
    MaterialModule,
    SectionFooterModule,
    NgxsModule.forFeature([WebsiteUrlState])
  ],
  declarations: [
    WebsiteComponent,
    SadMessageComponent,
    GreatMessageComponent
  ],
  exports: [
    WebsiteComponent
  ]
})
export class WebsiteModule {}
