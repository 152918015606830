import { NgModule } from '@angular/core';

import { NgxsModule } from '@ngxs/store';

import { AddressFormModule } from 'src/onboarding/shared/components/address-form/address-form.module';
import { SectionFooterModule } from 'src/onboarding/shared/components/section-footer/section-footer.module';
import { MaterialModule } from 'src/onboarding/shared/modules/material.module';
import { SharedModule } from 'src/onboarding/shared/modules/shared.module';
import { AddressComponent } from './componens/address.component';
import { AddressState } from './ngxs/address.state';

@NgModule({
  imports: [
    SharedModule,
    MaterialModule,
    SectionFooterModule,
    NgxsModule.forFeature([AddressState]),
    AddressFormModule
  ],
  declarations: [
    AddressComponent
  ],
  exports: [
    AddressComponent
  ]
})
export class AddressModule {}
