import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ENDPOINTS } from 'src/onboarding/shared/constants/endpoints';
import { ApiService } from 'src/onboarding/shared/services/api.service';
import { CompanyType } from './models/company-type.model';

@Injectable({
  providedIn: 'root'
})
export class OwnershipStructureService {

  constructor(
    private apiService: ApiService
  ) {}

  public saveCompanyType(companyType: CompanyType, businessEntityId: string): Observable<void> {
    return this.apiService.put(ENDPOINTS.COMPANY_TYPE(businessEntityId), companyType);
  }

}
