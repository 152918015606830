import { Component, Input } from '@angular/core';

@Component({
  selector: 'rs-message',
  templateUrl: 'message.component.html',
  styleUrls: ['message.component.scss']
})
export class MessageComponent {

  @Input() headerIsNotVisible: boolean;

}
