import { NgModule } from '@angular/core';

import { MaterialModule } from '../../modules/material.module';
import { SharedModule } from '../../modules/shared.module';
import { TypeItemComponent } from './type-item.component';

@NgModule({
  imports: [
    SharedModule,
    MaterialModule
  ],
  declarations: [
    TypeItemComponent
  ],
  exports: [
    TypeItemComponent
  ]
})
export class TypeItemModule {}
