import { RestaurantStatuses } from 'src/onboarding/shared/enums/restaurant-statuses.enum';
import { BusinessEntityExtended } from 'src/onboarding/shared/models/business-entity.model';

export class SetRestaurantStatus {
  static readonly type = '[Current Status] Set';

  constructor(
    public restaurantStatus: RestaurantStatuses,
    public posSystem: string | null
  ) {}
}

export class InitRestaurantStatus {
  static readonly type = '[Current Status] Init';

  constructor(
    public businessEntity: BusinessEntityExtended
  ) {}
}
