import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';

import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { SanitizeHtmlPipe } from './pipe/sanitize-html.pipe';
import { DocumentShowState } from '../../models/document-show-state.enum';
import { Unsubscribe } from '../../../../../../shared/classes/unsubscribe.class';
import { SCROLLED_DOWN_VALUE } from 'src/onboarding/shared/constants/common';

@Component({
  selector: 'rs-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss'],
  providers: [SanitizeHtmlPipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentComponent extends Unsubscribe {
  @Select(state => state.termsAndAgreement.documentSigning.documentShowState) documentShowState$!: Observable<DocumentShowState>;
  @ViewChild('documentRef', { static: false, read: ElementRef }) documentRef: ElementRef<HTMLElement>;

  @Input() htmlContent = '';
  @Input() set id(id: string) {
    this.isScrollHandlerDisabled = true;
    setTimeout(() => {
      this.isScrollHandlerDisabled = false;
    }, 500);
    setTimeout(() => {
      this.documentRef.nativeElement.scrollTo(0, 0);
    });
  };

  @Output() scrolledToBottom = new EventEmitter<string>();

  public isScrollHandlerDisabled: boolean;

  public onDocumentScroll(event: Event): void {
    const targetElement = event.target as unknown as { offsetHeight: number; scrollTop: number; scrollHeight: number };
    const { offsetHeight, scrollTop, scrollHeight } = targetElement;
    const isScrolledToBottom = (offsetHeight + scrollTop) / scrollHeight * 100 >= SCROLLED_DOWN_VALUE;

    if (isScrolledToBottom && !this.isScrollHandlerDisabled) {
      this.scrolledToBottom.emit(this.id);
    }
  }

}
