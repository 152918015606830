export class InitSigning {
  static readonly type = '[Signing] Init';
}

export class StartDocumentSignature {
  static readonly type = '[Document Signing] Start Document Signature';
}

export class GetDocuments {
  static readonly type = '[Document Signing] View Document';
}

export class SetCurrentDocumentId {
  static readonly type = '[Document Signing] Set Current Document Id';

  constructor(public currentDocumentId: string) {}
}

export class ViewDocument {
  static readonly type = '[Document Signing] Get Documents';

  constructor(public documentId: string) {}
}

export class SignDocuments {
  static readonly type = '[Document Signing] Sign Documents';
}
