import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { Unsubscribe } from '../../classes/unsubscribe.class';
import masks from 'src/assets/files/masks.json';

@Component({
  selector: 'rs-phone-control',
  templateUrl: 'phone-control.component.html',
  styleUrls: ['phone-control.component.scss']
})
export class PhoneControlComponent extends Unsubscribe implements OnInit {

  @Input() rsPhoneControl: FormControl;
  @Input() linear: boolean;

  public middlewareControl: FormControl = new FormControl('', Validators.required);
  public isTouched: boolean;
  public inputOptions = {
    initialCountry: null,
    preferredCountries: ['us', 'br']
  };

  private prefix = '+1';
  private mask = '(000) 000-0000';

  public ngOnInit(): void {
    this.setInitialCountry();
    this.subscribeToControl();
    this.setDefaultValue();
  }

  public reactOnClick(): void {
    this.isTouched = true;
  }

  public getPrefix(): string {
    return this.isTouched ? this.prefix : null;
  }

  public getMask(): string {
    return this.isTouched ? this.mask : null;
  }

  // TODO: add correct CountryChangeEvent type instead of any
  public onCountryChange(event: any): void {
    if (masks[event.iso2]) {
      const [prefix, mask, code] = masks[event.iso2]?.split(' ');


      this.prefix = prefix;
      this.mask = mask;
      this.isTouched = true;

      if (code) {
        this.middlewareControl.reset(code);
      }
    }
  }

  private setDefaultValue(): void {
    if (this.rsPhoneControl.value) {
      this.isTouched = true;

      setTimeout(() => {
        this.middlewareControl.reset(this.rsPhoneControl.value);
      }, 500);
    }
  }

  private subscribeToControl(): void {
    this.subscribeTo = this.middlewareControl.valueChanges
      .subscribe(() => {
        this.rsPhoneControl.reset({
          countryCode: this.prefix,
          number: `${this.prefix}${this.middlewareControl.value}`
        });
      });
  }

  private setInitialCountry(): void {
    if (this.rsPhoneControl.value) {
      Object.keys(masks).forEach(countryISOCode => {
        const [countryPhoneCode, mask] = masks[countryISOCode].split(' ');

        if (this.rsPhoneControl.value.includes(countryPhoneCode)) {
          this.inputOptions.initialCountry = countryISOCode;
          this.prefix = countryPhoneCode;
          this.mask = mask;
        }
      });
    }
  }

}
