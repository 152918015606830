import { Component, OnInit } from '@angular/core';

import { Select, Store } from '@ngxs/store';
import { combineLatest, Observable } from 'rxjs';
import { MatSelectChange } from '@angular/material/select';
import moment from 'moment';

import { Unsubscribe } from 'src/onboarding/shared/classes/unsubscribe.class';
import { IndividualOwner } from 'src/onboarding/shared/models/individual-owner.model';
import { CreateBusinessLeader, CreateControlPersonFromIndividualOwner, SetRequestSignerVisibility } from '../../ngxs/terms-and-agreement.actions';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { REGEX } from 'src/onboarding/shared/constants/regular-expressions';
import { Invitation } from '../../model/invitation.model';
import { OTHER } from 'src/onboarding/shared/constants/common';

@Component({
  selector: 'rs-request-signer',
  templateUrl: 'request-signer.component.html',
  styleUrls: ['request-signer.component.scss']
})
export class RequestSignerComponent extends Unsubscribe implements OnInit {

  @Select(state => state.ownersInformation.individuals) individuals$: Observable<IndividualOwner[]>;
  @Select(state => state.termsAndAgreement.activeInvitation) activeInvitation$: Observable<Invitation>;

  public individuals: IndividualOwner[];
  public otherForm: FormGroup;
  public activeInvitation: Invitation;

  constructor(
    private store: Store,
    private formBuilder: FormBuilder
  ) {
    super();
  }

  ngOnInit() {
    this.initSubscriptions();
  }

  public singleCancelVisibility(): boolean {
    return this.activeInvitation && !this.otherForm?.valid;
  }

  public cancel(): void {
    this.store.dispatch(new SetRequestSignerVisibility(false));
  }

  public reactOnChooseSigner(event: MatSelectChange): void {
    if (event.value === OTHER) {
      this.initOtherForm();
    } else {
      this.otherForm = null;
      this.store.dispatch(new CreateControlPersonFromIndividualOwner(event.value));
    }
  }

  public requestSignature(): void {
    this.store.dispatch(new CreateBusinessLeader({
      email: this.otherForm.value.email,
      firstName: this.otherForm.value.firstName,
      lastName: this.otherForm.value.lastName,
      phone: this.otherForm.value.phoneNumber
    }));
  }

  private initSubscriptions(): void {
    this.subscribeTo = combineLatest([
      this.individuals$,
      this.activeInvitation$
    ])
      .subscribe(([individuals, activeInvitation]: [IndividualOwner[], Invitation]) => {
        this.activeInvitation = activeInvitation;
        this.individuals = individuals.filter(owner => {
          return moment().diff(owner.dateOfBirth, 'years') >= 18 &&
                 activeInvitation?.email !== owner.email;
        });
      });
  }

  private initOtherForm(): void {
    this.otherForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(REGEX.EMAIL)]],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required]
    })
  }

}
