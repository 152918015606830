import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { EnrollmentPage } from 'src/onboarding/shared/classes/enrollment-page.class';
import { ENROLLMENT_SECTIONS } from 'src/onboarding/shared/constants/enrollment-sections.constants';
import { REGEX } from 'src/onboarding/shared/constants/regular-expressions';
import { SaveWebsiteUrl, SetAnswerAboutWebsite } from '../../ngxs/website.actions';

@Component({
  selector: 'rs-website',
  templateUrl: 'website.component.html',
  styleUrls: ['website.component.scss', 'website.component.responsive.scss']
})
export class WebsiteComponent extends EnrollmentPage implements OnInit {

  @Select(state => state.websiteUrl.doYouHaveWebsite) doYouHaveWebsite$: Observable<boolean>;
  @Select(state => state.websiteUrl.websiteUrl) websiteUrl$: Observable<string>;

  public urlControl = new FormControl("", Validators.pattern(REGEX.LINK));

  private doYouHaveWebsite: boolean;
  private websiteUrl: string;

  constructor(
    protected store: Store
  ) {
    super(ENROLLMENT_SECTIONS.WEBSITE, store);
  }

  ngOnInit() {
    super.ngOnInit();
    this.initSubscriptions();
  }

  public reactOnAcceptWebsiteUrl(): void {
    this.store.dispatch(new SetAnswerAboutWebsite(true, this.urlControl.value));
  }

  public userHasAcceptedWebsite(): boolean {
    return this.doYouHaveWebsite && this.urlControl.value;
  }

  public next(): void {
    this.store.dispatch(new SaveWebsiteUrl());
  }

  public reactOnDoNotHaveWebsite(): void {
    this.store.dispatch(new SetAnswerAboutWebsite(false, ""));
    this.urlControl.reset("");
  }

  public doNotHaveWebsite(): boolean {
    return !this.doYouHaveWebsite && this.doYouHaveWebsite !== null;
  }

  public isNextButtonVisible(): boolean {
    return this.doNotHaveWebsite() || (this.userHasAcceptedWebsite() && this.websiteUrl === this.urlControl.value);
  }

  private initSubscriptions(): void {
    this.subscribeTo = this.doYouHaveWebsite$
      .subscribe((doYouHaveWebsite: boolean) => this.doYouHaveWebsite = doYouHaveWebsite);
    this.subscribeTo = this.websiteUrl$
      .subscribe((websiteUrl: string) => {
        this.websiteUrl = websiteUrl;
        this.urlControl.reset(this.websiteUrl);
      });
  }

}
