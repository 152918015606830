import { NgModule } from '@angular/core';

import { FullNamePipe } from './full-name.pipe';
import { SafeUrlPipe } from './safe-url.pipe';

@NgModule({
  declarations: [
    FullNamePipe,
    SafeUrlPipe
  ],
  exports: [
    FullNamePipe,
    SafeUrlPipe
  ]
})
export class PipesModule {}
