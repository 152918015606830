<div class="rs-review-company-information"  *ngIf="businessInformation">
  <div class="rs-review-step">{{ 'review.company-information' | translate }}</div>
  <div class="rs-form-control-outline">
    <div class="rs-row">
      <mat-form-field appearance="outline">
        <mat-label>{{'placeholders.legal-entity-name' | translate}}*</mat-label>
        <input
          type="text"
          matInput
          placeholder="{{'placeholders.legal-entity-name' | translate}}*"
          value="{{ businessInformation.legalEntityName }}"
          disabled
        >
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{'placeholders.ein' | translate}}*</mat-label>
        <input
          type="text"
          matInput
          placeholder="{{'placeholders.ein' | translate}}*"
          value="{{ businessInformation.ein }}"
          disabled
        >
      </mat-form-field>
    </div>
  </div>
</div>
