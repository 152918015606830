import { Component } from '@angular/core';

import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { EnrollmentPage } from 'src/onboarding/shared/classes/enrollment-page.class';
import { ENROLLMENT_SECTIONS } from 'src/onboarding/shared/constants/enrollment-sections.constants';
import { RockspoonPlan } from '../models/rockspoon-plan.model';
import { SelectPlan } from '../ngxs/rockspoon-plan.actions';

@Component({
  selector: 'rs-rockspoon-plan',
  templateUrl: 'rockspoon-plan.component.html',
  styleUrls: [
    'rockspoon-plan.component.scss',
    'rockspoon-plan.component.responsive.scss'
  ]
})
export class RockspoonPlanComponent extends EnrollmentPage {

  @Select(state => state.rockspoonPlan.plans) plans$: Observable<RockspoonPlan[]>;
  @Select(state => state.rockspoonPlan.selectedPlanId) selectedPlanId$: Observable<string>;

  public rockspoonPlans: RockspoonPlan[];
  public selectedPlanId: string;

  constructor(
    protected store: Store
  ) {
    super(ENROLLMENT_SECTIONS.ROCKSPOON_PLAN, store);
  }

  ngOnInit() {
    super.ngOnInit();
    this.initSubscriptions();
  }

  public isPlanChecked(planId: string): boolean {
    return this.selectedPlanId === planId;
  }

  public setPayment(planId: string): void {
    this.store.dispatch(new SelectPlan(planId));
  }

  private initSubscriptions(): void {
    this.subscribeTo = this.plans$
      .subscribe((plans: RockspoonPlan[]) => this.rockspoonPlans = plans);
    this.subscribeTo = this.selectedPlanId$
      .subscribe((selectedPlanId: string) => this.selectedPlanId = selectedPlanId);
  }

}
