export class CompanyType {

  public companyType: CompanyTypes;
  public companyTypeOther: string;

  constructor(value: CompanyTypes) {
    if (Object.keys(CompanyTypes).includes(value)) {
      this.companyType = value;
      this.companyTypeOther = null;
    } else {
      this.companyType = CompanyTypes.other;
      this.companyTypeOther = value;
    }
  }

}

export enum CompanyTypes {
  individual = 'individual',
  cCorp = 'cCorp',
  sCorp = 'sCorp',
  partnership = 'partnership',
  trustEstate = 'trustEstate',
  llc = 'llc',
  other = 'other'
}
